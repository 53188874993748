import React, { Component } from "react";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import { FillBarChart } from "../components/fill_chart/FillBarChart";
import { fillBarChartData, FillChartType } from "../components/data";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import {
  chartValues,
  barChartLabels,
  barChartValues,
  getChange,
  chartLocked,
} from "./common";
import { HealthRisks, CostsState, CostDistributionType } from "./types";
import { loadHealthRisks, loadCostDistribution } from "./actions";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { loadChanges } from "../risks_changes/actions";
import { RiskChangesState } from "../risks_changes/types";
import {
  RecommendationState,
  Recommendations,
  setSelectedRecommendation,
} from "lqiDetails/Recommendations";
import { RecommendationModal } from "lqiDetails/RecommendationModal";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";

class CostsDetailsScreen extends Component<Props, RecommendationState> {
  state: RecommendationState = {
    selectedRecommendation: null,
  };

  componentDidMount() {
    this.props.loadHealthRisks();
    this.props.loadCostsDistribution(this.props.type);
    this.props.loadChanges(this.props.type);
  }

  render = () => (
    <div className="container">
      <SideMenu index={2} subMenuIndex={this.props.subMenuIndex} />

      <div className="rightContainer">
        <Header />

        <DropDownSelectors />

        <FillBarChart
          {...this.props.chartProps(this.props.costsState.healthRisks)}
          {...this.barChartProps(this.props.costsState)}
          onViewDetailsClicked={() =>
            this.props.history.push(this.props.detailsPath)
          }
          change={getChange(this.props.riskChanges.changes[this.props.type])}
          healthRiskLocked={
            this.props.chartProps(this.props.costsState.healthRisks)
              .healthRisksLocked
          }
        />

        <Recommendations
          setSelectedRecommendation={setSelectedRecommendation(this)}
          healthRisk={this.props.type}
        />

        <GiveFeedback />
      </div>
      {this.state.selectedRecommendation && (
        <RecommendationModal
          recommendation={this.state.selectedRecommendation}
          onDismissed={() => setSelectedRecommendation(this)(null)}
        />
      )}
    </div>
  );

  chartProps = (healthRisks: HealthRisks): Selector => ({
    ...fillBarChartData.cdv,
    values: chartValues(healthRisks.cvd),
  });

  barChartProps = (costsState: CostsState) => ({
    verticalBarChartValues: barChartValues(
      costsState.costDistribution[this.props.type]
    ),
    horizontalLabels: barChartLabels(
      costsState.costDistribution[this.props.type]
    ),
    distributionLocked: costsState.costDistributionLocked[this.props.type],
  });
}

interface Props extends RouteComponentProps<any> {
  costsState: CostsState;
  type: CostDistributionType;
  detailsPath: string;
  riskChanges: RiskChangesState;
  subMenuIndex: number;

  loadHealthRisks: () => void;
  chartProps: (healthRisks: HealthRisks) => Selector;
  loadCostsDistribution: (type: CostDistributionType) => void;
  loadChanges: (type: CostDistributionType) => void;
}

interface Selector {
  values: number[];
  label: string;
  colors: string[];
  icon: FillChartType;

  verticalBarChartValues: number[];
  healthRisksLocked?: boolean;
}

function mapStateToProps(state: RootState) {
  return {
    costsState: state.costs,
    riskChanges: state.riskChanges,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadHealthRisks: () => dispatch(loadHealthRisks()),
    loadCostsDistribution: (type: CostDistributionType) =>
      dispatch(loadCostDistribution(type)),
    loadChanges: (type: CostDistributionType) => dispatch(loadChanges(type)),
  };
}

const ReduxSeventhScreen = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CostsDetailsScreen)
);

function cvd(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.cdv,
    values: chartValues(healthRisks.cvd),
    healthRisksLocked: chartLocked(healthRisks.cvd),
  };
}

function obesity(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.obesity,
    label: "OBESITY RISK",
    values: chartValues(healthRisks.metabolic),
    healthRisksLocked: chartLocked(healthRisks.metabolic),
  };
}

function diabetes(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.obesity,
    label: "DIABETES II RISK",
    values: chartValues(healthRisks.t2d),
    healthRisksLocked: chartLocked(healthRisks.t2d),
  };
}

function msd(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.msd,
    values: chartValues(healthRisks.msd),
    healthRisksLocked: chartLocked(healthRisks.msd),
  };
}

function stress(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.stress,
    values: chartValues(healthRisks.stress),
    label: "STRESS RISK",
    healthRisksLocked: chartLocked(healthRisks.stress),
  };
}

function anxiety(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.stress,
    values: chartValues(healthRisks.anxiety),
    label: "ANXIETY RISK",
    healthRisksLocked: chartLocked(healthRisks.anxiety),
  };
}

function depression(healthRisks: HealthRisks): Selector {
  return {
    ...fillBarChartData.stress,
    values: chartValues(healthRisks.depression),
    label: "DEPRESSION RISK",
    healthRisksLocked: chartLocked(healthRisks.depression),
  };
}

const CdvDetailsScreen = () => (
  <ReduxSeventhScreen
    chartProps={cvd}
    type="cvd"
    detailsPath="/cvd_changes"
    subMenuIndex={1}
  />
);
const ObesityDetailsScreen = () => (
  <ReduxSeventhScreen
    subMenuIndex={3}
    chartProps={obesity}
    type="obesity"
    detailsPath="/obesity_changes"
  />
);
const DiabetesDetailsScreen = () => (
  <ReduxSeventhScreen
    subMenuIndex={5}
    chartProps={diabetes}
    type="t2d"
    detailsPath="/t2d_changes"
  />
);
const MSDDetailsScreen = () => (
  <ReduxSeventhScreen
    chartProps={msd}
    type="msd"
    detailsPath="/msd_changes"
    subMenuIndex={7}
  />
);
const StressDetailsScreen = () => (
  <ReduxSeventhScreen
    subMenuIndex={9}
    chartProps={stress}
    type="stress"
    detailsPath="/stress_changes"
  />
);
const AnxietyDetailsScreen = () => (
  <ReduxSeventhScreen
    subMenuIndex={11}
    chartProps={anxiety}
    type="anxiety"
    detailsPath="/anxiety_changes"
  />
);
const DepressionsDetailsScreen = () => (
  <ReduxSeventhScreen
    subMenuIndex={13}
    chartProps={depression}
    type="depression"
    detailsPath="/depression_changes"
  />
);

export {
  CdvDetailsScreen,
  ObesityDetailsScreen,
  DiabetesDetailsScreen,
  MSDDetailsScreen,
  StressDetailsScreen,
  AnxietyDetailsScreen,
  DepressionsDetailsScreen,
};
