import {
  ENERGY_LOADED,
  HealthRisksBenchmarks,
  HEALTH_RISKS_LOADED,
  HomeAction,
  Overview,
  OVERVIEW_LOADED,
  Retention,
  RETENTION_LOADED,
} from "./types";
import { AppThunk } from "../common/state";

import { api } from "../common/api";

const healthRisksLoaded = (healthRisks: HealthRisksBenchmarks): HomeAction => ({
  type: HEALTH_RISKS_LOADED,
  healthRisks,
});

const overviewLoaded = (overview: Overview): HomeAction => ({
  type: OVERVIEW_LOADED,
  overview,
});

const retentionLoaded = (retention: Retention): HomeAction => ({
  type: RETENTION_LOADED,
  retention,
});

export const loadHealthRisks =
  (): AppThunk<Promise<unknown>> => async (dispatch) => {
    const healthRisks = await api.getHealthRisksBenchmarks();

    dispatch(healthRisksLoaded(healthRisks));
    return {};
  };

export const loadOverview =
  (): AppThunk<Promise<unknown>> => async (dispatch) => {
    const overview = await api.getOverview();

    dispatch(overviewLoaded(overview));
    return {};
  };

export const loadRetention =
  (): AppThunk<Promise<unknown>> => async (dispatch) => {
    const retention = await api.getRetention();

    dispatch(retentionLoaded(retention));
    return {};
  };

export const loadEnergy =
  (): AppThunk<Promise<unknown>> => async (dispatch) => {
    const energy = await api.getEnergy();

    dispatch({
      type: ENERGY_LOADED,
      energy,
    });
    return {};
  };
