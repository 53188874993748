import React, { useState, useRef, FC, RefObject } from "react";
import { animationStyle, colors, usePosition } from "common/utils";
import { CircularSimpleChart } from "components/circular_chart/CircularSimpleChart";

export const HomeChartsModal: FC<Props> = (props) => {
  const [exit, setExit] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const position = usePosition(600, props.parentRef);

  return (
    <div
      id="recommendationModalContainer"
      className={exit ? "fadeOutContainer" : ""}
      style={{ background: "transparent" }}
      onClick={() => {
        setExit(true);
        setTimeout(props.onDimissed, 1000);
      }}
    >
      <div
        id="chartModal"
        className={`${exit ? "slideOutModal" : ""}`}
        style={{
          left: `${position.left}px`,
          top: `${position.top}px`,
          ...animationStyle(exit, modalRef),
        }}
        ref={modalRef}
      >
        {props.data.map((item) => (
          <CircularSimpleChart
            text={item.label}
            {...colors(item.value)}
            value={item.value}
            chartClass="homeLargeChart"
            showUpdatedAt={true}
            small={true}
          />
        ))}
      </div>
    </div>
  );
};

interface Data {
  value: number;
  label: string;
}

interface Props {
  onDimissed: () => void;

  data: Data[];
  parentRef: RefObject<HTMLDivElement>;
}
