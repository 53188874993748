import {
  LQIState,
  LQIAction,
  LQI_LOADED_ACTION,
  LONGETIVITY_LOADED_ACTION,
  SATISFACTION_LOADED_ACTION,
} from "./types";

const initialState: LQIState = {
  lqi: [],
  lqiLocked: false,
  longetivity: [],
  satisfaction: {
    withProduct: 0,
    withResults: 0,
  },
  longetivityLocked: false,
};

export const lqi = (state = initialState, action: LQIAction): LQIState => {
  switch (action.type) {
    case LQI_LOADED_ACTION:
      return {
        ...state,
        lqi: action.lqi,
        lqiLocked: action.locked,
      };
    case LONGETIVITY_LOADED_ACTION:
      return {
        ...state,
        longetivity: action.longetivity,
        longetivityLocked: action.locked,
      };
    case SATISFACTION_LOADED_ACTION:
      return {
        ...state,
        satisfaction: action.satisfaction,
      };
    default:
      return state;
  }
};
