import React, { FC, ReactNode, Fragment } from "react";
import { Point } from "components/data";

export const SpeedChartIndicator: FC<Props> = (props: Props) => {
  const destinationPoint = (radius: number, angle: number): Point => {
    return new Point(
      props.center.x + radius * Math.cos(angle),
      props.center.y + radius * Math.sin(angle)
    );
  };

  const indicator = (): ReactNode => {
    const leftOriginPoint = destinationPoint(
      props.radius - 5,
      props.angle - 0.3
    );
    const originPoint = destinationPoint(props.radius - 5, props.angle);
    const point = destinationPoint(props.largeRadius - 15, props.angle);

    return (
      <polygon
        points={`${leftOriginPoint.string()} ${originPoint.string()} ${point.string()}`}
        strokeWidth="2"
        stroke={props.color}
        fill={props.color}
      />
    );
  };

  return (
    <Fragment>
      {indicator()}
      <circle
        r={props.radius}
        cx={props.center.x}
        cy={props.center.y}
        fill={props.color}
      />
    </Fragment>
  );
};

interface Props {
  radius: number;
  largeRadius: number;
  center: Point;
  angle: number;
  color: string;
}
