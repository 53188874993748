import React, { useState, ReactElement } from "react";
import "./tooltip.css";
import { assets } from "assets/assets";

export const Tooltip: React.FC<Props> & {
  defaultProps: Partial<Props>;
} = (props: Props) => {
  const [show, setShow] = useState(false);

  return (
    <div
      id="tooltipContainer"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        src={show ? assets.tooltipVisible : assets.tooltipHidden}
        alt=""
        id="tooltipImage"
      />

      {show && (
        <div id="textContainer">
          <div id="tooltipDescription">{props.description}</div>
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  description: (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.
    </p>
  ),
};

interface Props {
  description: ReactElement;
}
