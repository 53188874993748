import React, { Component, Fragment } from "react";
import "./dropdownSelectors.css";
import { DropDown } from "./DropDown";
import { dropdownData } from "components/data";
import { Blurrable } from "common/types";

export class DropDownSelectors extends Component<Props> {
  static defaultProps = {
    showBackButton: false,
    onBackButtonPressed: () => {},
  };

  render = () => null;
  // <div className={`containerDropdown ${this.props.blur ? "blur" : ""}`}>
  //   {this.props.showBackButton ? (
  //     <div className="backButtonContainer">
  //       <Button
  //         text="Back"
  //         type="white"
  //         iconLeft={assets.iconBack}
  //         onClick={this.props.onBackButtonPressed}
  //       />
  //     </div>
  //   ) : (
  //     <Fragment>
  //       <img src={assets.companyIcon} alt="" />
  //       <span className="companyName">SmartDecisions Ltd</span>
  //     </Fragment>
  //   )}

  //   <Button
  //     text="Refresh"
  //     style={{ marginLeft: "2em" }}
  //     type="white"
  //     onClick={() => window.location.reload()}
  //   />
  // </div>

  dropDowns = () => (
    <Fragment>
      <DropDown
        placeholder={dropdownData.period.placeholder}
        options={dropdownData.period.options}
      />

      <DropDown
        placeholder={dropdownData.location.placeholder}
        options={dropdownData.location.options}
      />

      <DropDown
        placeholder={dropdownData.gender.placeholder}
        options={dropdownData.gender.options}
      />

      <DropDown
        placeholder={dropdownData.ages.placeholder}
        options={dropdownData.ages.options}
      />
    </Fragment>
  );
}

interface Props extends Blurrable {
  showBackButton: boolean;
  onBackButtonPressed: () => void;
}
