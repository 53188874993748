import {
  DEMOGRAPHICS_LOADED,
  Demographics,
  Conversion,
  CONVERSION_LOADED,
  GOAL_RANK_LOADED,
  Rank,
  RECOMMENDATIONS_RANK_LOADED,
  DemographicsAction,
} from "./types";
import { AppThunk } from "../common/state";
import { api } from "../common/api";

const demographicsLoadedAction = (demographics: Demographics) => ({
  type: DEMOGRAPHICS_LOADED,
  demographics,
});

const conversionLoadedAction = (conversion: Conversion) => ({
  type: CONVERSION_LOADED,
  conversion,
});

const goalRankLoadedAction = (goalRank: Rank[]): DemographicsAction => ({
  type: GOAL_RANK_LOADED,
  goalRank,
});

const recommendationsRankLoadedAction = (
  recommendationsRank: Rank[]
): DemographicsAction => ({
  type: RECOMMENDATIONS_RANK_LOADED,
  recommendationsRank,
});

export const loadDemographics = (): AppThunk => async (dispatch) => {
  const demographics = await api.getDemographics();

  dispatch(demographicsLoadedAction(demographics));
};

export const loadConversion = (): AppThunk => async (dispatch) => {
  const conversion = await api.getConversion();

  dispatch(conversionLoadedAction(conversion));
};

export const loadGoalRank = (): AppThunk => async (dispatch) => {
  const goalRank = await api.getGoalsRank();

  dispatch(goalRankLoadedAction(goalRank));
};

export const loadRecommendationsRank = (): AppThunk => async (dispatch) => {
  const recommendationsRank = await api.getRecommendationsRank();

  dispatch(recommendationsRankLoadedAction(recommendationsRank));
};
