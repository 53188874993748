import React from "react";

export const EmojiIndicator: React.FC<Props> = (props: Props) => {
  return (
    <div className="emojiContainer">
      <span className="emoji">{props.emoji}</span>
      <span className="emojiValue" style={{ color: props.textColor }}>
        {props.value}%
      </span>
    </div>
  );
};

interface Props {
  emoji: string;
  value: number;
  textColor: string;
}
