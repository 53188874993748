import Amplify, { Auth } from "aws-amplify";
import { FormEntry } from "../splash/SignUpScreen";
import uuid from "uuid";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import {
  CSSProperties,
  RefObject,
  useState,
  useEffect,
  useCallback,
} from "react";
import { chartColors } from "components/data";

const SPECIAL_CHARACTERS =
  "^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ `";

export const emailIsValid = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const to2Decimals = (value: number): number =>
  parseFloat(value.toFixed(2));

export const capitalize = (value?: string): string => {
  return value && value.length >= 2
    ? value[0].toUpperCase() + value.substr(1).toLowerCase()
    : "";
};

export const proportion = (
  value: number,
  start: number,
  end: number,
  newStart: number,
  newEnd: number
) => {
  return ((value - start) / (end - start)) * (newEnd - newStart) + newStart;
};

export const passwordIsValid = (password: string): string => {
  let hasNumber = false;
  let hasUppercase = false;
  let hasSpecialCharacters = false;

  if (password.length < 8) {
    return "Password must be at least 8 characters";
  }
  for (let char of password) {
    if (char === char.toUpperCase()) {
      hasUppercase = true;
    }
    if (char >= "0" && char <= "9") {
      hasNumber = true;
    }
    if (SPECIAL_CHARACTERS.includes(char)) {
      hasSpecialCharacters = true;
    }
  }
  if (!hasNumber) {
    return "Password must contain at least one number";
  }
  if (!hasUppercase) {
    return "Password must contain at least one uppercase";
  }
  if (!hasSpecialCharacters) {
    return (
      "Password must contain at least one of the caracters " +
      SPECIAL_CHARACTERS
    );
  }

  return "";
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const validPhoneNumber = (phoneNumber: string) => {
  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
  return phoneNumberObject ? phoneNumberObject.isValid() : false;
};

export const initAuth = () => {
  Amplify.configure({
    Auth: {
      region: "eu-west-2",
      userPoolId: "eu-west-2_0lUFADaw4",
      userPoolWebClientId: "67a0mijn8t39ag0qoiucr6evfl",
    },
  });
};

export const signUp = (data: FormEntry) => {
  return Auth.signUp({
    username: uuid.v4(),
    password: data.Password,
    attributes: {
      email: data.Email,
      phone_number: data.Phone,
      name: data.Name,
      given_name: data.Title,
    },
  });
};

export const animationStyle = (
  exit: boolean,
  ref: RefObject<HTMLElement>
): CSSProperties => {
  if (!exit || !ref.current) {
    return {};
  } else {
    return {
      transform: `translate(0%, ${
        window.innerHeight - ref.current.getBoundingClientRect().y
      }px)`,
    };
  }
};

export const random1to100 = () =>
  (Math.floor(Math.random() * 100) + 1).toString();

export const random = () => Math.floor(Math.random() * 100) + 1;

export const colors = (value: number): typeof chartColors.green => {
  if (value < 33) {
    return chartColors.green;
  } else if (value < 66) {
    return chartColors.yellow;
  } else {
    return chartColors.red;
  }
};

export const keys = <T, K extends keyof any>(t: T): K[] =>
  Object.keys(t).map((item) => item as K);

interface Position {
  left: number;
  top: number;
}

export const getPosition = (
  width: number,
  ref: RefObject<HTMLDivElement>
): Position => {
  const windowWidth = window.innerWidth;
  const rect = ref.current!.getBoundingClientRect();

  let left = rect.left;
  const top = rect.bottom;
  if (left + width > windowWidth) {
    left = rect.right - width;
  }

  return { left, top };
};

export const usePosition = (
  width: number,
  ref: RefObject<HTMLDivElement>,
  topOffset = 0
): Position => {
  const [position, setPosition] = useState<Position>({ left: 0, top: 0 });
  const calculatePosition = useCallback(() => getPosition(width, ref), [
    ref,
    width,
  ]);

  useEffect(() => {
    const position = calculatePosition();
    setPosition(
      topOffset === 0
        ? position
        : { left: position.left, top: position.top - topOffset }
    );
  }, [calculatePosition, topOffset]);

  return position;
};

export const isTour = () => {
  return window.location.href.endsWith("/tour");
};
