import {
  LQI_LOADED_ACTION,
  LQI,
  LQIAction,
  Longetivity,
  LONGETIVITY_LOADED_ACTION,
  Satisfaction,
  SATISFACTION_LOADED_ACTION,
} from "./types";
import { AppThunk } from "../common/state";
import { api } from "../common/api";

const lqiLoadedAction = (lqi: LQI[], locked: boolean): LQIAction => ({
  type: LQI_LOADED_ACTION,
  lqi,
  locked,
});

const longetivityLoadedAction = (
  longetivity: Longetivity[],
  locked: boolean
): LQIAction => ({
  type: LONGETIVITY_LOADED_ACTION,
  longetivity,
  locked,
});

const satisfactionLoadedAction = (satisfaction: Satisfaction): LQIAction => ({
  type: SATISFACTION_LOADED_ACTION,
  satisfaction,
});

export const loadLQIs = (): AppThunk<Promise<unknown>> => async (dispatch) => {
  const lqi = await api.getLQI();

  dispatch(lqiLoadedAction(lqi.data, lqi.locked));
  return {};
};

export const loadLongetivity = (): AppThunk => async (dispatch) => {
  const longetivity = await api.getLongetivity();

  dispatch(longetivityLoadedAction(longetivity.data, longetivity.locked));
};

export const loadSatisfaction = (): AppThunk => async (dispatch) => {
  const satisfaction = await api.getSatisfaction();

  dispatch(satisfactionLoadedAction(satisfaction));
};
