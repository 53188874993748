import React, { Component, CSSProperties } from "react";
import "./verticalBarCharts.css";

export class VerticalBarCharts extends Component<Props> {
  static defaultProps = {
    style: {},
    horizontalLabels: ["Absenteeism", "Presenteeism", "Lifestyle disease"],
    verticalLabels: ["$0m", "$2m", "$6m", "$8m"],
    colors: ["#7ad077", "#7ad077", "#7ad077"],
    large: false,
  };

  render = () => (
    <div className="verticalBarChartsContainer" style={this.props.style}>
      {this.props.isCostChart && (
        <span className="extrapolated">Extrapolated to 10000 people</span>
      )}

      <div className="verticalBarChartsGridContainer">
        <div className="verticalBarChartsLeftContainer">
          <span>{this.props.verticalLabels[0]}</span>
          <span>{this.props.verticalLabels[1]}</span>
          <span>{this.props.verticalLabels[2]}</span>
          <span style={{ top: 0 }}>{this.props.verticalLabels[3]}</span>
        </div>
        <div className="verticalBarChartsGridSubContainer verticalBarChartsLinesContainer">
          <div className="verticalBarChartsLine" />
          <div className="verticalBarChartsLine" />
          <div className="verticalBarChartsLine" />
          <div className="verticalBarChartsLineBottom" />
        </div>

        <div className="verticalBarChartVerticalGraphContainer">
          <div className=" verticalBarChartsGraphContainer">
            <div className="verticalBarChartsLabelsContainer">
              <div className={this.backgroundClass()}>
                <span
                  style={this.barHeight(this.props.values[0], 0)}
                  className={`${this.backgroundClass()} verticalBarChartsContainerBar`}
                ></span>
              </div>
            </div>

            <div className="verticalBarChartsLabelsContainer">
              <div className={this.backgroundClass()}>
                <span
                  style={this.barHeight(this.props.values[1], 1)}
                  className={`${this.backgroundClass()} verticalBarChartsContainerBar`}
                ></span>
              </div>
            </div>

            {!this.props.isCostChart && (
              <div className="verticalBarChartsLabelsContainer">
                <div className={this.backgroundClass()}>
                  <span
                    style={this.barHeight(this.props.values[2], 2)}
                    className={`${this.backgroundClass()} verticalBarChartsContainerBar`}
                  ></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="verticalBarChartsLabelsContainer">
        <p>{this.props.horizontalLabels[0]}</p>
        <p>{this.props.horizontalLabels[1]}</p>
        {!this.props.isCostChart && <p>{this.props.horizontalLabels[2]}</p>}
      </div>
    </div>
  );

  barHeight = (height: number, index: number): CSSProperties => ({
    height: `${height}%`,
    backgroundColor: this.props.colors[index],
  });

  backgroundClass = (): string =>
    `verticalBarChartsContainerBarBackground ${
      this.props.large ? "large" : ""
    }`;
}

interface Props {
  values: number[];
  style: CSSProperties;

  horizontalLabels: string[];
  verticalLabels: string[];
  colors: string[];
  large: boolean;

  isCostChart?: boolean;
}
