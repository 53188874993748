export const assets = {
  logo: require("./images/logo.svg"),
  syd: require("./images/syd.svg"),
  sydSecond: require("./images/syd_second.svg"),
  sydSecondSelected: require("./images/syd_second_selected.svg"),
  selectedMenuBackground: require("./images/ic_menu_selected_item.svg"),
  home: require("./images/ic_home.svg"),

  moneyIcon: require("./images/icon_money.svg"),
  heartIcon: require("./images/icon_heart.svg"),
  lightBulbIcon: require("./images/icon_light_bulb.svg"),

  settings: require("./images/settings.svg"),

  searchIcon: require("./images/ic_search.svg"),
  playIcon: require("./images/ic_play.svg"),
  companyIcon: require("./images/ic_company.svg"),

  investmentReturnIcon: require("./images/ic_investment_return.svg"),

  colors: {
    red: "#F17036",
    yellow: "#FEBE3A",
    green: "#7AD077",
  },

  sydHighlight: require("./images/sydHighlight.svg"),
  sydHighlightIntro: require("./images/sydModalIntro.svg"),

  indicators: {
    paidWell: {
      green: require("./images/ic_paid_well_green.svg"),
      red: require("./images/ic_paid_well_red.svg"),
      yellow: require("./images/ic_paid_well_yellow.svg"),
    },
    mentored: {
      green: require("./images/ic_mentored_green.svg"),
      red: require("./images/ic_mentored_red.svg"),
      yellow: require("./images/ic_mentored_yellow.svg"),
    },
    challenged: {
      green: require("./images/ic_challenged_green.svg"),
      red: require("./images/ic_challenged_red.svg"),
      yellow: require("./images/ic_challenged_yellow.svg"),
    },
    promoted: {
      green: require("./images/ic_promoted_green.svg"),
      red: require("./images/ic_promoted_red.svg"),
      yellow: require("./images/ic_promoted_yellow.svg"),
    },
    involved: {
      green: require("./images/ic_involved_green.svg"),
      red: require("./images/ic_involved_red.svg"),
      yellow: require("./images/ic_involved_yellow.svg"),
    },

    appreciation: {
      green: require("./images/ic_appreciation_green.svg"),
      red: require("./images/ic_appreciation_red.svg"),
      yellow: require("./images/ic_appreciation_yellow.svg"),
    },
    onAMission: {
      green: require("./images/ic_on_a_mission_green.svg"),
      red: require("./images/ic_on_a_mission_red.svg"),
      yellow: require("./images/ic_on_a_mission_yellow.svg"),
    },
    valued: {
      green: require("./images/ic_valued_green.svg"),
      red: require("./images/ic_valued_red.svg"),
      yellow: require("./images/ic_valued_yellow.svg"),
    },
    empowered: {
      green: require("./images/ic_empowered_green.svg"),
      red: require("./images/ic_empowered_red.svg"),
      yellow: require("./images/ic_empowered_yellow.svg"),
    },
    trusted: {
      green: require("./images/ic_trusted_green.svg"),
      red: require("./images/ic_trusted_red.svg"),
      yellow: require("./images/ic_trusted_yellow.svg"),
    },
    givenFlexibility: {
      green: require("./images/ic_given_flexibility_green.svg"),
      red: require("./images/ic_given_flexibility_red.svg"),
      yellow: require("./images/ic_given_flexibility_yellow.svg"),
    },
  },

  barCharts: {
    diet: require("./images/ic_apple.svg"),
    cognitiveHealth: require("./images/ic_cognitive_health.svg"),
    heartHealth: require("./images/ic_heart_health.svg"),
    mindHealth: require("./images/ic_mind_health.svg"),
    moodOptimisation: require("./images/ic_mood_optimisation.svg"),

    vitaminD: require("./images/ic_vitamin_d.svg"),
    bComplex: require("./images/ic_b_complex.svg"),
    vitaminC: require("./images/ic_vitamin_c.svg"),
    fishOil: require("./images/ic_fish.svg"),
    antioxidants: require("./images/ic_antioxidants.svg"),
  },

  genderChart: {
    iconFemale: require("./images/ic_female.svg"),
    iconMale: require("./images/ic_male.svg"),
  },

  mapChart: {
    iconMapLegend: require("./images/ic_map_legend.svg"),
    iconMap: require("./images/ic_map.svg"),
    iconMapLocation: require("./images/ic_map_location.svg"),
  },

  fillCharts: {
    cdvChart: require("./images/chart_cdv.svg"),
    obesityChart: require("./images/chart_obesity.svg"),
    msdChart: require("./images/chart_msd.svg"),
    stressDepressionChart: require("./images/chart_stress_depression.svg"),
  },

  absenteeismPresenteeism: {
    iconAbsenteeism: require("./images/icon_absenteeism.svg"),
    iconPresenteeism: require("./images/icon_presenteeism.svg"),
  },

  iconBack: require("./images/icon_back.svg"),

  splash: {
    splashBackground: require("./images/splash_background.svg"),
    splashRightImage: require("./images/splash_right_image.svg"),
    logo: require("./images/logo_2.svg"),
    iconLogin: require("./images/ic_login.svg"),

    ellipses: {
      ellipse1: require("./images/ellipse_1.svg"),
      ellipse2: require("./images/ellipse_2.svg"),
      ellipse3: require("./images/ellipse_3.svg"),
      ellipse4: require("./images/ellipse_4.svg"),
    },

    signUpRightSide: require("./images/sign_up_right_side.svg"),
    signUpRightSide2: require("./images/sign_up_right_side_2.svg"),
    bottomeLeft: require("./images/bottom_left.svg"),
    topRight: require("./images/top_right.svg"),
    tutorialRightSide: require("./images/tutorial_right_side.svg"),

    signUpStep1: require("./images/sign_up_step_1.svg"),
    signUpStep2: require("./images/sign_up_step_2.svg"),
    signUpStep3: require("./images/sign_up_step_3.svg"),
    signUpStep4: require("./images/sign_up_step_4.svg"),
  },

  recommendations: {
    eating: require("./images/recommendation_eating.svg"),
    activity: require("./images/recommendation_activity.svg"),
    reading: require("./images/recommendation_reading.svg"),
  },

  arrowLeft: require("./images/arrow_left.svg"),
  arrowRight: require("./images/arrow_right.svg"),
  iconClose: require("./images/ic_close.svg"),

  tooltipVisible: require("./images/ic_tooltip_visible.svg"),
  tooltipHidden: require("./images/ic_tooltip_hidden.svg"),

  arrowUp: require("./images/arrow_up.svg"),
  arrowDown: require("./images/arrow_down.svg"),

  locked: require("./images/locked.svg"),
};
