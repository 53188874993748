import {
  RiskChangeElement,
  CHANGES_LOADED,
  ChangesType,
  ChangesLoadedAction,
} from "./types";
import { AppThunk } from "../common/state";
import { api } from "../common/api";

const cdvChangesLoadedAction = (
  changes: RiskChangeElement[],
  changesType: ChangesType,
  locked: boolean
): ChangesLoadedAction => ({
  type: CHANGES_LOADED,
  changes,
  changesType,
  locked,
});

export const loadChanges = (type: ChangesType): AppThunk => async (
  dispatch
) => {
  const changes = await api.getChanges(type);

  dispatch(cdvChangesLoadedAction(changes.data, type, changes.locked));
};
