import React, { useRef, useEffect, useState } from "react";
import "./AbsoluteDetailedCurveChart.css";
import { DetailedCurveChart } from "./DetailedCurveChart";

export const AbsoluteDetailedCurveChart: React.FC<Props> & {
  defaultProps: Partial<Props>;
} = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [className, setClassName] = useState(
    `absoluteDetailedCurveChartContainer ${props.normalClass}`
  );

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      let cssClass = `absoluteDetailedCurveChartContainer ${props.normalClass}`;

      if (rect.x + rect.width > window.innerWidth - 20) {
        cssClass += ` ${props.leftClass}`;
      }
      if (rect.bottom > window.innerHeight - 20) {
        cssClass += " top";
      }

      setClassName(cssClass);
    }
  }, [props.leftClass, props.normalClass]);

  return (
    <div className="absoluteDetailedCurveChartMainContainer">
      <div className={className} ref={containerRef}>
        <DetailedCurveChart
          bottomLabels={props.values.map((item) => item.label)}
          label={`${props.title.toUpperCase()} OVER TIME`}
          simpleColor="#4FB654"
          simpleValues={props.values.map((item) => item.value)}
          zoom={true}
        />
      </div>
    </div>
  );
};

AbsoluteDetailedCurveChart.defaultProps = {
  leftClass: "left",
  normalClass: "normal",
};

interface Props {
  title: string;

  normalClass: string;
  leftClass: string;
  values: Pair[];
}

export interface Pair {
  label: string;
  value: number;
}
