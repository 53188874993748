import React from "react";
import ReactDropDown from "react-dropdown";
import "react-dropdown/style.css";
import "./dropdown.css";

export class DropDown extends React.Component<Props> {
  static defaultProps = {
    class: "dropdownContainer"
  };

  render = () => (
    <ReactDropDown
      options={this.props.options}
      placeholder={this.props.placeholder}
      className={this.props.class}
      controlClassName="dropdownControlContainer"
      menuClassName="dropdownMenu dropdownPlaceholder"
      placeholderClassName="dropdownPlaceholder placeholderLeft"
      arrowClassName="dropdownArrow"
    />
  );
}

interface Props {
  placeholder: string;
  options: string[];
  class: string;
}
