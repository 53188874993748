import React, {
  ReactNode,
  useState,
  useRef,
  Fragment,
  ReactElement,
} from "react";
import "lqiDetails/recommendations.css";
import "./feedback.css";
import { Point } from "components/data";
import { bezierCommand } from "components/common";
import { HorizontalSlider } from "./HorizontalSlider";
import { useMeasure } from "common/hooks";
import { EmojiIndicator } from "./EmojiIndicator";
import { animationStyle } from "common/utils";

export const NPSChart: React.FC<Props> & {
  defaultProps: Partial<Props>;
} = (props: Props) => {
  const [exit, setExit] = useState(false);

  const svg = useRef<SVGSVGElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const dimensions = useMeasure(svg);

  const RECTANGLE_WIDTH = 40;
  const COLORS = ["#7AD077", "#FDD273", "#F49D6C"];
  const BOTTOM_SIZE = 30;
  const SIDE_MARGIN = 20;

  const labels = (): ReactNode => {
    const LEFT = SIDE_MARGIN * 1.5;
    const RIGHT = dimensions.width - SIDE_MARGIN * 1.5;
    const y = dimensions.height;

    const SECTION = Math.ceil((RIGHT - LEFT) / props.elements.length);
    let index = 0;
    const labels: ReactElement[] = [];
    for (let x = LEFT; x < RIGHT; x += SECTION) {
      const sectionLeft = x;
      const sectionRight = x + SECTION;

      labels.push(
        <text
          x={(sectionLeft + sectionRight) / 2}
          y={y}
          textAnchor="middle"
          dominantBaseline="text-after-edge"
          className="feedbackLabel"
        >
          {props.elements[index].label}
        </text>
      );
      index++;
    }

    return labels;
  };

  const axis = (): ReactNode => {
    const left = SIDE_MARGIN;
    const right = dimensions.width - SIDE_MARGIN;
    const y = dimensions.height - BOTTOM_SIZE;

    return (
      <path
        d={`M ${left} ${y} L ${right} ${y}`}
        fill="none"
        stroke="#D6E6EE"
        strokeWidth="0.5"
        strokeDasharray="3"
      />
    );
  };

  const gradient = (element: ChartElement, index: number): ReactNode => {
    return (
      <defs>
        <linearGradient id={`gradient${index}`} gradientTransform="rotate(90)">
          <stop offset={`${element.values[0]}%`} stopColor={COLORS[0]} />
          <stop offset={`${element.values[0]}%`} stopColor={COLORS[1]} />
          <stop offset={`${element.values[1]}%`} stopColor={COLORS[1]} />
          <stop offset={`${element.values[1]}%`} stopColor={COLORS[2]} />
        </linearGradient>
      </defs>
    );
  };

  const rectangle = (index: number): ReactNode => {
    const left = SIDE_MARGIN * 1.5;
    const right = dimensions.width - SIDE_MARGIN * 1.5;
    const section = Math.floor((right - left) / props.elements.length);

    const bottom = dimensions.height - BOTTOM_SIZE - 10;
    const top = 10;
    const height = bottom - top;

    const sectionLeft = left + section * index;
    const sectionRight = sectionLeft + section;
    const x = (sectionLeft + sectionRight) / 2 - RECTANGLE_WIDTH / 2;
    const y = top;

    const animationProps = (
      from: number,
      to: number,
      attributeName: string
    ): React.SVGProps<SVGElement> => ({
      from,
      to,
      fill: "freeze",
      dur: "1s",
      begin: "1s",
      attributeName,
    });

    const rectangleProps = (
      fill: string,
      clipPath?: string
    ): React.SVGProps<SVGRectElement> => ({
      x,
      y,
      width: RECTANGLE_WIDTH,
      height,
      rx: 8,
      ry: 8,
      fill,
      clipPath,
    });

    return (
      <Fragment>
        <defs>
          <clipPath id={`cutTopPart${index}`}>
            <rect x={x} y={bottom} width={RECTANGLE_WIDTH} height="0">
              <animate {...animationProps(0, height, "height")} />
              <animate {...animationProps(bottom, top, "y")} />
            </rect>
          </clipPath>
        </defs>
        <rect {...rectangleProps("gray")} />
        <rect
          {...rectangleProps(
            `url(#gradient${index})`,
            `url(#cutTopPart${index})`
          )}
        />
      </Fragment>
    );
  };

  const path = (): ReactNode => {
    const top = 0;
    const bottom = dimensions.height - BOTTOM_SIZE;
    const height = bottom - top;
    const left = SIDE_MARGIN * 1.5;
    const right = dimensions.width - SIDE_MARGIN * 1.5;
    const section = Math.ceil((right - left) / props.elements.length);

    const map = (element: ChartElement, index: number): Point => {
      const sectionLeft = left + section * index;
      const sectionRight = sectionLeft + section;
      const x = (sectionLeft + sectionRight) / 2;
      const y = ((100 - element.pointValue) / 100) * height + top;

      return new Point(x, y);
    };

    const points = props.elements.map(map);
    const d = points.reduce(
      (acc, point, i, a) =>
        i === 0
          ? `M ${point.x},${point.y}`
          : `${acc} ${bezierCommand(point, i, a)}`,
      ""
    );

    return (
      <Fragment>
        <defs>
          <marker
            id="marker"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="5"
            markerHeight="5"
          >
            <circle cx="5" cy="5" r="4" fill="#3E9B4B" />
          </marker>

          <clipPath id="pathAnimation">
            <rect x="0" y="0" width="0" height={dimensions.height}>
              <animate
                attributeName="width"
                from="0"
                to={dimensions.width}
                dur="1s"
                begin="2s"
                fill="freeze"
              />
            </rect>
          </clipPath>
        </defs>
        <path
          d={d}
          stroke="#3E9B4B"
          strokeWidth="2"
          fill="none"
          markerStart="url(#marker)"
          markerMid="url(#marker)"
          markerEnd="url(#marker)"
          clipPath="url(#pathAnimation)"
        />
      </Fragment>
    );
  };

  return (
    <div
      id="recommendationModalContainer"
      className={exit ? "fadeOutContainer" : ""}
      onClick={() => {
        setExit(true);
        setTimeout(props.onDimissed, 1000);
      }}
    >
      <div
        id="recommendationModal"
        className={`feedbackModal ${exit ? "slideOutModal" : ""}`}
        style={animationStyle(exit, modalRef)}
        ref={modalRef}
      >
        <span id="feedbackTopLabel">NPS</span>

        <div id="emojiRowContainer">
          <EmojiIndicator emoji="😟" value={50} textColor="#CD512F" />
          <EmojiIndicator emoji="😊" value={41} textColor="#D99A38" />
          <EmojiIndicator emoji="😄" value={9} textColor="#3E9B4B" />
        </div>

        <div className="feedbackSeparator" />

        <HorizontalSlider value={41} />

        <div className="feedbackSeparator" />

        <svg ref={svg} id="feedbackSvg">
          {labels()}
          {axis()}

          {props.elements.map(gradient)}
          {props.elements.map((_, index) => index).map(rectangle)}
          {path()}
        </svg>
      </div>
    </div>
  );
};

NPSChart.defaultProps = {
  elements: [
    {
      label: "Jan",
      values: [33, 66],
      pointValue: 70,
    },
    {
      label: "Feb",
      values: [20, 85],
      pointValue: 60,
    },
    {
      label: "Mar",
      values: [70, 80],
      pointValue: 64,
    },
    {
      label: "Apr",
      values: [20, 50],
      pointValue: 50,
    },
    {
      label: "May",
      values: [15, 45],
      pointValue: 90,
    },
    {
      label: "Jun",
      values: [10, 40],
      pointValue: 50,
    },
  ],
};

interface ChartElement {
  label: string;
  values: [number, number];
  pointValue: number;
}

interface Props {
  elements: ChartElement[];
  onDimissed: () => void;
}
