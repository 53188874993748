import React, { Component, Fragment, CSSProperties, Ref } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "./circularSimpleChart.css";
import "./circularChart.css";
import { Locked } from "components/locked/Locked";

export class CircularSimpleChart extends Component<Props, State> {
  static defaultProps = {
    hideViewMore: true,
    showLabel: false,
    label: "",
    style: {},
    chartClass: null,
    spanClass: null,
    column: false,
    legendStyle: {},
    showUpdatedAt: false,
    small: false,
  };

  state: State = { showUpdatedAt: false, value: 0 };

  componentDidMount() {
    if (this.props.value !== 0) {
      this.setState({ ...this.state, value: this.props.value });
    }
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.value) {
      this.setState({ ...this.state, value: this.props.value });
    }
  }

  render = () => {
    const uiElements = this.ui();

    const className =
      this.props.chartClass != null
        ? `simpleChart ${this.props.chartClass}`
        : "simpleChart";

    return (
      <div
        className={`simpleChartContainer home  
          ${this.props.column ? " simpleChartContainerColumn" : ""} 
          ${this.props.blur ? " blur" : ""}
        `}
        style={{
          justifyContent: this.props.text.length === 0 ? "center" : "flexStart",
          ...this.props.style,
        }}
        ref={this.props.containerRef}
      >
        <div
          className={`${className}`}
          onMouseEnter={() =>
            this.setState({ ...this.state, showUpdatedAt: true })
          }
          onMouseLeave={() =>
            this.setState({ ...this.state, showUpdatedAt: false })
          }
        >
          <CircularProgressbarWithChildren
            strokeWidth={12}
            value={this.state.value}
            className={
              this.props.chartClass ? this.props.chartClass : "smallSimpleChart"
            }
            styles={buildStyles({
              pathColor: uiElements.color,
              trailColor: uiElements.trailColor,
            })}
          >
            <span className={this.props.spanClass}>
              {this.props.showLabel ? uiElements.label : `${this.props.value}%`}
            </span>
          </CircularProgressbarWithChildren>
        </div>

        {this.props.text.length === 0 ? null : (
          <div
            className={`legend ${this.props.column ? "legendColumn" : ""} ${
              this.props.small ? "small" : ""
            }`}
            style={this.props.legendStyle}
          >
            <p
              className="chartTitle"
              style={this.props.onClick ? { marginBottom: 5 } : {}}
            >
              {this.props.text.split("\n").map((item, key) => (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              ))}
            </p>

            {this.props.hideViewMore ? null : (
              <span className="viewMore">view more</span>
            )}
            {this.props.onClick ? (
              <span className="viewMore" onClick={this.props.onClick}>
                See Details
              </span>
            ) : null}
          </div>
        )}
        {/* {this.props.showUpdatedAt && this.state.showUpdatedAt && (
          <AbsoluteDetailedCurveChart title={this.props.text} />
        )} */}

        {this.props.locked && <Locked />}
      </div>
    );
  };

  ui = () => {
    if (!this.props.showLabel) {
      return {
        color: this.props.color,
        trailColor: this.props.trailColor,
        label: "",
      };
    } else {
      if (this.props.value < 25) {
        return {
          color: "#F17036",
          trailColor: "#FDDDBF",
          label: "Poor",
        };
      } else if (this.props.value >= 25 && this.props.value < 50) {
        return {
          color: "#F49D6C",
          trailColor: "#FDDDBF",
          label: "Bad",
        };
      } else if (this.props.value >= 50 && this.props.value < 75) {
        return {
          color: "#FDD273",
          trailColor: "#FEECB3",
          label: "Not bad",
        };
      } else if (this.props.value >= 75 && this.props.value < 100) {
        return {
          color: "#7AD077",
          trailColor: "#DAF9D2",
          label: "Good",
        };
      } else {
        return {
          color: "#4FB654",
          trailColor: "#DAF9D2",
          label: "Perfect",
        };
      }
    }
  };
}

interface Props {
  value: number;
  text: string;

  color: string;
  trailColor: string;
  hideViewMore: boolean;

  showLabel: boolean;
  label: string;
  style: CSSProperties;

  chartClass?: string;
  spanClass?: string;
  small: boolean;
  column: boolean;
  legendStyle: CSSProperties;
  showUpdatedAt: boolean;
  onClick?: () => void;
  containerRef?: Ref<HTMLDivElement>;
  blur?: boolean;
  highlight?: boolean;
  locked?: boolean;
}

interface State {
  showUpdatedAt: boolean;
  value: number;
}
