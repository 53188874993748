import { HealthRisks, CostCategories, HealthRisksCosts } from "costs/types";
import { Demographics, Conversion, Rank } from "engagement/types";
import { HealthRisksBenchmarks, Overview } from "home/types";
import { LQI, Energy, Satisfaction, Longetivity } from "lqi/types";
import { random } from "./utils";

class MockAPI {
  getHealthRisksBenchmarks = () => {
    return Promise.resolve<HealthRisksBenchmarks>({
      anxiety: [
        {
          benchmark: 30,
          company: 77,
          country: 4,
          date: "2020-10-22",
        },
      ],
      cvd: [
        {
          benchmark: 20,
          company: 30,
          country: 25,
          date: "2020-10-22",
        },
      ],
      depression: [
        {
          benchmark: 30,
          company: 33,
          country: 90,
          date: "2020-10-22",
        },
      ],
      metabolic: [
        {
          benchmark: 10,
          company: 20,
          country: 89,
          date: "2020-10-22",
        },
      ],
      msd: [
        {
          benchmark: 47,
          company: 85,
          country: 32,
          date: "2020-10-22",
        },
      ],
      obesity: [
        {
          benchmark: 36,
          company: 13,
          country: 78,
          date: "2020-10-22",
        },
      ],
      sad: [
        {
          benchmark: 37,
          company: 60,
          country: 25,
          date: "2020-10-22",
        },
      ],
      stress: [
        {
          benchmark: 31,
          company: 45,
          country: 30,
          date: "2020-10-22",
        },
      ],
      t2d: [
        {
          benchmark: 60,
          company: 31,
          country: 32,
          date: "2020-10-22",
        },
      ],
    });
  };

  getOverview = () =>
    Promise.resolve<Overview>({
      conversion: [{ date: "2020-10-22", value: 20 }],
      lqi: [{ date: "2020-10-22", value: 46 }],
      retention: [{ date: "2020-10-22", value: 90 }],
      roi: [{ date: "2020-10-22", value: 30 }],
    });

  getLQI = () =>
    Promise.resolve<{ data: LQI[]; locked: boolean }>({
      data: [
        {
          date: "2020-07",
          dimensions: [
            { id: "LQI_1", name: "Health", score: random() },
            { id: "LQI_2", name: "Emotion", score: random() },
            { id: "LQI_3", name: "Brain power", score: random() },
            { id: "LQI_4", name: "Self esteem", score: random() },
            { id: "LQI_5", name: "Purpose", score: random() },
            { id: "LQI_6", name: "Career", score: random() },
            { id: "LQI_7", name: "Finance", score: random() },
            { id: "LQI_8", name: "Social life", score: random() },
            { id: "LQI_9", name: "Environment", score: random() },
          ],
          overall_score: random(),
        },
        {
          date: "2020-08",
          dimensions: [
            { id: "LQI_1", name: "Health", score: random() },
            { id: "LQI_2", name: "Emotion", score: random() },
            { id: "LQI_3", name: "Brain power", score: random() },
            { id: "LQI_4", name: "Self esteem", score: random() },
            { id: "LQI_5", name: "Purpose", score: random() },
            { id: "LQI_6", name: "Career", score: random() },
            { id: "LQI_7", name: "Finance", score: random() },
            { id: "LQI_8", name: "Social life", score: random() },
            { id: "LQI_9", name: "Environment", score: random() },
          ],
          overall_score: random(),
        },
        {
          date: "2020-09",
          dimensions: [
            { id: "LQI_1", name: "Health", score: random() },
            { id: "LQI_2", name: "Emotion", score: random() },
            { id: "LQI_3", name: "Brain power", score: random() },
            { id: "LQI_4", name: "Self esteem", score: random() },
            { id: "LQI_5", name: "Purpose", score: random() },
            { id: "LQI_6", name: "Career", score: random() },
            { id: "LQI_7", name: "Finance", score: random() },
            { id: "LQI_8", name: "Social life", score: random() },
            { id: "LQI_9", name: "Environment", score: random() },
          ],
          overall_score: 71,
        },
        {
          date: "2020-10",
          dimensions: [
            { id: "LQI_1", name: "Health", score: random() },
            { id: "LQI_2", name: "Emotion", score: random() },
            { id: "LQI_3", name: "Brain power", score: random() },
            { id: "LQI_4", name: "Self esteem", score: random() },
            { id: "LQI_5", name: "Purpose", score: random() },
            { id: "LQI_6", name: "Career", score: random() },
            { id: "LQI_7", name: "Finance", score: random() },
            { id: "LQI_8", name: "Social life", score: random() },
            { id: "LQI_9", name: "Environment", score: random() },
          ],
          overall_score: random(),
        },
      ],
      locked: false,
    });

  getEnergy = () =>
    Promise.resolve<Energy[]>([
      {
        date: "2020-06",
        kpis: [{ id: "LQI_1_21", name: "Vitality", score: random() }],
      },
      {
        date: "2020-07",
        kpis: [{ id: "LQI_1_21", name: "Vitality", score: random() }],
      },
      {
        date: "2020-08",
        kpis: [{ id: "LQI_1_21", name: "Vitality", score: random() }],
      },
      {
        date: "2020-09",
        kpis: [{ id: "LQI_1_21", name: "Vitality", score: random() }],
      },
    ]);

  getDemographics = () =>
    Promise.resolve<Demographics>({
      age: [
        { range: "<28", value: 34 },
        { range: "28-30", value: 35 },
        { range: "31-39", value: 13 },
        { range: ">39", value: 18 },
      ],
      diversity: {
        africa: 18,
        asia: 34,
        australia: 0,
        europe: 26,
        northAmerica: 22,
        southAmerica: 0,
      },
      gender: { female: 29, male: 41, nonbinary: 30 },
      locked: {
        age: false,
        diversity: false,
        gender: false,
      },
    });

  getConversion = () =>
    Promise.resolve<Conversion>({
      action: random(),
      dashboard: random(),
      genetics: random(),
      lqi: random(),
    });

  getGoalsRank = () =>
    Promise.resolve<Rank[]>([
      {
        imgUrl: "umbrella.svg",
        name: "COVID-19 prevention",
        rank: 3,
        value: 0,
      },
      {
        imgUrl: "empowered.svg",
        name: "Anxiety reduction",
        rank: 1,
        value: 0,
      },
      { imgUrl: "mind.svg", name: "Mind Health", rank: 2, value: 0 },
      { imgUrl: "esteem.svg", name: "Self-esteem", rank: 4, value: 0 },
    ]);

  getSatisfaction = () =>
    Promise.resolve<Satisfaction>({
      withProduct: random(),
      withResults: random(),
    });

  getHealthRisks = () =>
    Promise.resolve<HealthRisks>({
      anxiety: [
        {
          date: "2020-10-11",
          healthy: 35,
          inDanger: 20,
          inRisk: 45,
        },
      ],
      cvd: [
        {
          date: "2020-10-11",
          healthy: 76,
          inDanger: 14,
          inRisk: 10,
        },
      ],
      depression: [
        {
          date: "2020-10-11",
          healthy: 43,
          inDanger: 33,
          inRisk: 24,
        },
      ],
      metabolic: [
        {
          date: "2020-10-11",
          healthy: 28,
          inDanger: 30,
          inRisk: 42,
        },
      ],
      msd: [
        {
          date: "2020-10-11",
          healthy: 30,
          inDanger: 30,
          inRisk: 40,
        },
      ],
      obesity: [
        {
          date: "2020-10-11",
          healthy: 27,
          inDanger: 30,
          inRisk: 43,
        },
      ],
      sad: [
        {
          date: "2020-10-11",
          healthy: 40,
          inDanger: 40,
          inRisk: 20,
        },
      ],
      stress: [
        {
          date: "2020-10-11",
          healthy: 35,
          inDanger: 15,
          inRisk: 50,
        },
      ],
      t2d: [
        {
          date: "2020-10-11",
          healthy: 72,
          inDanger: 15,
          inRisk: 13,
        },
      ],
    });

  getCostCategories = () =>
    Promise.resolve<CostCategories>({
      abs: 1500000,
      medical: 2100000,
      prs: 6400000,
    });

  getHealthRiskCosts = () =>
    Promise.resolve<HealthRisksCosts>({
      abs: {
        days: { total: 102 },
      },
      prs: {
        days: {
          total: 198,
        },
      },
      risk_costs: {
        cvd: 9832,
        metabolic: 119078,
        msd: 658901,
        sad: 328790,
      },
    });

  getLongetivity = () =>
    Promise.resolve<{ data: Longetivity[]; locked: boolean }>({
      data: [
        { range: "1-5Y", value: random() },
        { range: "6-10Y", value: random() },
        { range: "11-15Y", value: random() },
        { range: ">15Y", value: random() },
      ],
      locked: false,
    });
}

export const mockAPI = new MockAPI();
