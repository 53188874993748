import axios from "axios";
import { HealthRisksBenchmarks, Overview, Retention } from "../home/types";
import { Demographics, Conversion, Rank } from "../engagement/types";
import {
  HealthRisks,
  CostCategories,
  CostDistributionElement,
  HealthRisksCosts,
} from "../costs/types";
import { LQI, Longetivity, Satisfaction, Energy } from "../lqi/types";
import { RiskChangeElement } from "../risks_changes/types";
import { LQIDistPayload, Recommendation } from "lqiDetails/reducer";
import { client } from "./environments";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { isTour } from "./utils";
import { mockAPI } from "./mock_api";

const wpClient = axios.create({
  baseURL: "https://www.iamyiam.com/wp-json/wp/v2/",
});

class NetworkAPI {
  corporateId = "";

  getHealthRisksBenchmarks = () =>
    isTour()
      ? mockAPI.getHealthRisksBenchmarks()
      : this.call<HealthRisksBenchmarks>("healthRisks_benchmark");

  getEnergy = () =>
    isTour() ? mockAPI.getEnergy() : this.call<Energy[]>("energy");

  getOverview = () =>
    isTour() ? mockAPI.getOverview() : this.call<Overview>("overview");

  getRetention = () => this.call<Retention>("retention");

  getDemographics = () =>
    isTour()
      ? mockAPI.getDemographics()
      : this.call<Demographics>("demographics");

  getConversion = () =>
    isTour() ? mockAPI.getConversion() : this.call<Conversion>("conversion");

  getGoalsRank = () =>
    isTour() ? mockAPI.getGoalsRank() : this.call<Rank[]>("goalRank");

  getRecommendationsRank = () => this.call<Rank[]>("reRank");

  getHealthRisks = () =>
    isTour() ? mockAPI.getHealthRisks() : this.call<HealthRisks>("healthRisks");

  getCostCategories = () =>
    isTour()
      ? mockAPI.getCostCategories()
      : this.call<CostCategories>("cost_categories");

  getLQI = () =>
    isTour()
      ? mockAPI.getLQI()
      : this.call<{ data: LQI[]; locked: boolean }>("lqi", true);

  getLQIDist = () => this.call<Record<string, LQIDistPayload>>("lqiDist");

  getLongetivity = () =>
    isTour()
      ? mockAPI.getLongetivity()
      : this.call<{ data: Longetivity[]; locked: boolean }>("longevity", true);

  getSatisfaction = () =>
    isTour()
      ? mockAPI.getSatisfaction()
      : this.call<Satisfaction>("satisfaction");

  getChanges = (url: string) => {
    return this.call<{
      data: RiskChangeElement[];
      locked: boolean;
    }>(`${url}/changes`, true);
  };

  getCostDistribution = (url: string) =>
    this.call<{ data: CostDistributionElement[]; locked: boolean }>(
      `${url}/distribution`,
      true
    );

  getHealthRiskCosts = () =>
    isTour()
      ? mockAPI.getHealthRiskCosts()
      : this.call<HealthRisksCosts>("cost_healthRisks");

  getArticles = (lqiId?: string, healthRisk?: string) => {
    const headers: Record<string, string> = lqiId
      ? { lqiId: lqiId! }
      : healthRisk
      ? { healthRisk: healthRisk! }
      : {};

    return this.call<{ articles: [{ url: string }] }>(
      "lqiArticles",
      true,
      headers
    );
  };

  getRecommendation = (slug: string) => this.callBlogPostApi(slug);
  getRecommendationImage = (mediaId: number) => this.callBlogMediaApi(mediaId);

  invalidateCorporateID = () => (this.corporateId = "");

  call = <T>(
    url: string,
    skipParsing = false,
    extraHeaders: Record<string, string> = {}
  ): Promise<T> => {
    if (this.corporateId) {
      return this.callAPI<T>(url, skipParsing, extraHeaders);
    } else {
      return new Promise<T>((resolve) => {
        Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
          user.getUserAttributes((err, result) => {
            if (err || result === undefined) {
              this.corporateId = "showcase1";
            } else {
              const corporateIdAttribute = result.find(
                (item) => item.getName() === "custom:corporateID"
              );
              if (corporateIdAttribute) {
                this.corporateId = corporateIdAttribute.getValue();
              }
            }
            resolve(this.callAPI<T>(url, skipParsing, extraHeaders));
          });
        });
      });
    }
  };

  callAPI = <T>(
    url: string,
    skipParsing = false,
    extraHeaders: Record<string, string> = {}
  ): Promise<T> => {
    return client
      .get(`${this.corporateId}/${url}`, { headers: extraHeaders })
      .then(({ data }) => {
        if (skipParsing) {
          return data;
        }
        if (data.data) {
          return data.data as T;
        } else {
          return data as T;
        }
      });
  };

  callBlogPostApi = (slug: string): Promise<Partial<Recommendation>> => {
    return wpClient
      .get(`posts?slug=${slug}&lang=en`)
      .then(({ data }): Partial<Recommendation> => {
        return {
          content: data[0].content.rendered,
          excerpt: data[0].excerpt.rendered,
          title: data[0].title.rendered,
          modified: data[0].modified,
          mediaId: data[0].featured_media,
          slug,
        };
      });
  };

  callBlogMediaApi = (mediaId: number): Promise<string> => {
    return wpClient.get(`media/${mediaId}`).then(({ data }) => data.source_url);
  };
}

export const networkAPI = new NetworkAPI();
