import {
  DemographicsState,
  DemographicsAction,
  DEMOGRAPHICS_LOADED,
  CONVERSION_LOADED,
  GOAL_RANK_LOADED,
  RECOMMENDATIONS_RANK_LOADED,
} from "./types";

const initialState: DemographicsState = {
  demographics: {
    age: [],

    diversity: {
      africa: 0,
      asia: 0,
      australia: 0,
      europe: 0,
      northAmerica: 0,
      southAmerica: 0,
    },

    gender: {
      female: 0,
      male: 0,
      nonbinary: 0,
    },

    locked: {
      age: false,
      diversity: false,
      gender: false,
    },
  },

  conversion: {
    action: 0,
    dashboard: 0,
    genetics: 0,
    lqi: 0,
  },

  goalsRank: [],
  recommendationsRank: [],
};

export const demographics = (
  state = initialState,
  action: DemographicsAction
): DemographicsState => {
  switch (action.type) {
    case DEMOGRAPHICS_LOADED:
      return {
        ...state,
        demographics: action.demographics,
      };
    case CONVERSION_LOADED:
      return {
        ...state,
        conversion: action.conversion,
      };
    case GOAL_RANK_LOADED:
      return {
        ...state,
        goalsRank: action.goalRank,
      };
    case RECOMMENDATIONS_RANK_LOADED:
      return {
        ...state,
        recommendationsRank: action.recommendationsRank,
      };
    default:
      return state;
  }
};
