import React, { Component } from "react";
import { Blurrable } from "common/types";
import { assets } from "assets/assets";
import { Link } from "react-router-dom";
import { Button } from "./button/Button";

export class Header extends Component<Blurrable> {
  render = () => (
    <div className={`header ${this.props.blur ? "blur" : ""}`}>
      {/* <TextInput placeholder="Search" sourceImage={assets.searchIcon} /> */}
      {/* <img src={assets.companyIcon} alt="" id="companyLogo" /> */}
      {/* <span className="companyName">SmartDecisions Ltd</span> */}

      <Link to="/tour" style={{ textDecoration: "none" }}>
        <Button text="Take a Tour" icon={assets.playIcon} />
      </Link>
    </div>
  );
}
