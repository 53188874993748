import React, {
  ReactNode,
  Fragment,
  RefObject,
  createRef,
  useState,
  useRef,
  ComponentType,
  useLayoutEffect,
} from "react";
import "./guided.css";
import { assets } from "assets/assets";
import { Button } from "components/button/Button";
import { GuidedProps } from "common/types";
import EngagementScreen from "engagement/EngagementScreen";
import { CostsScreenNoRouter } from "costs/CostsScreen";
import { LQIScreenNoRouter } from "lqi/LQIScreen";
import { RouteComponentProps } from "react-router-dom";
import { HomeScreenNoRouter } from "home/HomeScreen";
import { content } from "common/content";

type GuidedComponent = ComponentType<Partial<GuidedProps>>;
type HighlightedItems = Record<number, RefObject<HTMLDivElement>>;

type GuidedComponentRecord = Record<number, GuidedComponent>;
type GuidedItems = Record<number, HighlightedItems>;

const createGuidedItem = (length: number): HighlightedItems => {
  const result: HighlightedItems = {};
  for (let i = 0; i < length; i++) {
    result[i] = createRef<HTMLDivElement>();
  }

  return result;
};

export const GuidedTourIndicator: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [componentIndex, setComponentIndex] = useState(0);

  const [position, setPosition] = useState<Position>({ left: 0, top: 0 });
  const [Component, setComponent] = useState<GuidedComponent>(
    HomeScreenNoRouter
  );
  const modalRef = useRef<HTMLDivElement | null>(null);

  const highlightedItems = useRef<GuidedItems>({
    0: createGuidedItem(2),
    1: createGuidedItem(3),
    2: createGuidedItem(4),
    3: createGuidedItem(2),
  });
  const guidedComponents = useRef<GuidedComponentRecord>({
    0: HomeScreenNoRouter,
    1: EngagementScreen,
    2: CostsScreenNoRouter,
    3: LQIScreenNoRouter,
  });

  useLayoutEffect(() => {
    if (highlightIndex < 0) return;

    const highlightedItem =
      highlightedItems.current[componentIndex][highlightIndex].current;
    const modalHeight = modalRef.current
      ? modalRef.current.getBoundingClientRect().height
      : 0;
    const modalWidth = modalRef.current
      ? modalRef.current!.getBoundingClientRect().width
      : 0;

    if (highlightedItem) {
      if (highlightIndex === 0) {
        window.scrollTo(0, 0);
      }

      const rect = highlightedItem.getBoundingClientRect();
      let top = rect.top + window.scrollY - modalHeight - 20;
      if (highlightIndex === 0) {
        top = rect.top + window.scrollY + rect.height + window.scrollY + 20;
      }

      let left = rect.left;
      if (left + modalWidth > window.innerWidth) {
        left = rect.right - modalWidth;
        if (left <= 0) {
          left = 80;
        }
      }

      setPosition({ left, top });
      if (highlightIndex > 0) {
        window.scrollTo(0, rect.top + window.scrollY - modalHeight - 40);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [highlightIndex, highlightedItems, componentIndex]);

  const modal = (): ReactNode => {
    return (
      <div
        id="highlightContainer"
        ref={modalRef}
        style={{ left: position.left, top: position.top }}
      >
        <img src={assets.sydHighlight} alt="" />
        <div id="highlightDescription">
          {content[componentIndex][highlightIndex]}
        </div>

        <Button
          type="white"
          text="SKIP"
          class="highlightSkipButton"
          onClick={onSkipPressed}
        />
        <Button type="green" text="NEXT" onClick={onNextPressed} />
      </div>
    );
  };

  const onNextPressed = () => {
    const highligtedItems = highlightedItems.current[componentIndex];
    const components = guidedComponents.current;

    if (highligtedItems) {
      if (highlightIndex < Object.keys(highligtedItems).length - 1) {
        setHighlightIndex(highlightIndex + 1);
      } else if (
        components &&
        componentIndex < Object.keys(components).length - 1
      ) {
        setComponent(guidedComponents.current[componentIndex + 1]);
        setComponentIndex(componentIndex + 1);
        setHighlightIndex(0);
      } else {
        props.history.goBack();
      }
    }
  };

  const onSkipPressed = () => props.history.goBack();

  const intro = (): ReactNode => {
    return (
      <div id="highlightIntroContainer">
        <span id="highlightIntroTitle">Take a Tour</span>
        <span id="highlightIntro">
          Hi! Do you have 5 minutes to learn how to make the most out of your
          population analytics dashboard?
        </span>

        <img src={assets.sydHighlightIntro} alt="" id="highlightImage" />
        <Button
          text="Let's start"
          class="highlighIntroButton"
          onClick={() => setHighlightIndex(0)}
          textStyle={{ fontSize: "20px" }}
        />
        <Button
          text="Skip for now"
          type="white"
          class="highlighIntroButton"
          onClick={onSkipPressed}
          textStyle={{ fontSize: "20px" }}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <svg style={{ display: "none" }}>
        <defs>
          <filter id="blurFilter">
            <feGaussianBlur stdDeviation="2" result="blur" />
          </filter>
        </defs>
      </svg>

      <Component
        blur={true}
        blurIndex={highlightIndex}
        highlightedItems={highlightedItems.current[componentIndex]}
      />
      {highlightIndex >= 0 && modal()}
      {highlightIndex < 0 && intro()}
    </Fragment>
  );
};

interface Position {
  left: number;
  top: number;
}
