import React, { Component, Fragment } from "react";

import "./circular_chart/circularSimpleChart.css";
import "./circular_chart/circularChart.css";
import { assets } from "../assets/assets";
import { Locked } from "./locked/Locked";

export class InvestmentReturnChart extends Component<Props, {}> {
  static defaultProps = {
    hideViewMore: true,
    small: false,
  };

  render = () => (
    <div
      className="simpleChartContainer home"
      style={{ marginBottom: "1em", minWidth: "0px" }}
    >
      <div className="roiContainer">
        <img
          src={assets.investmentReturnIcon}
          className="roiBackground"
          alt=""
        />
        <span className="roiText">{this.props.value}X</span>
      </div>

      <div className={`legend${this.props.small ? " lqi" : ""}`}>
        <p className="chartTitle">
          {this.props.text.split("\n").map((item, key) => (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          ))}
        </p>

        {!this.props.hideViewMore && (
          <span className="viewMore">view more</span>
        )}
      </div>

      {this.props.locked && <Locked />}
    </div>
  );
}

interface Props {
  value: number;
  text: string;
  hideViewMore: boolean;
  small: boolean;

  locked?: boolean;
}
