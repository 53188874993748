import { NUMBER_OF_FRAMES, ARCS_DRAWN } from "./SpeedChart";
import { proportion } from "common/utils";

export class Animator {
  values: number[] = [];
  hasBegunAnimation = false;

  steps: number[] = [];
  animatedValues: number[] = [];
  targetValues: number[] = [];

  callback: (values: number[]) => void;
  animate: (callback: () => void) => void;
  continueAnimation = true;
  duration = NUMBER_OF_FRAMES * 2;
  useLargerRange = -1;

  static initialize = (count: number): number[] => {
    const data: number[] = [];
    for (let i = 0; i < count; i++) {
      data.push(0);
    }

    return data;
  };

  constructor(
    callback: (values: number[]) => void,
    animate: (callback: () => void) => void
  ) {
    this.callback = callback;
    this.animate = animate;
  }

  setValues = (values: number[], useLargerRange = -1) => {
    if (this.hasBegunAnimation) {
      return;
    }

    this.useLargerRange = useLargerRange;
    this.values = values;
  };

  forceReset = (values: number[], useLargerRange = -1) => {
    this.useLargerRange = useLargerRange;
    this.values = values;

    this.steps = [];
    this.animatedValues = [];
    this.targetValues = [];
    this.hasBegunAnimation = false;
    this.continueAnimation = true;
  };

  beginAnimation = (duration = NUMBER_OF_FRAMES) => {
    if (!this.hasBegunAnimation) {
      this.hasBegunAnimation = true;
      this.duration = duration;

      this.values.forEach((value, index) => {
        const left = index === this.useLargerRange ? -100 : 0;
        const arcs = proportion(value, left, 100, 0, ARCS_DRAWN);

        this.targetValues.push(arcs);
        this.steps.push(arcs / this.duration);
        this.animatedValues.push(0);

        this.callback([...this.animatedValues]);
        this.animation();
      });
    }
  };

  animation = () => {
    if (this.continueAnimation) {
      this.increment();
      requestAnimationFrame(this.animation);
    }
  };

  increment = () => {
    this.continueAnimation = false;
    for (let i = 0; i < this.animatedValues.length; i++) {
      if (this.animatedValues[i] < this.targetValues[i]) {
        this.continueAnimation = true;
        this.animatedValues[i] += this.steps[i];
      }
    }

    this.callback([...this.animatedValues]);
  };
}
