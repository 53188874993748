import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { SideMenu } from "../components/SideMenu";
import "../styles.css";
import { simpleChartData, labels, scoresChartData } from "../components/data";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import { Header } from "../components/Header";
import { CircularSimpleChart } from "../components/circular_chart/CircularSimpleChart";
import { InvestmentReturnChart } from "../components/InvestmentReturnChart";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import {
  loadEnergy,
  loadHealthRisks,
  loadOverview,
  loadRetention,
} from "./actions";
import {
  HealthRisksBenchmarks,
  HealthRisksElement,
  Overview,
  OverviewElement,
} from "./types";
import { GuidedProps } from "common/types";
import {
  SpeedChart,
  NUMBER_OF_FRAMES,
} from "components/speed_chart/SpeedChart";
import { Tooltip } from "components/tooltip/Tooltip";
import { Animator } from "components/speed_chart/Animator";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { loadSatisfaction, loadLQIs } from "lqi/actions";
import { Energy, LQI } from "lqi/types";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { content } from "common/content";
import {
  getDimensionLocked,
  getDimensionScore,
  getDimensionValuesOverTime,
  getEnergyLocked,
  getEnergyValue,
  getEnergyValuesOverTime,
  largeScreen,
} from "lqi/common";
import { colors } from "common/utils";
import { HomeChartsModal } from "components/feedback_chart/HomeChartsModal";
import { LoaderWrappper } from "common/LoaderWrapper";

class HomeScreen extends React.Component<Props, State> {
  animator = new Animator(
    (values) => this.setState({ ...this.setState, values }),
    requestAnimationFrame
  );

  static defaultProps: Partial<Props> = {
    blur: false,
    blurIndex: -1,
    highlightedItems: {},
  };

  state: State = {
    selectedItem: 0,
    values: Animator.initialize(6),
    showObesityModal: false,
    showSADModal: false,
    loading: true,
  };

  obesityRef = React.createRef<HTMLDivElement>();
  sadRef = React.createRef<HTMLDivElement>();

  requestsPending = 5;

  requestComplete = () => {
    this.requestsPending--;
    if (this.requestsPending === 0) {
      this.setState({ ...this.state, loading: false });
    }
  };

  componentDidMount() {
    this.props.loadHealthRisks().then(this.requestComplete);
    this.props.loadOverview().then(this.requestComplete);
    this.props.loadLQIs().then(this.requestComplete);
    this.props.loadEnergy().then(this.requestComplete);
    this.props.loadRetention().then(this.requestComplete);
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (prevState.loading && !this.state.loading) {
      this.animator.setValues([
        this.overviewValues(this.props.overview.lqi),
        getDimensionScore(this.props.lqi, "health"),
        getDimensionScore(this.props.lqi, "brainPower"),
        getDimensionScore(this.props.lqi, "emotional"),
        this.overviewValues(this.props.overview.retention),
        getEnergyValue(this.props.energy),
      ]);

      this.animator.beginAnimation(NUMBER_OF_FRAMES * 2);
    }
  }

  selectorInactive(index: number, label: string): React.ReactNode {
    return (
      <span
        className="selectorInactive"
        onClick={() => this.setState({ ...this.state, selectedItem: index })}
        style={{ marginLeft: index === 0 ? "0px" : "10px" }}
      >
        {label}
      </span>
    );
  }

  selectorActive(index: number, label: string): React.ReactNode {
    const marginLeft = index === 0 ? 0 : 10;

    return (
      <div style={{ position: "relative", zIndex: 2 }}>
        <span
          className="selectorActive"
          style={{ marginLeft: `${marginLeft}px` }}
        >
          {label}
        </span>
        <div
          id="whiteCover"
          style={{
            marginLeft: `${marginLeft}px`,
            width: `calc(100% - ${marginLeft}px)`,
          }}
        />
      </div>
    );
  }

  selector(index: number, label: string): React.ReactNode {
    if (index === this.state.selectedItem) {
      return this.selectorActive(index, label);
    } else {
      return this.selectorInactive(index, label);
    }
  }

  selectors(): React.ReactNode {
    return (
      <div className="selectors">
        {this.selector(0, "YOUR COMPANY")}
        {/* {this.selector(1, "CZECH REPUBLIC")} */}
        {this.selector(1, "UK")}
        {this.selector(2, "YOUR BENCHMARK")}
      </div>
    );
  }

  currentScoresTopRow = () => (
    <div className="currentScoresRow">
      <SpeedChart
        text={"HEALTH\nSCORE"}
        value={getDimensionScore(this.props.lqi, "health")}
        valuesOverTime={getDimensionValuesOverTime(this.props.lqi, "health")}
        clickable={false}
        smallLegend={!largeScreen}
        arcNumbers={this.state.values[1]}
        locked={getDimensionLocked(this.props.lqi, "health")}
      />

      <SpeedChart
        text={scoresChartData.brainPowerScore.text}
        value={getDimensionScore(this.props.lqi, "brainPower")}
        valuesOverTime={getDimensionValuesOverTime(
          this.props.lqi,
          "brainPower"
        )}
        clickable={false}
        arcNumbers={this.state.values[2]}
        smallLegend={!largeScreen}
        locked={getDimensionLocked(this.props.lqi, "brainPower")}
      />

      <SpeedChart
        text={scoresChartData.emotionalScore.text}
        value={getDimensionScore(this.props.lqi, "emotional")}
        valuesOverTime={getDimensionValuesOverTime(this.props.lqi, "emotional")}
        clickable={false}
        arcNumbers={this.state.values[3]}
        smallLegend={!largeScreen}
        locked={getDimensionLocked(this.props.lqi, "emotional")}
      />
    </div>
  );

  currentScoreBottomRow = () => (
    <div className="currentScoresRow">
      <SpeedChart
        text={simpleChartData.retentionScore.text}
        value={this.overviewValues(this.props.overview.retention)}
        showUpdatedAt={false}
        clickable={false}
        showSeeDetails={true}
        arcNumbers={this.state.values[4]}
        smallLegend={!largeScreen}
        locked={this.overviewLocked(this.props.overview.retention)}
      />
      <SpeedChart
        text={simpleChartData.energyLack.text}
        value={getEnergyValue(this.props.energy)}
        valuesOverTime={getEnergyValuesOverTime(this.props.energy)}
        clickable={false}
        arcNumbers={this.state.values[5]}
        smallLegend={!largeScreen}
        locked={getEnergyLocked(this.props.energy)}
      />
      <InvestmentReturnChart
        {...simpleChartData.returnOnInvestment}
        small={!largeScreen}
        value={this.overviewValues(this.props.overview.roi)}
        locked={this.overviewLocked(this.props.overview.roi)}
      />
    </div>
  );

  healthStatus = () => (
    <div
      className={`chartContainer home first ${
        this.props.blur && this.props.blurIndex !== 0 ? "blur" : ""
      } ${this.props.blurIndex === 0 ? "highlight" : ""}`}
      ref={this.props.highlightedItems[0]}
      style={{
        marginTop: "-10px",
        backgroundColor: "white",
      }}
    >
      <CircularSimpleChart
        text={labels.stress}
        {...colors(this.healthRiskValues(this.props.healthRisks.sad))}
        value={this.healthRiskValues(this.props.healthRisks.sad)}
        chartClass="homeLargeChart"
        onClick={() => this.setState({ ...this.state, showSADModal: true })}
        showUpdatedAt={true}
        containerRef={this.sadRef}
        blur={
          this.blur() && !this.state.showSADModal && this.props.blurIndex !== 0
        }
        highlight={this.state.showSADModal}
        locked={this.healthRiskLocked(this.props.healthRisks.sad)}
      />

      <CircularSimpleChart
        text={labels.cvd}
        {...colors(this.healthRiskValues(this.props.healthRisks.cvd))}
        value={this.healthRiskValues(this.props.healthRisks.cvd)}
        chartClass="homeLargeChart"
        showUpdatedAt={true}
        blur={this.blur() && this.props.blurIndex !== 0}
        locked={this.healthRiskLocked(this.props.healthRisks.cvd)}
      />

      <CircularSimpleChart
        text={labels.obesity}
        {...colors(this.healthRiskValues(this.props.healthRisks.metabolic))}
        value={this.healthRiskValues(this.props.healthRisks.metabolic)}
        chartClass="homeLargeChart"
        showUpdatedAt={true}
        onClick={() => this.setState({ ...this.state, showObesityModal: true })}
        containerRef={this.obesityRef}
        blur={
          this.blur() &&
          !this.state.showObesityModal &&
          this.props.blurIndex !== 0
        }
        locked={this.healthRiskLocked(this.props.healthRisks.metabolic)}
      />

      <CircularSimpleChart
        text={labels.msd}
        {...colors(this.healthRiskValues(this.props.healthRisks.msd))}
        value={this.healthRiskValues(this.props.healthRisks.msd)}
        chartClass="homeLargeChart"
        showUpdatedAt={true}
        blur={this.blur() && this.props.blurIndex !== 0}
        locked={this.healthRiskLocked(this.props.healthRisks.msd)}
      />

      <Tooltip description={content[0][0]} />
    </div>
  );

  blur = () =>
    this.props.blur || this.state.showObesityModal || this.state.showSADModal;

  blurFilter = () => (
    <svg style={{ display: "none" }}>
      <defs>
        <filter id="blurFilter">
          <feGaussianBlur stdDeviation="2" result="blur" />
        </filter>
      </defs>
    </svg>
  );

  render() {
    return (
      <LoaderWrappper
        child={this.renderContent()}
        loading={this.state.loading}
      />
    );
  }

  renderContent(): React.ReactNode {
    return (
      <div className="container">
        {this.blurFilter()}
        <SideMenu
          blur={this.blur()}
          highlight={this.blur() && this.props.blurIndex >= 0}
          index={0}
          subMenuIndex={0}
        />

        <div className="rightContainer">
          <Header blur={this.blur()} />

          <DropDownSelectors blur={this.blur()} />

          <span
            className={`titleLabel ${
              this.blur() && this.props.blurIndex !== 0 ? "blur" : ""
            }`}
            style={{ marginTop: "1em" }}
          >
            Health Status: (% of people at medium and high risk):
          </span>
          {this.selectors()}
          {this.healthStatus()}

          <span
            className={`titleLabel ${
              this.blur() && this.props.blurIndex !== 1 ? "blur" : ""
            }`}
          >
            Your Current Scores
          </span>

          <div
            className={`chartContainer home ${
              this.blur() && this.props.blurIndex !== 1 ? "blur" : ""
            } ${this.props.blurIndex === 1 ? "highlight" : ""}`}
            ref={this.props.highlightedItems[1]}
          >
            <div id="speedChartLargeContainer">
              <SpeedChart
                text={simpleChartData.lifeQualityIndex.text}
                value={this.overviewValues(this.props.overview.lqi)}
                clickable={true}
                arcNumbers={this.state.values[1]}
                large={true}
                largeLeft={true}
                smallLegend={false}
                onClick={() => this.props.history.push("/lqi")}
                locked={this.overviewLocked(this.props.overview.lqi)}
              />
            </div>

            <div id="currentScoreRowContainer">
              {this.currentScoresTopRow()}
              {this.currentScoreBottomRow()}
            </div>
            <Tooltip description={content[0][1]} />
          </div>

          <GiveFeedback blur={this.blur()} />
        </div>
        {this.obesityModal()}
        {this.sadModal()}
      </div>
    );
  }

  obesityModal = () => {
    if (this.state.showObesityModal) {
      return (
        <HomeChartsModal
          data={[
            {
              label: "OBESITY",
              value: this.healthRiskValues(this.props.healthRisks.obesity),
            },
            {
              label: "DIABETES II",
              value: this.healthRiskValues(this.props.healthRisks.t2d),
            },
          ]}
          onDimissed={() =>
            this.setState({ ...this.state, showObesityModal: false })
          }
          parentRef={this.obesityRef}
        />
      );
    } else {
      return null;
    }
  };

  sadModal = () => {
    if (this.state.showSADModal) {
      return (
        <HomeChartsModal
          data={[
            {
              label: "STRESS",
              value: this.healthRiskValues(this.props.healthRisks.stress),
            },
            {
              label: "ANXIETY",
              value: this.healthRiskValues(this.props.healthRisks.anxiety),
            },
            {
              label: "DEPRESSION",
              value: this.healthRiskValues(this.props.healthRisks.depression),
            },
          ]}
          onDimissed={() =>
            this.setState({ ...this.state, showSADModal: false })
          }
          parentRef={this.sadRef}
        />
      );
    } else {
      return null;
    }
  };

  healthRiskValues = (elements: HealthRisksElement[]): number => {
    if (!elements || elements.length === 0) {
      return 0;
    } else {
      switch (this.state.selectedItem) {
        case 0:
          return elements[0].company;
        case 1:
          return elements[0].country;
        case 2:
        default:
          return elements[0].benchmark ? elements[0].benchmark : 0;
      }
    }
  };

  healthRiskLocked = (elements: HealthRisksElement[]): boolean | undefined => {
    if (!elements || elements.length === 0) {
      return false;
    } else {
      return elements[0].locked;
    }
  };

  overviewValues = (elements: OverviewElement[]) =>
    elements.length === 0 ? 0 : elements[0].value;

  overviewLocked = (elements: OverviewElement[]) =>
    elements.length === 0 ? false : elements[0].locked;
}

const mapStateToProps = (store: RootState) => ({
  healthRisks: store.healthRisks.healthRisks,
  overview: store.healthRisks.overview,
  lqi: store.lqi.lqi,
  energy: store.healthRisks.energy,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  loadHealthRisks: () => dispatch(loadHealthRisks()),
  loadOverview: () => dispatch(loadOverview()),
  loadRetention: () => dispatch(loadRetention()),
  loadSatisfaction: () => dispatch(loadSatisfaction()),
  loadLQIs: () => dispatch(loadLQIs()),
  loadEnergy: () => dispatch(loadEnergy()),
});

interface Props extends GuidedProps, RouteComponentProps {
  healthRisks: HealthRisksBenchmarks;
  overview: Overview;
  lqi: LQI[];
  energy: Energy[];

  loadHealthRisks: () => Promise<unknown>;
  loadOverview: () => Promise<unknown>;
  loadLQIs: () => Promise<unknown>;
  loadEnergy: () => Promise<unknown>;
  loadRetention: () => Promise<unknown>;
}

interface State {
  selectedItem: number;
  values: number[];

  showSADModal: boolean;
  showObesityModal: boolean;
  loading: boolean;
}

export const HomeScreenNoRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);

export default withRouter(HomeScreenNoRouter);
