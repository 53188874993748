import React, { Component, ReactElement } from "react";
import { assets } from "../assets/assets";
import "./menu.css";
import { Redirect, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Blurrable } from "common/types";
import { RootState } from "common/state";
import { LQI } from "lqi/types";
import { connect } from "react-redux";
import { getDimensionScore } from "lqi/common";
import { api } from "common/api";
import { commonActions, User } from "common/reducer";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import { CognitoUser } from "amazon-cognito-identity-js";

interface LinkData {
  text: string;
  target?: string;
  extraIndent?: boolean;

  children?: LinkData[];
}

export class SideMenuTextItems extends Component<Props, State> {
  state: State = {
    redirect: false,
  };

  componentDidMount() {
    const userIsNotLoaded = !this.props.user.name || !this.props.user.image;
    if (userIsNotLoaded) {
      Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        user.getUserAttributes((err, result) => {
          if (!err && result) {
            const nameAttribute = result.find(
              (item) => item.getName() === "name"
            );
            const imageAttribute = result.find(
              (item) => item.getName() === "picture"
            );
            const corporateIdAttribute = result.find(
              (item) => item.getName() === "custom:corporateID"
            );
            if (nameAttribute && imageAttribute && corporateIdAttribute) {
              this.props.setUser({
                image: imageAttribute.getValue(),
                name: nameAttribute.getValue(),
                corporateId: corporateIdAttribute.getValue(),
              });
            }
          }
        });
      });
    }
  }

  items = (): LinkData[] => [
    {
      text: "Home",
      target: "/",
      children: [
        {
          text: "Retention",
          target: "/retention",
        },
      ],
    },
    { text: "Engagement" },
    {
      text: "Costs & Savings",
      target: "/costs",
      children: [
        {
          text: "CVD",
          target: "/cvd",
        },
        {
          text: "Evolution",
          target: "/cvd_changes",
          extraIndent: true,
        },
        {
          text: "Obesity",
          target: "/obesity",
        },
        {
          text: "Evolution",
          target: "/obesity_changes",
          extraIndent: true,
        },
        {
          text: "Diabetes II",
          target: "/diabetes",
        },
        {
          text: "Evolution",
          target: "/diabetes_changes",
          extraIndent: true,
        },
        {
          text: "MSD",
          target: "/msd",
        },
        {
          text: "Evolution",
          target: "/msd_changes",
          extraIndent: true,
        },
        {
          text: "Stress",
          target: "/stress",
        },
        {
          text: "Evolution",
          target: "/stress_changes",
          extraIndent: true,
        },
        {
          text: "Anxiety",
          target: "/anxiety",
        },
        {
          text: "Evolution",
          target: "/anxiety_changes",
          extraIndent: true,
        },
        {
          text: "Depression",
          target: "/depression",
        },
        {
          text: "Evolution",
          target: "/depression_changes",
          extraIndent: true,
        },
      ],
    },
    {
      text: "Life Quality",
      target: "/lqi",
      children: [
        {
          text: "LQi details",
          target: "/lqi_details",
        },
        {
          text: "Health",
          target: `/score_details/Health score/${getDimensionScore(
            this.props.lqi,
            "health"
          )}/health`,
        },
        {
          text: "Career",
          target: `/score_details/Career score/${getDimensionScore(
            this.props.lqi,
            "career"
          )}/career`,
        },
        {
          text: "Brain Power",
          target: `/score_details/Brain Power score/${getDimensionScore(
            this.props.lqi,
            "brainPower"
          )}/brainPower`,
        },
        {
          text: "Financial",
          target: `/score_details/Financial score/${getDimensionScore(
            this.props.lqi,
            "financial"
          )}/financial`,
        },
        {
          text: "Emotional",
          target: `/score_details/Emotional score/${getDimensionScore(
            this.props.lqi,
            "emotional"
          )}/emotional`,
        },
        {
          text: "Environment",
          target: `/score_details/Environment score/${getDimensionScore(
            this.props.lqi,
            "environment"
          )}/environment`,
        },
        {
          text: "Self-awareness",
          target: `/score_details/Self-awareness score/${getDimensionScore(
            this.props.lqi,
            "selfAwareness"
          )}/selfAwareness`,
        },
        {
          text: "Purpose",
          target: `/score_details/Purpose score/${getDimensionScore(
            this.props.lqi,
            "purpose"
          )}/purpose`,
        },
        {
          text: "Social Life",
          target: `/score_details/Social Life score/${getDimensionScore(
            this.props.lqi,
            "socialLife"
          )}/socialLife`,
        },
      ],
    },
  ];

  render = () => {
    if (this.state.redirect) {
      return <Redirect to="/signup/1" />;
    }

    return (
      <div className={`textItemsContainer ${this.props.blur ? "blur" : ""}`}>
        <div
          style={{
            position: "absolute",
            top: "1em",
            left: "1em",
            right: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${this.props.user.image}`}
              alt=""
              width="40"
              height="40"
            />
            <img src={assets.settings} id="settings" alt="" />
            {this.renderLogoutContainer()}

            <span style={{ flexBasis: "100%" }} />
            <span style={{ marginTop: "1em" }}>Hi, {this.props.user.name}</span>
          </div>
        </div>

        {this.renderItems(this.props.index, this.props.top)}
      </div>
    );
  };

  renderLogoutContainer = () => (
    <div id="logoutContainer">
      <span onClick={this.logout}>Logout</span>
    </div>
  );

  logout = () => {
    Auth.signOut().then(() => {
      api.invalidateCorporateID();
      this.props.setUser({ corporateId: "", image: "", name: "" });
      this.setState({ ...this.state, redirect: true });
    });
  };

  renderItems = (index: number, top: number) => {
    const span = (
      text: string,
      selected: boolean,
      indentRight: "none" | "small" | "large"
    ): ReactElement<React.HTMLProps<HTMLSpanElement>> => (
      <span
        className={`${selected ? "textItemSelected" : "menuItemsNotSelected"} ${
          indentRight === "large"
            ? "indentLarge"
            : indentRight === "small"
            ? "indentSmall"
            : ""
        }`}
      >
        {text}
      </span>
    );

    const addChildrenToMenu = (
      item: LinkData,
      selected: boolean
    ): ReactElement<React.HTMLProps<HTMLSpanElement>>[] => {
      const elements: ReactElement<React.HTMLProps<HTMLSpanElement>>[] = [];
      elements.push(
        <Link
          to={item.target!}
          style={{ textDecoration: "none" }}
          key={item.target!}
        >
          {span(item.text, selected, item.extraIndent ? "large" : "small")}
        </Link>
      );

      if (item.children) {
        item.children.forEach((child, index) => {
          const subElements: ReactElement<React.HTMLProps<HTMLSpanElement>>[] =
            addChildrenToMenu(child, index + 1 === this.props.selectedIndex);
          elements.push(...subElements);
        });
      }
      return elements;
    };

    const menuItems: ReactElement[] = [];
    const items = this.items();
    menuItems.push(
      <Link
        style={{ textDecoration: "none" }}
        to={items[index].target ? items[index].target! : ""}
        key={index}
      >
        {span(items[index].text, this.props.selectedIndex === 0, "none")}
      </Link>
    );

    if (items[index].children) {
      items[index].children!.forEach((item, index) =>
        menuItems.push(
          ...addChildrenToMenu(item, index + 1 === this.props.selectedIndex)
        )
      );
    }

    const { marginTop } = this.props;
    return (
      <div style={{ top, position: "relative" }}>
        <div
          className="textItems"
          style={marginTop ? { marginTop: `${marginTop}em` } : {}}
        >
          {menuItems}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  lqi: state.lqi.lqi,
  user: state.common.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  setUser: (user: User) => dispatch(commonActions.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuTextItems);

interface Props extends Blurrable {
  index: number;
  top: number;
  selectedIndex: number;
  marginTop?: number;
  lqi: LQI[];
  user: User;
  setUser: (user: User) => void;
}

interface State {
  redirect: boolean;
}
