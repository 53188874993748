import React, { useState, useRef } from "react";
import { Recommendation } from "./reducer";
import { animationStyle } from "common/utils";

export const RecommendationModal: React.FC<{
  recommendation: Recommendation;
  onDismissed: () => void;
}> = ({ recommendation, onDismissed }) => {
  const [exit, setExit] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="recommendationModalContainer"
      className={exit ? "fadeOutContainer" : ""}
      onClick={() => {
        setExit(true);
        setTimeout(onDismissed, 1000);
      }}
    >
      <div
        id="recommendationModal"
        className={exit ? "slideOutModal" : ""}
        style={animationStyle(exit, modalRef)}
        ref={modalRef}
      >
        <span className="recommendationTitle">{recommendation.title}</span>
        <img src={recommendation.image} alt="" />
        <div
          className="recommendationModalDescription"
          dangerouslySetInnerHTML={{ __html: recommendation.content }}
        />
      </div>
    </div>
  );
};
