import React, {
  FC,
  ReactNode,
  Fragment,
  CSSProperties,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { Point } from "components/data";
import { SpeedChartIndicator } from "./SpeedChartIndicator";
import {
  AbsoluteDetailedCurveChart,
  Pair,
} from "components/detailed_curve_chart/AbsoluteDetaileddCurveChart";
import { Span } from "common/Span";
import { Link } from "react-router-dom";
import { Locked } from "components/locked/Locked";

export const SPEED_CHART_COLORS = [
  "#F27940",
  "#F27439",
  "#F37B3D",
  "#F48443",
  "#F58D48",
  "#F5954D",
  "#F79E52",
  "#F8A658",
  "#F9AF5D",
  "#FAB762",
  "#FBBF67",
  "#FCC86D",
  "#FDCF71",
  "#F2D071",
  "#E2CE6E",
  "#D3CB6C",
  "#C6C969",
  "#B6C766",
  "#A8C464",
  "#9AC261",
  "#8CC05F",
  "#7CBD5C",
  "#6DBB59",
  "#60B957",
];

export const ARCS_SKIPPED_START = 3;
export const ARCS_SKIPPED_END = 3;
export const ARCS_DRAWN = SPEED_CHART_COLORS.length;
export const NUMBER_OF_ARCS =
  ARCS_DRAWN + ARCS_SKIPPED_START + ARCS_SKIPPED_END;

export const NUMBER_OF_FRAMES = 60;
export const STEP_ANGLE = (2 * Math.PI) / NUMBER_OF_ARCS;
export const START_ANGLE = Math.PI / 2 + ARCS_SKIPPED_START * STEP_ANGLE;

export const SpeedChart: FC<SpeedChartProps> & {
  defaultProps: Partial<SpeedChartProps>;
} = (props: SpeedChartProps) => {
  const STROKE_WIDTH = 12;
  const RADIUS = 50 - STROKE_WIDTH;
  const CENTER = new Point(50, 50 + STROKE_WIDTH / 4);

  const OFFSET = 0;
  const [showUpdated, setShowUpdated] = useState(false);

  const pointForArc = (arcAngle: number, radius = RADIUS): Point => {
    const newX = CENTER.x + radius * Math.cos(arcAngle);
    const newY = CENTER.y + radius * Math.sin(arcAngle);

    return new Point(newX, newY);
  };

  const drawArc = (
    previousPoint: Point,
    destinationPoint: Point,
    color: string,
    opacity = 1,
    strokeLinecap: "butt" | "round" = "butt",
    id?: string
  ): ReactNode => {
    return (
      <path
        key={previousPoint.string()}
        fill="none"
        stroke={color}
        strokeWidth={STROKE_WIDTH}
        style={{ opacity }}
        d={`M ${previousPoint.x} ${previousPoint.y} A ${RADIUS} ${RADIUS} 0 0 1 ${destinationPoint.x} ${destinationPoint.y}`}
        strokeLinecap={strokeLinecap}
        id={id}
      />
    );
  };

  const color = (index: number): string => {
    return SPEED_CHART_COLORS[index];
  };

  const roundedArc = (
    startAngle: number,
    endAngle: number,
    opacity: number,
    color: string,
    id?: string
  ): ReactNode => {
    const startPoint = pointForArc(startAngle);
    const endPoint = pointForArc(endAngle);

    return drawArc(startPoint, endPoint, color, opacity, "round", id);
  };

  const drawArcs = (
    opacity: number,
    numberOfArcs: number,
    drawRoundedArcs: boolean
  ): ReactNode => {
    const arcs: ReactNode[] = [];
    if (drawRoundedArcs) {
      arcs.push(roundedArc(START_ANGLE - 0.1, START_ANGLE, opacity, color(0)));
    }

    for (let i = 0; i < numberOfArcs; i++) {
      const startAngle = START_ANGLE + i * STEP_ANGLE + OFFSET;
      const endAngle = START_ANGLE + (i + 1) * STEP_ANGLE;

      const startPoint = pointForArc(startAngle);
      const endPoint = pointForArc(endAngle);
      arcs.push(drawArc(startPoint, endPoint, color(i), opacity));
    }
    if (drawRoundedArcs) {
      arcs.push(
        roundedArc(
          START_ANGLE + numberOfArcs * STEP_ANGLE,
          START_ANGLE + numberOfArcs * STEP_ANGLE + 0.1,
          opacity,
          color(numberOfArcs - 1),
          "debugy"
        )
      );
    }

    return arcs;
  };

  const text = (): ReactNode => (
    <text
      x={CENTER.x}
      y={CENTER.y}
      dominantBaseline="middle"
      textAnchor="middle"
      className="sourceSansProText"
      fill="white"
      style={textStyle()}
    >
      {props.value}%
    </text>
  );

  const indicatorColor = () => {
    return SPEED_CHART_COLORS[Math.floor((props.value / 100) * ARCS_DRAWN)];
  };

  const indicatorAngle = useMemo(
    () => START_ANGLE + props.arcNumbers * STEP_ANGLE + 0.15,
    [props.arcNumbers]
  );

  const indicator = (): ReactNode => {
    return (
      <SpeedChartIndicator
        center={CENTER}
        radius={RADIUS / 2}
        largeRadius={RADIUS}
        angle={indicatorAngle}
        color={indicatorColor()}
      />
    );
  };

  const sideLegend = () => {
    return (
      <div
        className={`legend ${props.smallLegend ? "lqi" : ""} ${
          props.legendClass
        }`}
      >
        <p
          className="chartTitle"
          style={
            props.showSeeDetails
              ? { marginTop: "0px", marginBottom: "5px" }
              : {}
          }
        >
          {props.text.split("\n").map((item, index) => (
            <Fragment key={index}>
              {item}
              <br />
            </Fragment>
          ))}
        </p>
        {props.showSeeDetails && seeDetails()}
      </div>
    );
  };

  const seeDetails = () => (
    <Link to="/retention">
      <Span className="viewMore">See Details</Span>
    </Link>
  );

  const textStyle = (): CSSProperties => {
    if (!props.large) {
      return {};
    } else {
      return {
        fontSize: "26px",
        fontWeight: 600,
        fill: "#334050",
      };
    }
  };

  const topLegend = () => (
    <span className="speedChartLargeLabel">{props.text}</span>
  );

  const shadow = (): ReactNode => (
    <Fragment>
      <filter id="dropshadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dx="2" dy="2" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.5" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      <circle
        cx={CENTER.x}
        cy={CENTER.y}
        r={RADIUS + STROKE_WIDTH / 2}
        filter="url(#dropshadow)"
        fill="none"
        strokeWidth="2"
        stroke="rgba(10, 41, 8)"
        opacity="0.05"
      />
    </Fragment>
  );

  const whiteCircle = (): ReactNode => {
    const startingPoint = pointForArc(START_ANGLE);
    const endPoint = pointForArc(START_ANGLE + ARCS_DRAWN * STEP_ANGLE);

    return (
      <path
        fill="none"
        stroke="white"
        strokeWidth={STROKE_WIDTH}
        d={`M ${startingPoint.x} ${startingPoint.y} A ${RADIUS} ${RADIUS} 0 1 1 ${endPoint.x} ${endPoint.y}`}
      />
    );
  };

  const drawSeparators = (): ReactNode => {
    const paths: ReactElement[] = [];
    for (let i = 0; i <= ARCS_DRAWN; i++) {
      const angle = START_ANGLE + i * STEP_ANGLE;
      const startingPoint = pointForArc(angle, RADIUS - STROKE_WIDTH / 2);
      const endPoint = pointForArc(angle, RADIUS + STROKE_WIDTH / 2);

      paths.push(
        <path
          key={i}
          d={`M ${startingPoint.x} ${startingPoint.y} L ${endPoint.x} ${endPoint.y}`}
          stroke="white"
          strokeWidth="1"
          opacity="0.4"
        />
      );
    }

    return paths;
  };

  const finalRoundedArc = (): ReactNode => {
    const startAngle = START_ANGLE + STEP_ANGLE * ARCS_DRAWN;
    const endAngle = startAngle + 0.1;

    return drawArc(
      pointForArc(startAngle),
      pointForArc(endAngle),
      SPEED_CHART_COLORS[SPEED_CHART_COLORS.length - 1],
      0.3,
      "round"
    );
  };

  const indicatorLeft = () => (
    <span
      className="speedChartIndicatorLabel"
      style={{
        color: SPEED_CHART_COLORS[0],
        position: "relative",
        left: "1em",
        bottom: "10px",
      }}
    >
      -100%
    </span>
  );

  const indicatorRight = () => (
    <span
      className="speedChartIndicatorLabel"
      style={{
        color: SPEED_CHART_COLORS[SPEED_CHART_COLORS.length - 1],
        position: "relative",
        left: "-1em",
        bottom: "10px",
      }}
    >
      100%
    </span>
  );

  const lastUpdated = (): ReactNode => (
    <AbsoluteDetailedCurveChart
      title={props.text}
      values={props.valuesOverTime!}
    />
  );

  return (
    <Fragment>
      {props.large && !props.largeLeft && topLegend()}
      <div style={props.style} className="relative">
        <div
          className={`simpleChartContainerColumn simpleChart speedChart ${
            props.clickable ? "speedChartContainer" : ""
          }${props.locked ? "" : ""}`}
          style={
            props.largeLeft
              ? {}
              : {
                  height: props.large ? "16em" : "8em",
                  ...props.svgStyle,
                }
          }
          onClick={() => {
            props.onClick(props.text, props.value);
          }}
        >
          <svg
            viewBox="0 0 100 100"
            width={props.large ? "12em" : "7em"}
            height={props.large ? "12em" : "7em"}
            style={{ overflow: "visible" }}
            onMouseEnter={() => setShowUpdated(true)}
            onMouseLeave={() => setShowUpdated(false)}
          >
            {shadow()}
            {finalRoundedArc()}
            {whiteCircle()}
            {drawArcs(0.3, ARCS_DRAWN, false)}
            {drawArcs(1, Math.ceil(props.arcNumbers), true)}
            {drawSeparators()}
            {!props.large && indicator()}
            {text()}
          </svg>
          {!props.large && sideLegend()}
          {!props.large && showUpdated && props.showUpdatedAt && lastUpdated()}
        </div>

        {props.locked && <Locked />}
      </div>

      <div className="speedIndicatorsContainer">
        {props.hideWhiteLines && indicatorLeft()}
        {props.hideWhiteLines && indicatorRight()}
      </div>
      {props.large && props.largeLeft && topLegend()}
    </Fragment>
  );
};

const defaultProps: Partial<SpeedChartProps> = {
  value: 70,
  text: "SOCIAL LIFE SCORE",
  large: false,
  onClick: () => {},
  hideWhiteLines: false,
  range: {
    start: 0,
    end: 100,
  },
  clickable: true,
  smallLegend: true,
  showUpdatedAt: true,
  largeLeft: false,
  showSeeDetails: false,
};

SpeedChart.defaultProps = defaultProps;

export interface SpeedChartProps {
  value: number;
  text: string;
  large: boolean;
  onClick: (label: string, value: number) => void;
  style?: CSSProperties;
  svgStyle?: CSSProperties;
  hideWhiteLines: boolean;
  clickable: boolean;

  arcNumbers: number;
  smallLegend: boolean;
  showUpdatedAt: boolean;
  legendClass?: string;
  largeLeft: boolean;
  showSeeDetails: boolean;

  range: { start: number; end: number };
  valuesOverTime?: Pair[];
  locked?: boolean;
}

export interface AnimationData {
  arc: number;
  angle: number;
}
