import React from "react";
import { Recommendation } from "./reducer";
import "./recommendations.css";

export const RecommendationCell: React.FC<{
  recommendation: Recommendation;
  onClick: (recommendation: Recommendation) => void;
}> = ({ recommendation, onClick }) => {
  return (
    <div
      className="recommendationContainer"
      onClick={() => onClick(recommendation)}
    >
      <img src={recommendation.image} alt="" />
      <span className="recommendationTitle summary">
        {recommendation.title}
      </span>
      <div
        className="recommendationExcerpt"
        dangerouslySetInnerHTML={{ __html: recommendation.excerpt }}
      ></div>
      <span className="recommendationViewMore">View More</span>
    </div>
  );
};
