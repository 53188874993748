import React, { Component, ReactNode, Fragment } from "react";
import "./detailedCurveChart.css";
import { Point } from "../data";
import { bezierCommand } from "../common";
import "components/curve_chart/curveChart.css";
import { Span } from "common/Span";
import { Locked } from "components/locked/Locked";

export class DetailedCurveChart extends Component<Props, State> {
  leftOffset = 60;
  bottomOffset = 20;

  state = {
    width: 0,
    height: 0,
    innerWidth: 0,
    animate: false,
  };

  ref: HTMLDivElement | null = null;

  componentDidMount() {
    window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
  }

  measure = () => {
    if (this.ref) {
      const rect = this.ref.getBoundingClientRect();

      if (this.state.width !== rect.width) {
        this.setState({
          ...this.state,
          width: rect.width,
          height: rect.height,
        });
      }
    }
  };

  render = () => (
    <div
      className={
        this.props.simpleValues !== undefined
          ? "detailedCurveChartContainerOverlay"
          : "chartContainer detailedCurveChartContainer"
      }
    >
      <div
        className={`detailedCurveTitleContainer ${
          this.props.simpleValues !== undefined ? "center" : ""
        }`}
      >
        <span className="detailedCurveTitle">{this.props.label}</span>
      </div>

      <div
        className="detailedCurveChartSvgContainer"
        ref={(ref) => {
          this.ref = ref;
          this.measure();
        }}
      >
        <svg width="100%" height="100%" style={{ overflow: "visible" }}>
          <defs>
            {!this.props.simpleValues && (
              <Fragment>
                {this.marker(this.props.healthyColor!, 0)}
                {this.marker(this.props.riskColor!, 1)}
                {this.marker(this.props.strugglingColor!, 2)}
              </Fragment>
            )}

            {this.props.simpleValues && (
              <Fragment>{this.marker(this.props.simpleColor!, 0)}</Fragment>
            )}
          </defs>

          {this.props.zoom ? this.percentagesZoomed() : this.percentages()}
          {this.props.zoom ? this.linesZoomed() : this.lines()}
          {this.bottomLabels()}

          {!this.props.simpleValues && (
            <Fragment>
              {this.renderCurves(
                this.props.healthyValues!,
                this.props.healthyColor!,
                "dot0"
              )}
              {this.renderCurves(
                this.props.riskValues!,
                this.props.riskColor!,
                "dot1"
              )}
              {this.renderCurves(
                this.props.strugglingValues!,
                this.props.strugglingColor!,
                "dot2"
              )}
            </Fragment>
          )}

          {this.props.simpleColor && (
            <Fragment>
              {this.renderCurves(
                this.props.simpleValues!,
                this.props.simpleColor!,
                "dot0"
              )}
            </Fragment>
          )}
        </svg>
      </div>

      {!this.props.simpleValues && (
        <div className="detailedCurveChartLegendContainer">
          <span
            className="detailedCurveCircle"
            style={{ backgroundColor: this.props.strugglingColor }}
          />
          <span className="detailedCurveLegendLabel">Struggling</span>

          <span
            className="detailedCurveCircle"
            style={{ backgroundColor: this.props.riskColor }}
          />
          <span className="detailedCurveLegendLabel">At Risk</span>

          <span
            className="detailedCurveCircle"
            style={{ backgroundColor: this.props.healthyColor }}
          />
          <span className="detailedCurveLegendLabel">Healthy</span>
        </div>
      )}

      {this.props.simpleValues && this.lastUpdatedAt()}
      {this.props.locked && <Locked />}
    </div>
  );

  lastUpdatedAt = () => (
    <div className="detailedCurveChartLegendContainer lastUpdated">
      <Span>
        Last updated{" "}
        {this.props.bottomLabels[this.props.bottomLabels.length - 1]}
      </Span>
    </div>
  );

  zoomedValues = () => {
    let values = this.props.simpleValues!;
    values.sort((a, b) => a - b);
    //remove duplicates
    values = values.filter((item, index, self) => index === self.indexOf(item));

    const result = [];
    for (let i = 0; i < values.length; i++) {
      result.push(values[i] - (values[i] % 10));
    }
    //adds the highest value + 10
    result.push(
      values[values.length - 1] - (values[values.length - 1] % 10) + 10
    );

    return result
      .reverse()
      .filter((item, index, self) => index === self.indexOf(item));
  };

  percentages = () => {
    const views: ReactNode[] = [];
    for (let i = 0; i <= 5; i++) {
      views.push(
        <text
          key={`text${i}`}
          x="0"
          y={(this.state.height / 5) * 0.9 * i}
          className="detailedCurveLeftLabel text"
          alignmentBaseline="middle"
        >
          {100 - 20 * i}%
        </text>
      );
    }

    return views;
  };

  percentagesZoomed = () => {
    const height = this.state.height * 0.9 - this.bottomOffset;
    const values = this.zoomedValues();

    const views: ReactNode[] = [];
    for (let i = 0; i < values.length; i++) {
      views.push(
        <text
          key={`text${i}`}
          x="0"
          y={(height / (values.length - 1)) * i}
          className="detailedCurveLeftLabel text"
          alignmentBaseline="middle"
        >
          {values[i]}%
        </text>
      );
    }

    return views;
  };

  lines = () => {
    const views: ReactNode[] = [];
    for (let i = 0; i <= 5; i++) {
      const y = ((this.state.height * 0.9) / 5) * i;

      views.push(
        <path
          key={`path${i}`}
          d={`M ${this.leftOffset} ${y} L ${this.state.width} ${y}`}
          stroke="#D6E6EE"
          strokeWidth="0.25"
          strokeDasharray="1"
        />
      );
    }

    return views;
  };

  linesZoomed = () => {
    const height = this.state.height * 0.9 - this.bottomOffset;
    const values = this.zoomedValues();

    const views: ReactNode[] = [];
    for (let i = 0; i < values.length; i++) {
      const y = (height / (values.length - 1)) * i;

      views.push(
        <path
          key={`path${i}`}
          d={`M ${this.leftOffset} ${y} L ${this.state.width} ${y}`}
          stroke="#D6E6EE"
          strokeWidth="1"
          strokeDasharray="5"
        />
      );
    }

    return views;
  };

  bottomLabels = () => {
    const views: ReactNode[] = [];
    for (let i = 0; i < this.props.bottomLabels.length; i++) {
      const x =
        ((this.state.width - this.leftOffset) /
          (this.props.bottomLabels.length - 1)) *
          i +
        this.leftOffset;
      views.push(
        <text
          key={`text${i}`}
          y={this.state.height * 0.95}
          x={x}
          className="detailedCurvedBottomLabel text"
          textAnchor={
            i === 0
              ? "start"
              : i === this.props.bottomLabels.length - 1
              ? "end"
              : "middle"
          }
        >
          {this.props.bottomLabels[i]}
        </text>
      );
    }
    return views;
  };

  buildPoints = (values: number[]): Point[] => {
    const points: Point[] = [];
    for (let i = 0; i < values.length; i++) {
      const x =
        ((this.state.width - this.leftOffset) / (values.length - 1)) * i +
        this.leftOffset;
      points.push(
        new Point(
          x,
          this.state.height * 0.9 - (this.state.height * 0.9 * values[i]) / 100
        )
      );
    }

    return points;
  };

  buildPointsZoomed = (values: number[]): Point[] => {
    const height = this.state.height * 0.9 - this.bottomOffset;
    const zoomedValues = this.zoomedValues();
    const min = zoomedValues[zoomedValues.length - 1];
    const max = zoomedValues[0];

    const points: Point[] = [];
    for (let i = 0; i < values.length; i++) {
      const x =
        ((this.state.width - this.leftOffset) / (values.length - 1)) * i +
        this.leftOffset;

      const zoomedValue = ((values[i] - min) / (max - min)) * height;
      points.push(new Point(x, height - zoomedValue));
    }

    return points;
  };

  renderCurves = (values: number[], color: string, marker: string) => {
    const points = this.props.zoom
      ? this.buildPointsZoomed(values)
      : this.buildPoints(values);
    const d = points.reduce(
      (acc, point, i, a) =>
        i === 0
          ? `M ${point.x},${point.y}`
          : `${acc} ${bezierCommand(point, i, a)}`,
      ""
    );

    return (
      <path
        d={d}
        fill="none"
        stroke={color}
        strokeWidth="2"
        className={this.state.animate ? "animateSVGPath" : ""}
        markerMid={`url(#${marker})`}
        markerStart={`url(#${marker})`}
        markerEnd={`url(#${marker})`}
        ref={(ref) => {
          if (ref && ref.parentElement && !this.props.simpleValues) {
            const rect = ref.parentElement.getBoundingClientRect();
            ref.style.strokeDasharray = `${rect.width}px`;
            ref.style.strokeDashoffset = `${rect.width}px`;

            if (rect.width > 0 && !this.state.animate) {
              setTimeout(
                () => this.setState({ ...this.state, animate: true }),
                100
              );
            }
          }
        }}
      />
    );
  };

  marker = (color: string, index: number) => (
    <marker
      id={`dot${index}`}
      viewBox="0 0 10 10"
      refX="5"
      refY="5"
      markerWidth="7.5"
      markerHeight="7.5"
    >
      <circle cx="5" cy="5" r="4" fill={color} />
    </marker>
  );
}

interface Props {
  label: string;
  bottomLabels: string[];

  healthyValues?: number[];
  riskValues?: number[];
  strugglingValues?: number[];

  healthyColor?: string;
  riskColor?: string;
  strugglingColor?: string;

  simpleValues?: number[];
  simpleColor?: string;
  locked?: boolean;
  zoom?: boolean;
}

interface State {
  width: number;
  height: number;
  animate: boolean;
}
