import {
  HealthRisks,
  CostsAction,
  HEALTH_RISKS_LOADED,
  CostCategories,
  COST_CATEGORIES_LOADED,
  CostDistributionType,
  CostDistributionElement,
  COST_DISTRIBUTION_LOADED,
  HEALTH_COST_RISKS_LOADED,
} from "./types";
import { api } from "../common/api";
import { AppThunk } from "../common/state";
import { dataLoaded } from "../common/types";

const healthRisksLoadedAction = (healthRisks: HealthRisks): CostsAction => ({
  type: HEALTH_RISKS_LOADED,
  healthRisks,
});

const costCategoriesLoadedAction = (
  costCategories: CostCategories
): CostsAction => ({
  type: COST_CATEGORIES_LOADED,
  costCategories,
});

const costDistributionLoadedAction = (
  distribution: CostDistributionElement[],
  type: CostDistributionType,
  locked: boolean
): CostsAction => ({
  type: COST_DISTRIBUTION_LOADED,
  distribution,
  distributionType: type,
  locked,
});

export const loadHealthRisks = (): AppThunk => async (dispatch) => {
  const healthRisks = await api.getHealthRisks();

  dispatch(healthRisksLoadedAction(healthRisks));
};

export const loadCostCategories = (): AppThunk => async (dispatch) => {
  const costCategories = await api.getCostCategories();

  dispatch(costCategoriesLoadedAction(costCategories));
};

export const loadCostDistribution = (
  type: CostDistributionType
): AppThunk => async (dispatch) => {
  const costDistribution = await api.getCostDistribution(type);

  dispatch(
    costDistributionLoadedAction(
      costDistribution.data,
      type,
      costDistribution.locked
    )
  );
};

export const loadHealthRisksCosts = (): AppThunk => async (dispatch) => {
  const healthRiskCosts = await api.getHealthRiskCosts();

  dispatch(dataLoaded(HEALTH_COST_RISKS_LOADED, healthRiskCosts));
};
