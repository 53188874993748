import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./loader.css";
import { CSSTransition } from "react-transition-group";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import { isTour } from "./utils";

export const LoaderWrappper: FunctionComponent<{
  child: ReactNode;
  loading: boolean;
}> = ({ child: Child, loading }) => {
  const timeout = 0;

  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        setLoggedIn(session.isValid());
      })
      .catch(() => {
        setLoggedIn(false);
      });
  }, []);

  const showLoading = useMemo(() => {
    return !isTour() && loading;
  }, [loading]);

  if (!loggedIn) {
    return <Redirect to="/signup/1" />;
  }

  return (
    <div>
      <CSSTransition
        classNames="opacity"
        timeout={timeout}
        in={showLoading}
        unmountOnExit
      >
        <div className="loaderContainer" key="loader">
          <Loader type="Grid" width={100} height={100} />
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="opacity"
        in={!showLoading}
        timeout={timeout}
        unmountOnExit
      >
        <div
          key="nonloader"
          style={{
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {Child}
        </div>
      </CSSTransition>
    </div>
  );
};
