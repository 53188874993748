import React, { useRef, ReactNode } from "react";
import { useMeasure } from "common/hooks";

export const HorizontalSlider: React.FC<Props> = (props: Props) => {
  const svg = useRef<SVGSVGElement>(null);
  const dimensions = useMeasure(svg);

  const INDICATOR_EXTRA = 5;
  const STROKE_WIDTH = 2;
  const INDICATOR_WIDTH = 5;
  const FILL_MARGIN = 4;

  const slider = (): ReactNode => {
    const left = STROKE_WIDTH;
    const right = dimensions.width - STROKE_WIDTH;
    const width = right - left;

    const top = INDICATOR_EXTRA;
    const bottom = dimensions.height - INDICATOR_EXTRA;
    const height = bottom - top;

    return (
      <rect
        x={left}
        y={top}
        width={width}
        height={height}
        stroke="#7f909e"
        strokeWidth={STROKE_WIDTH}
        fill="none"
        rx="10"
        ry="10"
        opacity="0.3"
      />
    );
  };

  const separator = (): ReactNode => {
    const x = dimensions.width / 2 - INDICATOR_WIDTH / 2;
    const width = INDICATOR_WIDTH;
    const y = 0;
    const height = dimensions.height;

    return (
      <rect
        x={x}
        width={width}
        y={y}
        height={height}
        rx="4"
        ry="4"
        fill="#7F909E"
      />
    );
  };

  const fill = (): ReactNode => {
    const x = dimensions.width / 4;
    const width = dimensions.width / 2 - x;
    const y = INDICATOR_EXTRA + STROKE_WIDTH + FILL_MARGIN;
    const height =
      dimensions.height - INDICATOR_EXTRA - STROKE_WIDTH - FILL_MARGIN - y;

    return (
      <rect
        x={x}
        width={width}
        y={y}
        height={height}
        fill="#F17036"
        rx="8"
        ry="8"
      />
    );
  };

  return (
    <div id="sliderValueContainer">
      <span id="sliderValue">-{props.value}%</span>
      <div id="sliderContainer">
        <span>-100%</span>
        <svg id="slider" ref={svg}>
          {slider()}
          {fill()}
          {separator()}
        </svg>
        <span style={{ color: "#3E9B4B" }}>+100%</span>
      </div>
    </div>
  );
};

interface Props {
  value: number;
}
