import { Locked } from "components/locked/Locked";
import React, { Component } from "react";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import { assets } from "../../assets/assets";
import "./genderChart.css";

export class GenderChart extends Component<Props> {
  render = () => (
    <div className="genderChartContainer">
      <p className="genderChartTitle">GENDER</p>
      <div className="genderChartTopContainer">
        <div className="genderChartColumnContainer">
          <img src={assets.genderChart.iconFemale} alt="" />
          <p className="genderLabel">{this.props.femaleValue}%</p>
        </div>

        <div className="genderChartColumnContainer">
          <div className="genderCircleContainer">
            <CircularProgressbarWithChildren
              value={this.props.maleValue + this.props.nonBinaryValue}
              styles={buildStyles({
                pathColor: "#1E6834",
                trailColor: "#ADEAA4",
              })}
            >
              <CircularProgressbar
                value={this.props.femaleValue}
                styles={buildStyles({
                  pathColor: "#4FB654",
                  trailColor: "transparent",
                })}
              />
            </CircularProgressbarWithChildren>
          </div>
          <span style={{ flexGrow: 1 }} />

          <div className="nonBinaryContainer">
            <span className="nonBinaryCircle" />
            <span>Non-Binary</span>
          </div>
          <p className="genderLabel" style={{ marginTop: 0 }}>
            {this.props.nonBinaryValue}%
          </p>
        </div>

        <div className="genderChartColumnContainer">
          <img src={assets.genderChart.iconMale} alt="" />
          <p className="genderLabel">{this.props.maleValue}%</p>
        </div>
      </div>
      {this.props.locked && <Locked />}
    </div>
  );
}

interface Props {
  femaleValue: number;
  maleValue: number;
  nonBinaryValue: number;

  locked?: boolean;
}
