import React, { Component, CSSProperties } from "react";
import "./verticalSimpleBarChart.css";

export class VerticalSimpleBarChart extends Component<Props> {
  render = () => (
    <div className="verticalSimpleBarChartVerticalContainer">
      <span className="extrapolatedSmallMargin">
        Extrapolated to 10000 people
      </span>
      <div className="verticalSimpleBarChartContainer">
        <div className="verticalSimpleBarChartLeftContainer">
          {this.props.labels.map((item, index) => (
            <span
              key={index}
              style={{
                marginTop:
                  index === this.props.labels.length - 1 ? "0px" : "-8px",
              }}
            >
              {item}
            </span>
          ))}
        </div>

        <div className="verticalSimpleBarChartGridContainer">
          <div className="verticalSimpleBarChartLine" />
          <div className="verticalSimpleBarChartLine" />
          <div className="verticalSimpleBarChartLine" />
          <div className="verticalSimpleBarChartLineBottom" />

          <div className="verticalSimpleBarChartDrawContainer">
            <div
              className="verticalSimpleBarChartBar"
              style={{
                ...this.style("#F17036", this.props.values[0], 8, 8),
                ...this.delay(3),
              }}
            ></div>
            <div
              className="verticalSimpleBarChartBar"
              style={{
                ...this.style("#FEBE3A", this.props.values[1]),
                ...this.delay(2),
              }}
            ></div>
            <div
              className="verticalSimpleBarChartBar"
              style={{
                ...this.style("#4FB654", this.props.values[2]),
                ...this.delay(1),
              }}
            ></div>
            <div
              className="verticalSimpleBarChartBar"
              style={{
                ...this.style("#45525E", this.props.values[3], 0, 0, 8, 8),
                ...this.delay(0),
              }}
            ></div>
          </div>
        </div>
      </div>

      <div
        className="fillChartLegendContainer"
        style={{ justifyContent: "center" }}
      >
        <div className="fillChartLegendEntry">
          <span
            className="fillChartCircle"
            style={{ backgroundColor: "#F17036" }}
          ></span>
          <span>MSD</span>
        </div>
        <div className="fillChartLegendEntry" style={{ marginRight: "20px" }}>
          <span
            className="fillChartCircle"
            style={{ backgroundColor: "#FEBE3A" }}
          ></span>
          <span>SAD</span>
        </div>
        <div className="fillChartLegendEntry" style={{ marginRight: "20px" }}>
          <span
            className="fillChartCircle"
            style={{ backgroundColor: "#4FB654" }}
          ></span>
          <span>CVD</span>
        </div>
        <div className="fillChartLegendEntry" style={{ marginRight: "20px" }}>
          <span
            className="fillChartCircle"
            style={{ backgroundColor: "#45525E" }}
          ></span>
          <span>Metabolic</span>
        </div>
      </div>
    </div>
  );

  delay = (index: number): CSSProperties => {
    const reversed = [...this.props.values].reverse();
    let previousAnimationDuration = 0;
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        previousAnimationDuration += 500 * reversed[i];
      }
    }
    const animationDuration = 500 * reversed[index];

    return {
      animationDuration: `${animationDuration}ms`,
      animationDelay: `${previousAnimationDuration}ms`,
    };
  };

  style = (
    backgroundColor: string,
    flex: number,
    topLeftRadius = 0,
    topRightRadius = 0,
    bottomLeftRadius = 0,
    bottomRightRadius = 0
  ): CSSProperties => ({
    backgroundColor,
    flexGrow: flex,
    borderTopLeftRadius: `${topLeftRadius}px`,
    borderTopRightRadius: `${topRightRadius}px`,
    borderBottomLeftRadius: `${bottomLeftRadius}px`,
    borderBottomRightRadius: `${bottomRightRadius}px`,
  });
}

interface Props {
  labels: string[];
  values: number[];
}
