import { RefObject } from "react";

export interface GenericAction<T extends string, P> {
  type: T;
  payload: P;
}

export const dataLoaded = <T extends string, P>(
  type: T,
  data: P
): GenericAction<T, P> => ({
  type,
  payload: data,
});

export interface Blurrable {
  blur?: boolean;
  highlight?: boolean;
}

export interface GuidedProps {
  blurIndex: number;
  blur: boolean;

  highlightedItems: Record<number, RefObject<HTMLDivElement>>;
}

export interface Dimensions {
  width: number;
  height: number;
}
