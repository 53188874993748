import React, { Component } from "react";
import "./indicator.css";
import { AbsoluteDetailedCurveChart } from "./detailed_curve_chart/AbsoluteDetaileddCurveChart";

export class Indicator extends Component<Props, State> {
  static defaultProps = {
    large: false,
  };

  state: State = { showUpdatedAt: false };

  render = () => (
    <div
      className={`indicatorContainer ${
        this.props.large ? " indicatorContainerLarge" : ""
      } ${this.state.showUpdatedAt ? "showUpdatedAt" : ""}`}
      // onMouseEnter={() => this.setState({ ...this.state, showUpdatedAt: true })}
      // onMouseLeave={() =>
      //   this.setState({ ...this.state, showUpdatedAt: false })
      // }
    >
      <img
        src={this.image()}
        className={
          this.props.large ? "indicatorImageLarge" : "indicatorImageSmall"
        }
        alt=""
      />
      <p>{this.props.text}</p>
      <p className="indicatorPercentage" style={{ background: this.color() }}>
        {this.props.value}%
      </p>

      {this.state.showUpdatedAt && (
        <AbsoluteDetailedCurveChart
          title={this.props.text}
          normalClass="indicatorNormal"
          leftClass="indicatorLeft"
        />
      )}
    </div>
  );

  color = () => {
    if (this.props.value < 33) {
      return "#F17036";
    } else if (this.props.value >= 33 && this.props.value < 66) {
      return "#FEBE3A";
    } else {
      return "#4FB654";
    }
  };

  image = () => {
    if (this.props.value < 33) {
      return this.props.image.red;
    } else if (this.props.value >= 33 && this.props.value < 66) {
      return this.props.image.yellow;
    } else {
      return this.props.image.green;
    }
  };
}

interface Props {
  image: Image;
  text: string;
  value: number;

  large: boolean;
}

interface State {
  showUpdatedAt: boolean;
}

interface Image {
  green: any;
  red: any;
  yellow: any;
}
