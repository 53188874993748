import { Locked } from "components/locked/Locked";
import { Age } from "engagement/types";
import React, { Component } from "react";
import "./triangle.css";

const colors = ["#ADEAA4", "#4FB654", "#2C823F", "#1b4f26"];

export class TriangleChart extends Component<TriangleChartProps> {
  color = (index: number) => {
    if (index > colors.length) {
      return colors[0];
    }
    return colors[index];
  };

  triangle = (age: Age, index: number) => {
    return (
      <Triangle
        key={`age${index}`}
        value={age.value}
        background={this.color(index)}
        opacity={index === 0 || index === 1 ? 0.7 : 0.8}
        negativeLeftMargin={index > 0}
        label={age.range}
      />
    );
  };

  render = () => (
    <div className="triangleChartContainer">
      <p className="triangleChartLabel">AGE</p>
      <div className="triangleRowContainer">
        {this.props.values.map(this.triangle)}
      </div>

      {this.props.locked && <Locked />}
    </div>
  );
}

class Triangle extends Component<TriangleProps> {
  static defaultProps = {
    value: 0,
  };

  render = () => {
    const width = this.width();

    return (
      <div
        className="triangleContainer"
        style={{
          marginLeft: this.props.negativeLeftMargin ? "-40px" : 0,
        }}
      >
        <svg
          width={this.width()}
          height={this.width()}
          className="triangleSvg"
          style={{
            fill: this.props.background,
            opacity: 0.7,
            stroke: this.props.background,
          }}
        >
          <path
            d={`M ${width / 2},10 ${width - 10},${width - 10} 10,${
              width - 10
            } z`}
          />
        </svg>
        <p className="triangleValue">{this.props.value}%</p>
        <p className="triangleCategory">{this.props.label}</p>
      </div>
    );
  };

  width = () => (this.props.value / 100) * 200 + 50;
}

interface TriangleProps {
  value: number;
  background: string;
  opacity: number;
  negativeLeftMargin: boolean;
  label: string;
}

interface TriangleChartProps {
  values: Age[];

  locked?: boolean;
}
