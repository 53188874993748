import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "common/state";
import { RecommendationCell } from "./RecommendationCell";
import { assets } from "assets/assets";
import { keys } from "common/utils";
import {
  RecommendationKey,
  loadRecommendations,
  RecommendationsData,
  Recommendation,
} from "./reducer";
import Loader from "react-loader-spinner";

export const Recommendations: React.FC<{
  setSelectedRecommendation: (recommendation: Recommendation) => void;
  lqiId?: string;
  healthRisk?: string;
}> = ({ setSelectedRecommendation, lqiId, healthRisk }) => {
  const [recommendationsPage, setRecommendationsPage] = useState(0);

  const next = () => {
    if (recommendationsPage < recommendations.length - 3) {
      setRecommendationsPage(recommendationsPage + 3);
    }
  };

  const prev = () => {
    if (recommendationsPage >= 3) {
      setRecommendationsPage(recommendationsPage - 3);
    }
  };

  const recommendations = useSelector<RootState, Recommendation[]>((state) =>
    keys<RecommendationsData, RecommendationKey>(
      state.recommendations.recommendations
    ).map(
      (key) => state.recommendations.recommendations[key as RecommendationKey]!
    )
  );
  const loading = useSelector<RootState, boolean>(
    (state) => state.recommendations.loading
  );

  const displayRecommendations = (): Recommendation[] => {
    const left = recommendationsPage;
    const right = left + 3;

    return recommendations.slice(left, right);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRecommendations(lqiId, healthRisk));
  }, [dispatch, healthRisk, lqiId]);

  if (loading) {
    return (
      <div className="recommendationsLoaderContainer">
        <Loader type="ThreeDots" width={80} height={80} />
      </div>
    );
  }

  return (
    <Fragment>
      <span className="titleLabel">Recommended articles</span>
      <div className="recommendationsContainer">
        {displayRecommendations().map((item) => (
          <RecommendationCell
            key={item.slug}
            recommendation={item}
            onClick={setSelectedRecommendation}
          />
        ))}
      </div>
      <div className="arrowContainer">
        <img
          src={assets.arrowLeft}
          alt=""
          onClick={prev}
          className={`arrow ${recommendationsPage >= 3 ? "" : "disabled"}`}
        />
        <img
          src={assets.arrowRight}
          alt=""
          onClick={next}
          className={`arrow ${
            recommendationsPage < recommendations.length - 3 ? "" : "disabled"
          }`}
        />
      </div>
    </Fragment>
  );
};

export interface RecommendationState {
  selectedRecommendation: Recommendation | null;
}

export const setSelectedRecommendation =
  (component: React.Component<{}, RecommendationState>) =>
  (recommendation: Recommendation | null) =>
    component.setState((prevState) => ({
      ...prevState,
      selectedRecommendation: recommendation,
    }));
