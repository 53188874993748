import React, { Fragment } from "react";
import { Span } from "./Span";
import { Link } from "react-router-dom";
import { KpiKey } from "components/kpis";

export const content = [
  [
    <p>
      Understand how your population scores on the four key health risk
      categories which are responsible for 90% of global healthcare costs. You
      can dive deeper in the{" "}
      <Link to="/costs">costs &amp; savings section</Link>.
    </p>,

    <p>
      Get a 5 seconds status snapshot with these key performance indicators. You
      can dive deeper into your Life Quality index in the{" "}
      <Link to="/lqi">LQi section</Link>.
    </p>,
  ],

  [
    <Span>Keep up to date with your population diversity indicators.</Span>,

    <Span>
      Monitor SYD’s engagement indicators in order to devise smart communication
      strategies as needed
    </Span>,

    <Span>
      Monitor your organisation’s NPS as well as your people’s engagement with
      SYD in order to devise smarter engagement and communication strategies.
      Click the NPS to dive deeper.
    </Span>,

    <Span>
      Monitor in real time the goals that your people are currently focusing on.
    </Span>,
  ],

  [
    <Span>
      Explore in details the breakdown of your population across the four key
      health risk categories (these are responsible for 90% of global healthcare
      costs). Click each risk to dive deeper, understand the evolution over
      time, and access relevant recommendations
    </Span>,

    <Span>
      Monitor your levels of lifestyle conditions-led absenteeism (people absent
      from their work) and presenteeism (people present at work but performing
      poorly for several possible reasons such as burnout, mental health
      conditions, physical conditions etc.)
    </Span>,

    <Span>
      Access estimates of costs generated by lifestyle conditions-led
      absenteeism &amp; presenteeism
    </Span>,

    <Span>
      Understand the breakdown of the lifestyle conditions estimated costs for
      your organisation
    </Span>,
  ],

  [
    <Span>
      Dive into the 9 dimensions of your people’s quality of life. Each
      dimension is powered by 40+ granular KPIs with a unique score for each
      individual. Those scores evolve over time and can go up or down to reflect
      your population’s quality of life. The LQi is part of iamYiam’s
      intellectual property. It was designed by our expert team of scientists,
      clinicians and behavioural psychologists. It is a nine dimensional model
      of life quality, developed by us and consistent with the dimensions later
      used in the Stanford WELL for Life Scale [Heaney, C.A.; Avery, E.C.; Rich,
      T.; Ahuja, N.J.; Winter, S.J. Stanford WELL for Life Measures Work Group
      Stanford WELL for Life: Learning What It Means to Be Well. Am. J. Health
      Promot. 2017, 31, 449.]
    </Span>,

    <Span>
      Get a 5 seconds snapshot with these key health &amp; wellbeing indicators.
      Hover over each indicator to see the evolution over time
    </Span>,

    <Span>
      Track the evolution of our LQi score over time and compare your position
      with other relevant organisations
    </Span>,

    <Span>
      Understand the level of quality of life in your population depending on
      how long they’ve been part of your organisation. Organisations can
      typically observe a ‘U’ shape, with new joiners and oldest employees
      displaying the highest LQi levels
    </Span>,
  ],
];

export const kpisContent: Record<KpiKey, React.ReactElement> = {
  health: (
    <Fragment>
      <p>
        Physical health refers to your optimal state of physical well-being. It
        goes beyond mere absence of infirmity and disease. Your lifestyle
        choices, diet and surrounding environment can greatly impact your
        physical health. A diverse set of actions ranging from the way you
        breathe and the amount of water your drink to consistent physical
        activities can improve your health score.
      </p>
    </Fragment>
  ),

  emotional: (
    <Fragment>
      <p>
        Emotional health reflects a positive outlook, your capacity to
        acknowledge and appropriately navigate your feelings. It refers to your
        ability to express or not express them constructively to others. Coping
        with stress on your own or with the aid of others, being realistic about
        what you can accomplish or expect others to accomplish within time
        constraints, finding healthy coping mechanisms during moments of great
        emotional strain, knowing when you should reach out for assistance,
        finding ways to adapt and control emotions in certain situations (real
        or imagined) and developing close, empathetic, meaningful relationships
        characterise emotional health.{" "}
      </p>
      <br />
    </Fragment>
  ),

  selfAwareness: (
    <Fragment>
      <p>
        Self-esteem reflects the lifelong evolution of your overall subjective
        emotional evaluation of your own worth and personal value. It’s a trait
        which can range from arrogant over-confidence to the depths of despair
        and complete loss of confidence; it can be consistent or varied
        depending on your personality, environment and other personal
        circumstances some of which may develop from childhood. Ideally
        self-esteem provides a healthy balance of liking, understanding,
        accepting and embracing who you are, but also having the ability to show
        resilience by building yourself up and refocusing after a challenge. It
        is having self-sufficiency and an internal independence of circumstance,
        while also recognizing that there are ways you can continue to grow and
        to develop as a person.
      </p>
    </Fragment>
  ),

  purpose: (
    <Fragment>
      <p>
        Your sense of purpose is what gives meaning and direction to your life.
         Enhancing your connection to self, nature and others are key to
        establishing purpose. For many, purpose and spiritual harmony are
        related, but purpose goes beyond belief and involves participating in
        activities that stem from one’s values. The values and beliefs that
        guide purpose may arise from contemplation, spiritual or metaphysical
        journeys, crisis, meditation, prayer or reflection on meaningful events
        in your life.  Purpose may also arise spontaneously from a playful,
        curious mind. Your purpose can have a connection to something greater or
        higher than yourself such as a cause, a religion or the environment.{" "}
      </p>
    </Fragment>
  ),

  career: (
    <Fragment>
      <p>
        Career has to do with your main activities on daily basis and it
        involves your long-term commitments. Aligning career with with your
        purpose in life will contribute to your feeling of achievement. Ideally,
        commitments and achievements are personal choices and should balance
        with other things you do enabling a self-determined sense of well-being,
        a feeling of control, of daily achievement and enjoyment which impacts
        positively on others and which you can sustain throughout your life.{" "}
      </p>
      <br />
    </Fragment>
  ),

  financial: (
    <Fragment>
      <p>
        This dimension describes the state of your personal financial status.
        Taking a cross cultural, transnational perspective, financial health is
        maintaining an absence of distress by having an adequate amount of a
        designated monetary unit of exchange to meet your expectations and/or
        those of your extended family for food, housing, healthcare, and
        cultural/community/societal involvement.
      </p>
    </Fragment>
  ),

  socialLife: (
    <Fragment>
      <p>
        Social life describes your embedding into your social context, i.e. your
        active and reactive interactions with other human beings in various
        contexts. This can be the family, the work environment, the housing
        environment, the community or peer groups. The quality and depth of your
        social life, the acts of giving and receiving, a sense of community and
        the communication patterns arising thereof can have a substantial impact
        on your quality of life.{" "}
      </p>
    </Fragment>
  ),

  environment: (
    <Fragment>
      <p>
        Your environment does not only include the natural world around you, but
        also the conditions in which you surround yourself with. Aspects of
        environment include location of residence, air-quality, noise-level,
        temperature, and the humidity of the location. Additionally, your
        environment is comprised of factors that you choose for yourself, such
        as the clothes you wear, the amount of plants in your house, your
        commute time and preferred method of transportation. An ideal
        environment in addition to protecting your physical health can also
        create a safe place for your mental health to grow and evolve.
      </p>
    </Fragment>
  ),

  brainPower: (
    <Fragment>
      <p>
        Throughout life, the function of the healthy brain is to help you make
        sense of the world and oversee your daily living functions. A healthy
        brain refers to abilities such as the ability to learn, remember,
        forgive, solve problems, concentrate, make decisions, manage
        information, think logically, display sound judgment, feel altruistic,
        feel humorous, gain perspective, appreciate the arts, handle numbers,
        possess spiritual beliefs, gain wisdom, express love, kindness,
        creativity and the ability to live a full and well expressed life.
      </p>
    </Fragment>
  ),
};

export const scoreDetailsTooltips = {
  topRight: (label: string) => (
    <p>
      Understand the distribution of your population as a function of their own
      career score. The vertical axis represents the % of people and the
      horizonal axis refers to the {label}
    </p>
  ),
  bottomLeft: (label: string) => (
    <p>
      These ‘KPIs’ are some of the many key characteristics that are tracked for
      each individual and that have a direct impact on the {label}. The
      right-hand side graph will change as you hover over them
    </p>
  ),
  bottomRight: (
    <p>
      Understand the distribution of your population as a function of their own
      score for the KPI that you hover over on the left-hand side. The vertical
      axis represents the % of people and the horizonal axis refers to the score
      for the KPI that you hover over on the left-hand side
    </p>
  ),
};
