import React, { Component } from "react";
import "./header.css";
import { assets } from "../../assets/assets";
import { Button } from "../../components/button/Button";

export class Header extends Component<Props> {
  render = () => (
    <div className="headerContainer">
      <img src={assets.splash.logo} alt="" />

      <Button
        icon={assets.splash.iconLogin}
        text="Login"
        class="buttonShadow"
        onClick={this.props.onClick}
      />
    </div>
  );
}

interface Props {
  onClick: () => void;
}
