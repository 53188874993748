import React, { Component, Fragment } from "react";
import "./splash.css";
import { assets } from "../assets/assets";
import { Header } from "./components/Header";
import { Button } from "../components/button/Button";
import { CSSTransition } from "react-transition-group";
import { DropDown } from "../components/dropdown/DropDown";
import { dropdownData } from "../components/data";
import { IntentionTriangle } from "./components/IntentionTriangle";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { UnregisterCallback } from "history";
import { InputWrapper } from "./InputWrapper";
import {
  passwordIsValid,
  emailIsValid,
  signUp,
  validPhoneNumber,
} from "../common/utils";
import { Auth } from "aws-amplify";
import { api } from "common/api";

export class SignUpScreen extends Component<
  RouteComponentProps<StepParams>,
  State
> {
  emptyFormFields = (): FormEntry => ({
    Name: "",
    ConfirmPassword: "",
    Email: "",
    Password: "",
    Phone: "",
    Title: "",
    LoginEmail: "",
    LoginPassword: "",
  });

  state: State = {
    top: "20%",
    step: 1,
    oldStep: 2,
    reverse: false,

    errors: this.emptyFormFields(),
    data: this.emptyFormFields(),
    redirect: false,
  };

  timeout = 1000;
  welcomeParagraph: HTMLParagraphElement | null = null;

  backCallback: UnregisterCallback | null = null;

  componentDidMount() {
    api.invalidateCorporateID();
    this.route();

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
          this.setState({ ...this.state, redirect: true });
        }
      })
      .catch(() => {});
  }

  componentDidUpdate(prevProps: RouteComponentProps<StepParams>) {
    if (prevProps.match.params.step !== this.props.match.params.step) {
      this.route();
    }
  }

  route = () => {
    if (this.props.match.params.step === "login") {
      this.setState({
        ...this.state,
        step: "Login",
        reverse: false,
        errors: this.emptyFormFields(),
        data: this.emptyFormFields(),
      });
      return;
    }

    const step = parseInt(this.props.match.params.step);
    if (step >= 1 && step <= 5) {
      this.setState({
        ...this.state,
        step: step as Step,
        oldStep: step as Step,
        reverse: this.state.step === "Login" || step < this.state.step,
      });
    }
  };

  animation = () =>
    this.state.reverse ? "slowOpacity-reverse" : "slowOpacity";

  welcome = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step === 1}
    >
      <div className="animationContainer">
        <div className="splashRightContainer top">
          <img
            src={assets.splash.splashRightImage}
            className="splashRightBackground"
            alt=""
          />
        </div>

        <div className="splashTextContainer">
          <p
            className="splashTitle noMargin"
            ref={(ref) => {
              this.welcomeParagraph = ref;
              this.adjustEllipse();
            }}
          >
            Welcome to iamYiam!
          </p>
          <p className="splashText">
            Let's start understanding how we can bring out the best in people
          </p>

          <Button
            text="START DISCOVERING"
            class="splashDiscoveryButton"
            onClick={() => this.props.history.push("/signup/login")}
          ></Button>
        </div>

        <img
          src={assets.splash.ellipses.ellipse1}
          className="ellipse1"
          alt=""
        />
        <img
          src={assets.splash.ellipses.ellipse2}
          className="ellipse2"
          alt=""
        />
        <img
          src={assets.splash.ellipses.ellipse3}
          className="ellipse3"
          alt=""
          style={{ top: this.state.top }}
        />
        <img
          src={assets.splash.ellipses.ellipse4}
          className="ellipse4"
          alt=""
        />
      </div>
    </CSSTransition>
  );

  adjustEllipse = () => {
    if (this.welcomeParagraph != null) {
      const top = this.welcomeParagraph.getBoundingClientRect().top;
      const ellipseTop = (top * 3) / 4;
      const newTop = `${ellipseTop}px`;

      if (this.state.top !== newTop) {
        this.setState({ ...this.state, top: newTop });
      }
    }
  };

  signUp = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step !== 1}
    >
      <div className="animationContainer">
        <div className="splashRightContainer">{this.renderImage()}</div>

        <div className="splashTextContainer">
          <div id="signUpIndicatorContainer">
            {/* {this.stepImage()} */}
            {this.renderLogin()}
          </div>
        </div>

        <img
          src={assets.splash.ellipses.ellipse1}
          className="ellipse1"
          alt=""
        />
        <img
          src={assets.splash.ellipses.ellipse2}
          className="ellipse2"
          style={{ top: "90%" }}
          alt=""
        />
        <img
          src={assets.splash.ellipses.ellipse3}
          className="ellipse3"
          style={{ left: "50%", top: "15%" }}
          alt=""
        />
        <img
          src={assets.splash.ellipses.ellipse4}
          className="ellipse4"
          alt=""
        />

        <img src={assets.splash.bottomeLeft} className="bottomLeft" alt="" />
        <img src={assets.splash.topRight} className="topRight" alt="" />
      </div>
    </CSSTransition>
  );

  stepImage = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step !== "Login" && this.state.step !== 1}
    >
      <div id="signUpStepImageContainer">
        <img src={this.stepSrc()} id="signUpStep" alt="" />
      </div>
    </CSSTransition>
  );

  render = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div className="splashContainer">
        <Header onClick={() => this.props.history.push("/signup/login")} />

        {this.welcome()}
        {this.signUp()}
      </div>
    );
  };

  stepSrc = () => {
    const { oldStep } = this.state;
    if (oldStep === 2) {
      return assets.splash.signUpStep1;
    } else if (oldStep === 3) {
      return assets.splash.signUpStep2;
    } else if (oldStep === 4) {
      return assets.splash.signUpStep3;
    } else if (oldStep === 5) {
      return assets.splash.signUpStep4;
    } else {
      return assets.splash.signUpStep1;
    }
  };

  goToNext = () => {
    const { step } = this.state;
    if (
      step === 2 &&
      (this.checkErrors(["Name", "Title", "Phone"]) || this.checkPhoneError())
    ) {
      return;
    }
    if (
      step === 3 &&
      (this.checkErrors(["Password", "ConfirmPassword", "Email"]) ||
        this.checkPasswordError() ||
        this.checkEmailError() ||
        this.checkPasswordsMatch())
    ) {
      return;
    }
    if (step < 5) {
      this.props.history.push(`/signup/${(this.state.step as number) + 1}`);
    }
    if (step === 5) {
      signUp(this.state.data)
        .then(() => this.setState({ ...this.state, redirect: true }))
        .catch((err) => alert(JSON.stringify(err)));
    }
  };

  checkPhoneError = () => {
    const valid = validPhoneNumber(this.state.data.Phone);

    const { errors } = this.state;
    errors.Phone = valid ? "" : "Phone number is not valid";
    this.setState({ ...this.state, errors });

    return !valid;
  };

  checkEmailError = () => {
    const valid = emailIsValid(this.state.data.Email);

    const { errors } = this.state;
    errors.Email = valid ? "" : "Email is not valid";
    this.setState({ ...this.state, errors });

    return !valid;
  };

  checkPasswordError = () => {
    const error = passwordIsValid(this.state.data.Password);

    const { errors } = this.state;
    errors.Password = error;
    this.setState({ ...this.state, errors });

    return error;
  };

  checkPasswordsMatch = () => {
    const error = this.state.data.Password !== this.state.data.ConfirmPassword;

    const { errors } = this.state;
    errors.ConfirmPassword = error ? "Passwords do not match" : "";
    this.setState({ ...this.state, errors });

    return error;
  };

  renderImage = () => (
    <Fragment>
      <CSSTransition
        classNames={this.animation()}
        timeout={this.timeout}
        unmountOnExit
        in={this.state.step === 2}
      >
        <div className="centerChildren">
          <img
            src={assets.splash.signUpRightSide}
            style={{ marginRight: "5em" }}
            alt=""
          />
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={this.animation()}
        timeout={this.timeout}
        unmountOnExit
        in={this.state.step === 3 || this.state.step === "Login"}
      >
        <div className="centerChildren">
          <img
            src={assets.splash.signUpRightSide2}
            style={{ flexGrow: 1 }}
            alt=""
          />
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={this.animation()}
        timeout={this.timeout}
        unmountOnExit
        in={this.state.step === 4}
      >
        <div className="centerChildren">
          <IntentionTriangle class="" />
        </div>
      </CSSTransition>

      <CSSTransition
        classNames={this.animation()}
        timeout={this.timeout}
        unmountOnExit
        in={this.state.step === 5}
      >
        <div className="centerChildren">
          <img
            src={assets.splash.tutorialRightSide}
            style={{ flexGrow: 1 }}
            alt=""
          />
        </div>
      </CSSTransition>
    </Fragment>
  );

  renderForm = () => (
    <Fragment>
      {this.renderFormFirstStep()}
      {this.renderFormSecondStep()}
      {this.renderFormThirdStep()}
      {this.renderTutorial()}
      {this.renderLogin()}
    </Fragment>
  );

  renderFormFirstStep = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step === 2}
    >
      <div id="formContainer">
        <p className="splashTitle noMargin small">
          Please share a<br /> few details
        </p>
        <InputWrapper
          placeholder="Your Name"
          class="input"
          key={"Name"}
          error={this.state.errors.Name}
          onTextChanged={this.onChanged("Name")}
          text={this.state.data.Name}
        />
        <InputWrapper
          placeholder="Title"
          class="input"
          key={"Title"}
          error={this.state.errors.Title}
          onTextChanged={this.onChanged("Title")}
          text={this.state.data.Title}
        />
        <InputWrapper
          placeholder="Phone number"
          class="input"
          key={"Phone"}
          error={this.state.errors.Phone}
          onTextChanged={this.onChanged("Phone")}
          text={this.state.data.Phone}
        />

        <Button text="NEXT STEP" class="signUpButton" onClick={this.goToNext} />
      </div>
    </CSSTransition>
  );

  onChanged = (key: FormKey) => (text: string) => {
    const { errors, data } = this.state;
    data[key] = text;
    errors[key] = "";

    this.setState({ ...this.state, data, errors });
  };

  renderFormSecondStep = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step === 3}
    >
      <div id="formContainer">
        <p className="splashTitle noMargin small">
          Please create your
          <br /> account profile
        </p>
        <InputWrapper
          placeholder="Your Email"
          class="input"
          key={"Email"}
          error={this.state.errors.Email}
          onTextChanged={this.onChanged("Email")}
          text={this.state.data.Email}
        />
        <InputWrapper
          placeholder="Password"
          class="input"
          key={"Password"}
          error={this.state.errors.Password}
          onTextChanged={this.onChanged("Password")}
          password
          text={this.state.data.Password}
        />
        <InputWrapper
          placeholder="Confirm Password"
          class="input"
          key={"ConfirmPassword"}
          error={this.state.errors.ConfirmPassword}
          onTextChanged={this.onChanged("ConfirmPassword")}
          password
          text={this.state.data.ConfirmPassword}
        />

        <Button text="NEXT STEP" class="signUpButton" onClick={this.goToNext} />
      </div>
    </CSSTransition>
  );

  renderLogin = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step !== 1}
    >
      <div id="formContainer">
        <p className="splashTitle noMargin small">
          Please enter your
          <br /> email and password
        </p>
        <InputWrapper
          placeholder="Your Email"
          class="input"
          key={"Email"}
          error={this.state.errors.LoginEmail}
          onTextChanged={this.onChanged("LoginEmail")}
          text={this.state.data.LoginEmail}
        />
        <InputWrapper
          placeholder="Password"
          class="input"
          key={"Password"}
          error={this.state.errors.LoginPassword}
          onTextChanged={this.onChanged("LoginPassword")}
          password
          text={this.state.data.LoginPassword}
        />

        <Button text="Login" class="signUpButton" onClick={this.login} />
      </div>
    </CSSTransition>
  );

  login = () => {
    if (!this.checkErrors(["LoginEmail", "LoginPassword"])) {
      const { data } = this.state;

      Auth.signIn(data.LoginEmail, data.LoginPassword)
        .then(() => this.setState({ ...this.state, redirect: true }))
        .catch(() =>
          alert("Login failed. Please check your credentials and try again")
        );
    }
  };

  renderFormThirdStep = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step === 4}
    >
      <div id="formContainer">
        <p className="splashTitle noMargin small">
          What is your intention
          <br /> for today
        </p>

        <DropDown
          placeholder={dropdownData.period.placeholder}
          options={dropdownData.period.options}
          class="signUpDropDown"
        />

        <p>Which period you want to analyse?</p>

        <DropDown
          placeholder={dropdownData.intention.placeholder}
          options={dropdownData.intention.options}
          class="signUpDropDown second"
        />

        <Button text="NEXT STEP" class="signUpButton" onClick={this.goToNext} />
      </div>
    </CSSTransition>
  );

  renderTutorial = () => (
    <CSSTransition
      classNames={this.animation()}
      timeout={this.timeout}
      unmountOnExit
      in={this.state.step === 5}
    >
      <div id="formContainer">
        <p className="splashTitle noMargin small">
          Last Step!
          <br /> A few tips on how SYD <br />
          can be of most help to you
        </p>

        <p id="tutorialContent">
          You would be able to walk through the work process of our <br />
          Dashboard with special hints and notes and use it <br />
          each time you need help
        </p>

        <ul>
          <li>Analyze your Employee Health Status</li>
          <li>Control your companies spending</li>
          <li>Find useful recommendations</li>
        </ul>

        <Button
          text="START WORK"
          class="signUpButton"
          onClick={this.goToNext}
        />
      </div>
    </CSSTransition>
  );

  checkErrors = (keys: FormKey[]) => {
    let error = false;
    const { errors } = this.state;

    for (let key of keys) {
      if (this.state.data[key as FormKey].trim().length === 0) {
        errors[key as FormKey] = "This field is required";
        error = true;
      }
    }

    if (error) {
      this.setState({ ...this.state, errors });
    }
    return error;
  };
}

interface State {
  top: string;

  step: Step;
  oldStep: Step;

  reverse: boolean;
  errors: FormEntry;
  data: FormEntry;
  redirect: boolean;
}

type Step = 1 | 2 | 3 | 4 | 5 | "Login";

interface StepParams {
  step: string;
}

export type FormKey =
  | "Name"
  | "Title"
  | "Phone"
  | "Email"
  | "Password"
  | "ConfirmPassword"
  | "LoginEmail"
  | "LoginPassword";

export type FormEntry = { [key in FormKey]: string };
