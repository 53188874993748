import React, { Component } from "react";
import "../styles.css";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { loadLQIs, loadLongetivity, loadSatisfaction } from "./actions";
import { LQI, Longetivity, Satisfaction } from "./types";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { loadEnergy, loadOverview } from "home/actions";
import { LQICharts } from "./LQICharts";
import { GuidedProps } from "common/types";
import { KpiKey } from "components/kpis";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { LoaderWrappper } from "common/LoaderWrapper";
import { Overview } from "home/types";

class LQIScreen extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    blur: false,
    blurIndex: -1,
    highlightedItems: {},
  };

  state: State = { loading: true };

  componentDidMount() {
    this.props.loadLQIs();
    this.props.loadLongetivity();
    this.props.loadSatisfaction();
    this.props.loadOverview();
    this.props.loadEnergy();
  }

  componentDidUpdate() {
    if (
      this.state.loading &&
      this.props.lqi.length > 0 &&
      this.props.satisfaction.withProduct > 0 &&
      this.props.satisfaction.withResults > 0 &&
      this.props.lqi.length > 0
    ) {
      this.setState({ ...this.state, loading: false });
    }
  }

  onChartClicked = (label: string, score: number, kpi: KpiKey) => {
    this.props.history.push(`/score_details/${label}/${score}/${kpi}`);
  };

  render() {
    return (
      <LoaderWrappper
        child={this.renderContent()}
        loading={this.state.loading}
      />
    );
  }

  renderContent = () => {
    return (
      <div>
        <div className="container">
          <SideMenu
            index={3}
            subMenuIndex={0}
            highlight={this.props.blur}
            blur={this.props.blur}
          />

          <div className="rightContainer">
            <Header blur={this.props.blur} />

            <DropDownSelectors blur={this.props.blur} />

            <span className="titleLabel">Life Quality Dimensions</span>

            <LQICharts
              blur={this.props.blur}
              highlightIndex={this.props.blurIndex}
              childRefs={[
                this.props.highlightedItems[0],
                this.props.highlightedItems[1],
              ]}
              onSelectedItem={this.onChartClicked}
            />

            <GiveFeedback blur={this.props.blur} />
          </div>
        </div>
      </div>
    );
  };
}

interface Props extends RouteComponentProps<any>, GuidedProps {
  lqi: LQI[];
  longetivity: Longetivity[];
  satisfaction: Satisfaction;
  overview: Overview;

  loadLQIs: () => void;
  loadLongetivity: () => void;
  loadSatisfaction: () => void;
  loadOverview: () => void;
  loadEnergy: () => void;
}

interface State {
  loading: boolean;
}

function mapStateToProps(state: RootState) {
  return {
    lqi: state.lqi.lqi,
    longetivity: state.lqi.longetivity,
    satisfaction: state.lqi.satisfaction,
    overview: state.healthRisks.overview,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadLQIs: () => dispatch(loadLQIs()),
    loadLongetivity: () => dispatch(loadLongetivity()),
    loadSatisfaction: () => dispatch(loadSatisfaction()),
    loadOverview: () => dispatch(loadOverview()),
    loadEnergy: () => dispatch(loadEnergy()),
  };
}

export const LQIScreenNoRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(LQIScreen);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LQIScreen)
);
