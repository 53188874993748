import React, { useState, HTMLProps, Fragment } from "react";
import { Span } from "common/Span";
import "components/feedback_chart/feedback.css";
import { assets } from "assets/assets";

export const GiveFeedback: React.FC<{ blur?: boolean }> = ({ blur }) => {
  const [selected, setSelected] = useState(-1);
  const [exit, setExit] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [hidden, setHidden] = useState(false);

  const spanProps = (index: number): HTMLProps<HTMLSpanElement> => ({
    className: `emojiMain ${selected < 0 ? "active" : ""} ${
      selected === index ? "emojiSelected" : ""
    }`,
    onClick: onItemSelected(index)
  });

  const span = (text: string, index: number) => (
    <Span role="img" aria-label="" {...spanProps(index)}>
      {text}
    </Span>
  );

  const onItemSelected = (index: number) => () => {
    setSelected(index);
    setTimeout(() => dismiss(true), 1500);
  };

  const dismiss = (hide: boolean) => {
    setExit(true);
    setHidden(hide);

    setTimeout(() => {
      setShowFeedback(false);
      setExit(false);
    }, 500);
  };

  return (
    <Fragment>
      <div
        className={`emojiMainContainer active ${hidden ? "hidden" : ""} ${
          blur ? "blur" : ""
        }`}
        onClick={() => setShowFeedback(true)}
      >
        <span className="emojiMain" role="img" aria-label="">
          😟
        </span>
        <span className="emojiMain" role="img" aria-label="">
          😊
        </span>
        <span className="emojiMain" role="img" aria-label="">
          😄
        </span>
      </div>

      {showFeedback && (
        <div id="giveFeedbackModalContainer">
          <div
            id="giveFeedbackContainer"
            className={exit ? "giveFeedbackExit" : ""}
          >
            <img
              id="leaveFeedbackClose"
              src={assets.iconClose}
              alt=""
              onClick={() => dismiss(false)}
            />

            <Span id="leaveFeedack">
              {selected < 0
                ? "We'd love to hear your feedback on this page"
                : "Thank you for submitting your feedback"}
            </Span>

            <div className="emojiMainContainer">
              {["😟", "😊", "😄"].map(span)}
            </div>

            <Span id="chooseFace">Pick one!</Span>
          </div>
        </div>
      )}
    </Fragment>
  );
};
