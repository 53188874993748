import { RiskChangeElement } from "./types";

export const getLabels = (changes: RiskChangeElement[]): string[] =>
  changes.length === 0 ? [] : changes.map(item => item.range);

export const getValues = (changes: RiskChangeElement[]) => ({
  healthyValues: changes.map(item => item.healthy),
  riskValues: changes.map(item => item.inRisk),
  strugglingValues: changes.map(item => item.inDanger)
});
