import React, { Component } from "react";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import { DetailedCurveChart } from "../components/detailed_curve_chart/DetailedCurveChart";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { loadChanges } from "./actions";
import { RiskChangesState, ChangesType } from "./types";
import { connect } from "react-redux";
import { getLabels, getValues } from "./common";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  RecommendationState,
  Recommendations,
  setSelectedRecommendation,
} from "lqiDetails/Recommendations";
import { RecommendationModal } from "lqiDetails/RecommendationModal";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";

class RisksChangesScreen extends Component<Props, RecommendationState> {
  state: RecommendationState = {
    selectedRecommendation: null,
  };

  componentDidMount() {
    this.props.loadChanges(this.props.changesType);
  }

  render = () => (
    <div className="container">
      <SideMenu index={2} subMenuIndex={this.props.subMenuIndex} />

      <div className="rightContainer">
        <Header />

        <DropDownSelectors
          showBackButton={true}
          onBackButtonPressed={() => this.props.history.goBack()}
        />

        <DetailedCurveChart
          bottomLabels={this.props.labels(this.props.riskChanges)}
          label={this.props.label}
          locked={true}
          {...this.props.values(this.props.riskChanges)}
          {...this.props.colors()}
        />

        <Recommendations
          setSelectedRecommendation={setSelectedRecommendation(this)}
          healthRisk={this.props.changesType}
        />

        <GiveFeedback />
      </div>
      {this.state.selectedRecommendation && (
        <RecommendationModal
          recommendation={this.state.selectedRecommendation}
          onDismissed={() => setSelectedRecommendation(this)(null)}
        />
      )}
    </div>
  );
}

interface Props extends RouteComponentProps<any> {
  riskChanges: RiskChangesState;
  changesType: ChangesType;
  label: string;
  subMenuIndex: number;

  loadChanges: (type: ChangesType) => void;
  labels: (state: RiskChangesState) => string[];
  colors: () => Colors;
  values: (state: RiskChangesState) => Values;
}

interface Colors {
  healthyColor: string;
  riskColor: string;
  strugglingColor: string;
}

interface Values {
  healthyValues: number[];
  riskValues: number[];
  strugglingValues: number[];
}

function mapStateToProps(state: RootState) {
  return {
    riskChanges: state.riskChanges,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadChanges: (type: ChangesType) => dispatch(loadChanges(type)),
  };
}

function cdvColors(): Colors {
  return {
    healthyColor: "#ADEAA4",
    riskColor: "#4FB654",
    strugglingColor: "#757575",
  };
}

function cdvLabels(state: RiskChangesState) {
  return getLabels(state.changes.cvd);
}

function cdvValues(state: RiskChangesState) {
  return getValues(state.changes.cvd);
}

function obesityLabels(state: RiskChangesState) {
  return getLabels(state.changes.obesity);
}

function obesityValues(state: RiskChangesState) {
  return getValues(state.changes.obesity);
}

function obesityColors(): Colors {
  return {
    healthyColor: "#FDDF8E",
    riskColor: "#FEBE3A",
    strugglingColor: "#D99A38",
  };
}

function msdLabels(state: RiskChangesState) {
  return getLabels(state.changes.msd);
}

function msdValues(state: RiskChangesState) {
  return getValues(state.changes.msd);
}

function msdColors(): Colors {
  return {
    healthyColor: "#FDDDBF",
    riskColor: "#F17036",
    strugglingColor: "#AB3622",
  };
}

function stressLabels(state: RiskChangesState) {
  return getLabels(state.changes.stress);
}

function stressValues(state: RiskChangesState) {
  return getValues(state.changes.stress);
}

function stressColors(): Colors {
  return {
    healthyColor: "#ADEAA4",
    riskColor: "#4FB654",
    strugglingColor: "#757575",
  };
}

const RiskChangesScreen = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RisksChangesScreen)
);

const CDVChangesScreen = () => (
  <RiskChangesScreen
    labels={cdvLabels}
    values={cdvValues}
    colors={cdvColors}
    changesType="cvd"
    label="Cardiovascular diseases changes"
    subMenuIndex={2}
  />
);
const ObesityChangesScreen = () => (
  <RiskChangesScreen
    labels={obesityLabels}
    values={obesityValues}
    colors={obesityColors}
    changesType="obesity"
    label="Obesity changes"
    subMenuIndex={4}
  />
);
const DiabetesChangesScreen = () => (
  <RiskChangesScreen
    labels={obesityLabels}
    values={obesityValues}
    colors={obesityColors}
    changesType="t2d"
    label="Diabetes II changes"
    subMenuIndex={6}
  />
);

const MSDChangesScreen = () => (
  <RiskChangesScreen
    labels={msdLabels}
    values={msdValues}
    colors={msdColors}
    changesType="msd"
    label="MSD changes"
    subMenuIndex={8}
  />
);
const StressChangesScreen = () => (
  <RiskChangesScreen
    labels={stressLabels}
    values={stressValues}
    colors={stressColors}
    changesType="stress"
    label="Stress risk changes"
    subMenuIndex={10}
  />
);
const AnxietyChangesScreen = () => (
  <RiskChangesScreen
    labels={stressLabels}
    values={stressValues}
    colors={stressColors}
    changesType="anxiety"
    label="Anxiety risk changes"
    subMenuIndex={12}
  />
);
const DepressionChangesScreen = () => (
  <RiskChangesScreen
    labels={stressLabels}
    values={stressValues}
    colors={stressColors}
    changesType="depression"
    label="Depression risk changes"
    subMenuIndex={14}
  />
);

export {
  CDVChangesScreen,
  ObesityChangesScreen,
  DiabetesChangesScreen,
  MSDChangesScreen,
  StressChangesScreen,
  AnxietyChangesScreen,
  DepressionChangesScreen,
};
