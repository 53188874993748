import React, { Component, CSSProperties } from "react";
import { assets } from "../../assets/assets";
import "./mapChart.css";
import { Continent } from "../../engagement/common";
import { Diversity } from "engagement/types";
import { keys } from "common/utils";
import { Locked } from "components/locked/Locked";

export class MapChart extends Component<Props> {
  static defaultProps = {
    continent: "africa",
  };

  mapPin = (continent: Continent, value: number) =>
    value === 0 ? null : (
      <div
        key={continent}
        className="mapLegendContainer"
        style={this.coordinates(continent)}
      >
        <span>{value}%</span>
        {this.image(this.color(continent))}
      </div>
    );

  render = () => (
    <div className="mapChartContainer">
      <p>DIVERSITY</p>
      <img src={assets.mapChart.iconMapLegend} alt="" />
      <div className="mapContainer">
        <img src={assets.mapChart.iconMap} alt="" />
        {keys<Diversity, Continent>(this.props.diversity).map((continent) =>
          this.mapPin(continent, this.props.diversity[continent])
        )}
      </div>

      {this.props.locked && <Locked />}
    </div>
  );

  coordinates = (continent: Continent): CSSProperties => {
    switch (continent) {
      case "africa":
        return { left: "98px", top: "21px" };
      case "asia":
        return { left: "140px", top: "-12px" };
      case "australia":
        return { left: "167px", top: "34px" };
      case "europe":
        return { left: "104px", top: "-18px" };
      case "northAmerica":
        return { left: "22px", top: "-15px" };
      case "southAmerica":
        return { left: "48px", top: "30px" };
      default:
        return { left: "30px", top: "-25px" };
    }
  };

  image = (fill: string) => (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className="mapPin"
    >
      <path
        d="M30 14C30 22.8366 18 34 18 34C18 34 6 22.8366 6 14C6 7.16344 11.3726 2 18 2C24.6274 2 30 7.16344 30 14Z"
        fill={fill}
      />
      <circle cx="18" cy="15" r="5" fill="white" />
    </svg>
  );

  color = (continent: Continent): string => {
    switch (continent) {
      case "northAmerica":
        return "#4FB654";
      case "southAmerica":
        return "#ADEAA4";
      case "europe":
        return "#45525E";
      case "africa":
        return "#F17036";
      case "asia":
        return "#FEBE3A";
      case "australia":
      default:
        return "#AB3622";
    }
  };
}

interface Props {
  diversity: Diversity;

  locked?: boolean;
}
