import React, { FunctionComponent, Fragment } from "react";
import { FormKey } from "./SignUpScreen";
import { TextInput } from "../components/TextInput";

export const InputWrapper: FunctionComponent<{
  key: FormKey;
  error: string;
  placeholder: string;
  class: string;
  onTextChanged: (text: string, key: FormKey) => void;
  password?: boolean;
  text?: string;
}> = ({
  key,
  error,
  placeholder,
  class: cssClass,
  onTextChanged,
  password = false,
  text = "",
}) => {
  const onInputChanged = (text: string) => onTextChanged(text, key);

  return (
    <Fragment>
      <TextInput
        error={error}
        placeholder={placeholder}
        class={cssClass}
        onTextChanged={onInputChanged}
        password={password}
        text={text}
      />
    </Fragment>
  );
};
