import React, { Component } from "react";
import "./GoalChart.css";

export class GoalChart extends Component<Props, {}> {
  static defaultProps = {
    supplement: false,
    imageUrl: null,
    image: null,
  };

  imageSrc = () =>
    this.props.imageUrl
      ? `https://d3b3zp0qkrdpk.cloudfront.net/uploads/goals_new/mobile_new/${this.props.imageUrl.replace(
          ".svg",
          ".png"
        )}`
      : this.props.image;

  render = () => (
    <div className="barchartContainer">
      <img src={this.imageSrc()} className="barchartIcon" alt="" />

      <div className="barchartLabelContainer">
        <span className="goalName">{this.props.text}</span>
      </div>
    </div>
  );

  textColor = () => (this.props.supplement ? "#FEBE3A" : "#4FB654");

  backgroundColor = () => (this.props.supplement ? "#FEECB3" : "#DAF9D2");

  fillColor = () => (this.props.supplement ? "#FEBE3A" : "#7AD077");
}

interface Props {
  supplement: boolean;
  text: string;
  value: number;
  image: any | null;
  imageUrl: string | null;
}
