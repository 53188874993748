export interface LQIElement {
  id: string;
  name: string;
  score: number;

  locked?: boolean;
}

export interface LQI {
  date: string;
  dimensions: LQIElement[];
  overall_score: number;
}

export interface Longetivity {
  range: string;
  value: number;
}

export interface Satisfaction {
  withProduct: number;
  withResults: number;
}

export interface LQIState {
  lqi: LQI[];
  longetivity: Longetivity[];
  satisfaction: Satisfaction;
  longetivityLocked: boolean;
  lqiLocked: boolean;
}

export interface Energy {
  date: string;
  kpis: {
    id: string;
    name: string;
    score: number;

    locked?: boolean;
  }[];
}

/**
 * actions
 */
export const LQI_LOADED_ACTION = "LQI_LOADED_ACTION";
export const LONGETIVITY_LOADED_ACTION = "LONGETIVITY_LOADED_ACTION";
export const SATISFACTION_LOADED_ACTION = "SATISFACTION_LOADED_ACTION";

export interface LQILoadedAction {
  type: typeof LQI_LOADED_ACTION;
  lqi: LQI[];
  locked: boolean;
}

export interface LongetivityLoadedAction {
  type: typeof LONGETIVITY_LOADED_ACTION;
  longetivity: Longetivity[];
  locked: boolean;
}

export interface SatisfactionLoadedAction {
  type: typeof SATISFACTION_LOADED_ACTION;
  satisfaction: Satisfaction;
}

export type LQIAction =
  | LQILoadedAction
  | LongetivityLoadedAction
  | SatisfactionLoadedAction;
