import React, { useState, useEffect, useRef, useMemo } from "react";
import { Blurrable } from "common/types";
import { SideMenu } from "components/SideMenu";
import { Header } from "components/Header";
import { DropDownSelectors } from "components/dropdown/DropDownSelectors";
import {
  SpeedChart,
  NUMBER_OF_FRAMES,
} from "components/speed_chart/SpeedChart";
import { DetailsChart } from "./DetailsChart";
import { loadLqiDist, loadOutlook, Recommendation } from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import { RecommendationModal } from "./RecommendationModal";
import { RouteComponentProps } from "react-router-dom";
import { loadLQIs } from "lqi/actions";
import { GiveFeedback } from "../components/feedback_chart/GiveFeedback";
import { capitalize } from "common/utils";
import { Recommendations } from "./Recommendations";
import { KpiKey } from "components/kpis";
import { Animator } from "components/speed_chart/Animator";
import { Tooltip } from "components/tooltip/Tooltip";
import { kpisContent, scoreDetailsTooltips } from "common/content";
import { assets } from "assets/assets";
import { Locked } from "components/locked/Locked";
import { RootState } from "common/state";
import { Point } from "components/data";
import { Outlook } from "./types";
import lqiConversionUtils from "./common";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "react-loader-spinner";

export const ScoreDetailsScreen: React.FC<Props> = (props: Props) => {
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<Recommendation | null>(null);
  const [score, setScore] = useState(0);
  const [kpiIndex, setKpiIndex] = useState<KpiKey>("brainPower");
  const [label, setLabel] = useState("");
  const [outlook, setOutlook] = useState<Outlook>({
    top: [],
    bottom: [],
    locked: false,
  });
  const [loadingOutlook, setLoadingOutlook] = useState(true);

  const corporateId = useSelector<RootState, string>(
    (state) => state.common.user.corporateId
  );

  const cssClass = useMemo(() => {
    return corporateId === "blurLarge" ? "" : "";
  }, [corporateId]);

  const scrollPosition = useRef(0);

  const [animationData, setAnimationData] = useState<number[]>(
    Animator.initialize(1)
  );
  const animator = useRef(
    new Animator(setAnimationData, requestAnimationFrame.bind(window))
  );

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(loadLQIs());
    dispatch(loadLqiDist());
  }, [dispatch]);

  useEffect(() => {}, [animationData]);

  useEffect(() => {
    const score = parseInt(props.match.params.score);
    setScore(score);
    setLabel(props.match.params.label);
    setKpiIndex(props.match.params.kpi);

    animator.current.forceReset([score]);
    animator.current.beginAnimation(NUMBER_OF_FRAMES);
  }, [props.match.params]);

  useEffect(() => {
    if (selectedRecommendation) {
      scrollPosition.current = window.scrollY;
    } else {
    }
  }, [selectedRecommendation]);

  const lqiId = useMemo(() => {
    return lqiConversionUtils.KPIKeyToLqiId(kpiIndex);
  }, [kpiIndex]);

  useEffect(() => {
    setLoadingOutlook(true);
    loadOutlook(lqiId)
      .then(setOutlook)
      .finally(() => setLoadingOutlook(false));
  }, [lqiId]);

  const capitalizedLabel = () => label.split(" ").map(capitalize).join(" ");

  const subMenuIndex = (): number => {
    switch (kpiIndex) {
      case "health":
        return 2;
      case "career":
        return 3;
      case "brainPower":
        return 4;
      case "financial":
        return 5;
      case "emotional":
        return 6;
      case "environment":
        return 7;
      case "selfAwareness":
        return 8;
      case "purpose":
        return 9;
      case "socialLife":
        return 10;
      default:
        return 2;
    }
  };

  const changes = () => (
    <div className="chartContainer" style={{ flexDirection: "column" }}>
      {loadingOutlook ? (
        <Loader type="ThreeDots" width={80} height={80} />
      ) : (
        <>
          <div className={`scoresFutureOutlookRow ${cssClass}`}>
            {outlook.top.map((item) => outlookCell(item.value, item.name))}
          </div>
          <div className={`scoresFutureOutlookRow ${cssClass}`}>
            {outlook.bottom.map((item) => outlookCell(item.value, item.name))}
          </div>

          {outlook.locked && <Locked />}
        </>
      )}
    </div>
  );

  const outlookCell = (value: number, name: string) => (
    <div className="outlookCell">
      <div className="outlookContainer">
        <img
          src={value >= 0 ? assets.arrowUp : assets.arrowDown}
          alt=""
          width={20}
        />
        <span>{value}%</span>
      </div>
      <span className="futureOutlook">{name}</span>
    </div>
  );

  const chartValues = useSelector<RootState, Point[]>((state) => {
    return state.recommendations.lqiDist[kpiIndex].map(
      (item, index) => new Point(index * 10, item.value)
    );
  });

  return (
    <div>
      <div className="container">
        <SideMenu
          index={3}
          subMenuIndex={subMenuIndex()}
          highlight={props.blur}
          blur={props.blur}
        />

        <div className="rightContainer">
          <Header blur={props.blur} />
          <DropDownSelectors blur={props.blur} />

          <div
            className="scoreDetailsRowContainer"
            style={{ marginTop: "1em" }}
          >
            <div className="scoreDetailsContainer">
              <SpeedChart
                text={label}
                value={score}
                large={true}
                clickable={false}
                arcNumbers={animationData[0]}
              />
              <Tooltip description={kpisContent[kpiIndex]} />
            </div>

            <div className="scoreDetailsContainer">
              <DetailsChart
                label={`Population distribution by ${capitalizedLabel()}`}
                bottomLabel={`${capitalizedLabel()}`}
                values={chartValues}
              />

              <Tooltip
                description={scoreDetailsTooltips.topRight(capitalizedLabel())}
              />
            </div>
          </div>
          <span className="titleLabel">
            The 3 best and 3 worst performers for your {capitalizedLabel()}
          </span>
          {changes()}

          <Recommendations
            setSelectedRecommendation={setSelectedRecommendation}
            lqiId={lqiId}
          />

          <GiveFeedback />
        </div>
      </div>
      {selectedRecommendation && (
        <RecommendationModal
          recommendation={selectedRecommendation}
          onDismissed={() => setSelectedRecommendation(null)}
        />
      )}
    </div>
  );
};

interface Data {
  label: string;
  score: string;
  kpi: KpiKey;
}

interface Props extends Blurrable, RouteComponentProps<Data> {}
