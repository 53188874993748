import {
  CostsState,
  CostsAction,
  COST_CATEGORIES_LOADED,
  COST_DISTRIBUTION_LOADED,
  HEALTH_COST_RISKS_LOADED,
} from "./types";
import { HEALTH_RISKS_LOADED } from "../home/types";

const initialState: CostsState = {
  healthRisks: {
    cvd: [],
    msd: [],
    metabolic: [],
    sad: [],
    anxiety: [],
    depression: [],
    obesity: [],
    stress: [],
    t2d: [],
  },

  costCategories: {
    abs: 0,
    medical: 0,
    prs: 0,
  },

  costDistribution: {
    cvd: [],
    msd: [],
    obesity: [],
    stress: [],
    t2d: [],
    anxiety: [],
    depression: [],
  },

  healthRiskCosts: {
    risk_costs: {
      cvd: 0,
      msd: 0,
      metabolic: 0,
      sad: 0,
    },

    abs: { days: { total: 0 } },
    prs: { days: { total: 0 } },
  },
  costDistributionLocked: {
    cvd: false,
    msd: false,
    obesity: false,
    stress: false,
    t2d: false,
    anxiety: false,
    depression: false,
  },
};

export const costs = (
  state = initialState,
  action: CostsAction
): CostsState => {
  switch (action.type) {
    case HEALTH_RISKS_LOADED:
      return {
        ...state,
        healthRisks: action.healthRisks,
      };
    case COST_CATEGORIES_LOADED:
      return {
        ...state,
        costCategories: action.costCategories,
      };
    case COST_DISTRIBUTION_LOADED:
      const costDistribution = state.costDistribution;
      costDistribution[action.distributionType] = action.distribution;

      const costDistributionLocked = state.costDistributionLocked;
      costDistributionLocked[action.distributionType] = action.locked;

      return {
        ...state,
        costDistribution,
        costDistributionLocked,
      };
    case HEALTH_COST_RISKS_LOADED:
      return {
        ...state,
        healthRiskCosts: action.payload,
      };
    default:
      return state;
  }
};
