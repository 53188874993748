import { combineReducers } from "redux";
import { healthRisks } from "../home/reducer";
import { demographics } from "../engagement/reducer";
import { costs } from "../costs/reducer";
import { lqi } from "../lqi/reducer";
import { riskChanges } from "../risks_changes/reducer";
import { recommendations } from "lqiDetails/reducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  user: { name: "", image: "", corporateId: "" },
};

export type User = typeof initialState["user"];

const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
  },
});

export const common = commonReducer.reducer;

export const commonActions = commonReducer.actions;

export const rootReducer = combineReducers({
  common,
  healthRisks,
  demographics,
  costs,
  lqi,
  riskChanges,
  recommendations,
});
