import { RiskChangesState, RiskChangesAction, CHANGES_LOADED } from "./types";

const initialState: RiskChangesState = {
  changes: {
    cvd: [],
    obesity: [],
    msd: [],
    stress: [],
    t2d: [],
    anxiety: [],
    depression: [],
  },
  locked: {
    cvd: false,
    obesity: false,
    msd: false,
    stress: false,
    t2d: false,
    anxiety: false,
    depression: false,
  },
};

export const riskChanges = (
  state = initialState,
  action: RiskChangesAction
): RiskChangesState => {
  switch (action.type) {
    case CHANGES_LOADED:
      const changes = state.changes;
      changes[action.changesType] = action.changes;

      const locked = state.locked;
      locked[action.changesType] = action.locked;

      return {
        ...state,
        changes,
        locked,
      };
    default:
      return state;
  }
};
