import { KpiKey } from "components/kpis";

const lqiIdtoKPIKey = (key: string): KpiKey => {
  switch (key) {
    case "LQI_1":
      return "health";
    case "LQI_2":
      return "emotional";
    case "LQI_3":
      return "brainPower";
    case "LQI_4":
      return "selfAwareness";
    case "LQI_5":
      return "purpose";
    case "LQI_6":
      return "career";
    case "LQI_7":
      return "financial";
    case "LQI_8":
      return "socialLife";
    case "LQI_9":
      return "environment";
    default:
      return "health";
  }
};

const KPIKeyToLqiId = (key: KpiKey): string => {
  switch (key) {
    case "health":
      return "LQI_1";
    case "emotional":
      return "LQI_2";
    case "brainPower":
      return "LQI_3";
    case "selfAwareness":
      return "LQI_4";
    case "purpose":
      return "LQI_5";
    case "career":
      return "LQI_6";
    case "financial":
      return "LQI_7";
    case "socialLife":
      return "LQI_8";
    case "environment":
      return "LQI_9";
    default:
      return "LQI_1";
  }
};

const lqiConversionUtils = {
  lqiIdtoKPIKey,
  KPIKeyToLqiId,
};

export default lqiConversionUtils;
