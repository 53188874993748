import React, { Component } from "react";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import "../styles.css";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { loadLQIs, loadLongetivity } from "./actions";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { LQI, Longetivity } from "./types";
import { getDateLabels, bottomLabels, points, getValues } from "./common";
import {
  Recommendations,
  setSelectedRecommendation,
  RecommendationState,
} from "lqiDetails/Recommendations";
import { RecommendationModal } from "lqiDetails/RecommendationModal";
import { ArrowChart } from "components/arrow_chart/ArrowChart";
import { lifeQualityIndexChart } from "components/data";
import { EmployeeLongetivityChart } from "components/employee_longetivity/EmployeeLongetivityChart";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { Locked } from "components/locked/Locked";

class LQIDetailsScreen extends Component<Props, RecommendationState> {
  state: RecommendationState = {
    selectedRecommendation: null,
  };

  componentDidMount() {
    this.props.loadLQIs();
    this.props.loadLongetivity();
  }

  render = () => {
    const dateLabels = getDateLabels(this.props.lqi);
    const values = getValues(this.props.lqi);

    return (
      <div className="container">
        <SideMenu index={3} subMenuIndex={1} />

        <div className="rightContainer">
          <Header />
          <DropDownSelectors />

          <div
            className="rowColumnChartContainer"
            style={{ marginBottom: "1em" }}
          >
            <div className={`squareChartColumnContainer`}>
              <span className="titleLabel" style={{ marginLeft: "0em" }}>
                Life Quality index
              </span>
              <div
                className={`squareChartContainer `}
                style={{ height: "525px" }}
              >
                <ArrowChart
                  {...lifeQualityIndexChart}
                  oldLqiScore={values[0]}
                  lqiScore={values[1]}
                  previousDateLabel={dateLabels[0]}
                  currentDateLabel={dateLabels[1]}
                />
                {this.props.lqiLocked && <Locked />}
              </div>
            </div>

            <div className={`squareChartColumnContainer`}>
              <span className="titleLabel" style={{ marginLeft: "0em" }}>
                LQi score over employee longevity within the company
              </span>

              <div
                className={`squareChartContainer`}
                style={{ height: "525px" }}
              >
                <EmployeeLongetivityChart
                  bottomLabels={bottomLabels(this.props.longetivity)}
                  points={points(this.props.longetivity)}
                />

                {this.props.longetivityLocked && <Locked />}
              </div>
            </div>
          </div>

          <Recommendations
            lqiId="LQI_2"
            setSelectedRecommendation={setSelectedRecommendation(this)}
          />

          <GiveFeedback />
        </div>
        {this.state.selectedRecommendation && (
          <RecommendationModal
            recommendation={this.state.selectedRecommendation}
            onDismissed={() => setSelectedRecommendation(this)(null)}
          />
        )}
      </div>
    );
  };
}

interface Props {
  lqi: LQI[];
  lqiLocked: boolean;

  longetivity: Longetivity[];
  longetivityLocked: boolean;

  loadLQIs: () => void;
  loadLongetivity: () => void;
}

function mapStateToProps(state: RootState) {
  return {
    lqi: state.lqi.lqi,
    lqiLocked: state.lqi.lqiLocked,
    longetivity: state.lqi.longetivity,
    longetivityLocked: state.lqi.longetivityLocked,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadLQIs: () => dispatch(loadLQIs()),
    loadLongetivity: () => dispatch(loadLongetivity()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LQIDetailsScreen);
