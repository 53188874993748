import React, { Component, ReactNode, Fragment } from "react";
import "./menu.css";
import { assets } from "../assets/assets";
import SideMenuTextItems from "./SideMenuTextItems";
import { Link } from "react-router-dom";
import { Blurrable } from "common/types";

export class SideMenu extends Component<Props, State> {
  static defaultProps = {
    index: 0,
  };

  menuItems = [
    assets.home,
    assets.lightBulbIcon,
    assets.moneyIcon,
    assets.heartIcon,
  ];

  state: State = { top: 0, hoveredIndex: -1 };
  links: string[] = ["/", "/engagement", "/costs", "/lqi"];
  selectedMenuItem: HTMLImageElement | null = null;

  componentDidMount() {
    this.measureItems();
    window.addEventListener("resize", this.measureItems);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measureItems);
  }

  render = () => {
    return (
      <div className={`menuContainer`}>
        <div className="sideMenu">
          <div className={`logo ${this.props.blur ? "blur" : ""}`}>
            <img src={assets.logo} width="32" height="32" alt="" />
          </div>

          <div className={`background ${this.props.blur ? "blur" : ""}`} />
          {this.renderMenu()}
        </div>

        <SideMenuTextItems
          index={this.props.index}
          selectedIndex={this.props.subMenuIndex}
          blur={this.props.blur}
          top={0}
          marginTop={this.props.marginTop}
        />
      </div>
    );
  };

  renderMenu = () => {
    const views: ReactNode[] = [];

    this.menuItems.forEach((item, index) =>
      views.push(
        index === this.props.index
          ? this.selectedItem(item, index)
          : this.unselectedItem(item, index)
      )
    );

    return <Fragment>{views}</Fragment>;
  };

  selectedItem = (item: any, index: number) => {
    const className = "white";

    return (
      <Link to={this.links[index]} style={{ alignSelf: "stretch" }} key={index}>
        <div
          className={`selectedContainer ${
            this.props.highlight ? "highlightNoPadding" : ""
          } ${this.props.blur && !this.props.highlight ? "blur" : ""}`}
        >
          <img
            src={assets.selectedMenuBackground}
            alt=""
            className="selectedIconBackground"
            width="73"
          />
          <div className="item selected">
            <img
              src={item}
              className={className}
              ref={(ref) => this.measure(ref)}
              width="22px"
              height="22px"
              alt=""
            />
          </div>
        </div>
      </Link>
    );
  };

  measure = (ref: HTMLImageElement | null) => {
    this.selectedMenuItem = ref;
    this.measureItems();
  };

  measureItems = () => {
    if (this.selectedMenuItem != null) {
      const bounds = this.selectedMenuItem.getBoundingClientRect();
      const newTop = bounds.top + bounds.height / 2;
      if (this.state.top !== newTop) {
        this.setState({ ...this.state, top: newTop });
      }
    }
  };

  unselectedItem = (item: any, index: number) => {
    const hoveredStyle =
      index === this.state.hoveredIndex ? { transform: "scale(1.5)" } : {};

    return (
      <Link
        to={this.links[index]}
        style={{ textDecoration: "none" }}
        key={index}
      >
        <div
          className={`unselectedContainer ${this.props.blur ? "blur" : ""}`}
          style={hoveredStyle}
          onMouseEnter={this.onMouseEnter(index)}
          onMouseLeave={this.onMouseExit(index)}
        >
          <div className="item unselected">
            <img src={item} width="22" height="22" alt="" />
          </div>
        </div>
      </Link>
    );
  };

  onMouseEnter = (index: number) => () =>
    this.setState({ ...this.state, hoveredIndex: index });

  onMouseExit = (index: number) => () =>
    this.setState({ ...this.state, hoveredIndex: -1 });
}

interface State {
  top: number;
  hoveredIndex: number;
}

interface Props extends Blurrable {
  index: number;
  subMenuIndex: number;
  marginTop?: number;
}
