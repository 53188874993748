import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {
  HashRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import HomeScreen from "./home/HomeScreen";
import EngagementScreen from "./engagement/EngagementScreen";
import CostsScreen from "./costs/CostsScreen";
import LQIScreen from "./lqi/LQIScreen";
import LQIDetailsScreen from "./lqi/LQIDetailsScreen";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./common/reducer";
import {
  CdvDetailsScreen,
  ObesityDetailsScreen,
  MSDDetailsScreen,
  StressDetailsScreen,
  DiabetesDetailsScreen,
  AnxietyDetailsScreen,
  DepressionsDetailsScreen,
} from "./costs/CostsDetailsScreen";
import {
  CDVChangesScreen,
  ObesityChangesScreen,
  MSDChangesScreen,
  StressChangesScreen,
  AnxietyChangesScreen,
  DepressionChangesScreen,
  DiabetesChangesScreen,
} from "./risks_changes/RiskChangesScreen";
import { SignUpScreen } from "./splash/SignUpScreen";
import { initAuth } from "./common/utils";
import { composeWithDevTools } from "redux-devtools-extension";
import { GuidedTourIndicator } from "components/guided_tour/GuidedTourIndicator";
import { ScoreDetailsScreen } from "lqiDetails/ScoreDetailsScreen";
import { RetentionScreen } from "home/RetentionScreen";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

initAuth();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/signup/:step" component={withRouter(SignUpScreen)} />

        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/retention" component={RetentionScreen} />
        <Route path="/engagement" component={EngagementScreen} />
        <Route path="/costs" component={CostsScreen} />
        <Route path="/lqi" component={LQIScreen} />
        <Route path="/tour" component={withRouter(GuidedTourIndicator)} />

        <Route path="/lqi_details" component={LQIDetailsScreen} />

        <Route path="/cvd" component={CdvDetailsScreen} />
        <Route path="/obesity" component={ObesityDetailsScreen} />
        <Route path="/diabetes" component={DiabetesDetailsScreen} />
        <Route path="/msd" component={MSDDetailsScreen} />
        <Route path="/stress" component={StressDetailsScreen} />
        <Route path="/anxiety" component={AnxietyDetailsScreen} />
        <Route path="/depression" component={DepressionsDetailsScreen} />

        <Route path="/cvd_changes" component={CDVChangesScreen} />
        <Route path="/obesity_changes" component={ObesityChangesScreen} />
        <Route path="/diabetes_changes" component={DiabetesChangesScreen} />
        <Route path="/msd_changes" component={MSDChangesScreen} />
        <Route path="/stress_changes" component={StressChangesScreen} />
        <Route path="/anxiety_changes" component={AnxietyChangesScreen} />
        <Route path="/depression_changes" component={DepressionChangesScreen} />

        <Route
          path="/score_details/:label/:score/:kpi"
          component={ScoreDetailsScreen}
        />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
