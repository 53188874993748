import React, { Fragment, useState, useEffect } from "react";
import { SideMenu } from "components/SideMenu";
import { Header } from "components/Header";
import { DropDownSelectors } from "components/dropdown/DropDownSelectors";
import { GuidedProps } from "common/types";
import { Indicator } from "components/Indicator";
import { indicatorsData } from "components/data";
import { Tooltip } from "components/tooltip/Tooltip";
import { content } from "common/content";
import { RetentionElement, Retention } from "./types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "common/state";
import { Recommendations } from "lqiDetails/Recommendations";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { Recommendation } from "lqiDetails/reducer";
import { RecommendationModal } from "lqiDetails/RecommendationModal";
import "../styles.css";
import { loadRetention } from "./actions";
import { Locked } from "components/locked/Locked";

export const RetentionScreen: React.FC<Props> = (props) => {
  const retentionValue = (elements: RetentionElement[]) =>
    elements.length === 0 ? 0 : elements[0].value;

  const retention = useSelector<RootState, Retention>(
    (state) => state.healthRisks.retention
  );

  const [selectedRecommendation, setSelectedRecommendation] =
    useState<Recommendation | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadRetention());
  }, [dispatch]);

  const employeesStayWhereTheyAre = () => (
    <Fragment>
      <div
        className={`labelRow ${
          props.blur && props.blurIndex !== 2 ? "blur" : ""
        }`}
      >
        <span className="titleLabel">Employees stay where they are</span>
      </div>
      <div
        className={`chartContainer home ${
          props.blur && props.blurIndex !== 2 ? "blur" : ""
        } ${props.blurIndex === 2 ? "highlight" : ""}`}
        style={{
          flexWrap: "nowrap",
          alignItems: "center",
          marginBottom: "2em",
        }}
      >
        <Indicator
          {...indicatorsData.paidWell}
          large={true}
          value={retentionValue(retention.paidWell)}
        />
        <div className="indicatorsRow">
          <Indicator
            {...indicatorsData.mentored}
            value={retentionValue(retention.mentored)}
          />
          <Indicator
            {...indicatorsData.challenged}
            value={retentionValue(retention.challenged)}
          />
          <Indicator
            {...indicatorsData.promoted}
            value={retentionValue(retention.promoted)}
          />
          <Indicator
            {...indicatorsData.involved}
            value={retentionValue(retention.involved)}
          />

          <Indicator
            {...indicatorsData.appreciation}
            value={retentionValue(retention.appreciated)}
          />
          <Indicator
            {...indicatorsData.onAMission}
            value={retentionValue(retention.onMission)}
          />
          <Indicator
            {...indicatorsData.valued}
            value={retentionValue(retention.valued)}
          />
          <Indicator
            {...indicatorsData.empowered}
            value={retentionValue(retention.empowered)}
          />
          <Indicator
            {...indicatorsData.trusted}
            value={retentionValue(retention.trusted)}
          />
          <Indicator
            {...indicatorsData.givenFlexibility}
            value={retentionValue(retention.flexible)}
          />

          <Tooltip description={content[0][2]} />

          <Locked />
        </div>
      </div>
    </Fragment>
  );

  return (
    <div>
      <div className="container">
        <SideMenu
          blur={props.blur}
          highlight={props.blur && props.blurIndex >= 0}
          index={0}
          subMenuIndex={1}
        />

        <div className="rightContainer">
          <Header blur={props.blur} />

          <DropDownSelectors blur={props.blur} />
          {employeesStayWhereTheyAre()}

          <Recommendations
            setSelectedRecommendation={setSelectedRecommendation}
            healthRisk="LQI_6"
          />

          <GiveFeedback />
        </div>
      </div>
      {selectedRecommendation && (
        <RecommendationModal
          recommendation={selectedRecommendation}
          onDismissed={() => setSelectedRecommendation(null)}
        />
      )}
    </div>
  );
};

interface Props extends GuidedProps {}
