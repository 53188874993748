export interface RiskChangeElement {
  healthy: number;
  inDanger: number;
  inRisk: number;
  range: string;
}

export interface RiskChangesState {
  changes: {
    [name in
      | "cvd"
      | "obesity"
      | "msd"
      | "stress"
      | "t2d"
      | "anxiety"
      | "depression"]: RiskChangeElement[];
  };
  locked: {
    [name in
      | "cvd"
      | "obesity"
      | "msd"
      | "stress"
      | "t2d"
      | "anxiety"
      | "depression"]: boolean;
  };
}

export type ChangesType =
  | "cvd"
  | "obesity"
  | "msd"
  | "stress"
  | "t2d"
  | "anxiety"
  | "depression";

/**
 * actions
 */
export const CHANGES_LOADED = "CHANGES_LOADED";

export interface ChangesLoadedAction {
  type: typeof CHANGES_LOADED;
  changes: RiskChangeElement[];
  changesType: ChangesType;
  locked: boolean;
}

export type RiskChangesAction = ChangesLoadedAction;
