import React, { ReactElement, ReactNode, Fragment } from "react";
import { useRef } from "react";
import "./detailChart.css";
import { Point } from "components/data";
import { useMeasure } from "common/hooks";
import { proportion } from "common/utils";
import { SPEED_CHART_COLORS } from "components/speed_chart/SpeedChart";

export const DetailsChart: React.FC<Props> = (props: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const dimensions = useMeasure(svgRef);

  const LEFT_LABELS_SIZE = 60;
  const BOTTOM_LABELS_SIZE = 40;
  const FONT_SIZE = 14;
  const LABELS_SIZE = dimensions.height / FONT_SIZE;

  const RECT_WIDTH = 20;

  const leftLabels = (): ReactNode => {
    const LABELS_TOP = LABELS_SIZE;
    const LABELS_BOTTOM = dimensions.height - BOTTOM_LABELS_SIZE;
    const NUMBER_OF_LABELS = 6;
    const UNIT = (LABELS_BOTTOM - LABELS_TOP) / (NUMBER_OF_LABELS - 1);

    let index = 100;
    const labels: ReactElement[] = [];
    for (let i = LABELS_TOP; i <= LABELS_BOTTOM; i += UNIT) {
      labels.push(
        <text
          key={`detailsChartLeftLabel${i}`}
          x="20"
          y={i}
          textAnchor="middle"
          alignmentBaseline="middle"
          className="detailedChartAxisLabel"
        >
          {index}%
        </text>
      );
      index -= 20;
    }
    return labels;
  };

  const bottomLabels = (): ReactNode => {
    const LABELS_LEFT = LEFT_LABELS_SIZE;
    const LABELS_RIGHT = dimensions.width - LABELS_SIZE;
    const NUMBER_OF_LABELS = 11;
    const UNIT = Math.floor(
      (LABELS_RIGHT - LABELS_LEFT) / (NUMBER_OF_LABELS - 1)
    );

    let index = 0;
    const labels: ReactElement[] = [];

    for (let i = LABELS_LEFT; i <= LABELS_RIGHT; i += UNIT) {
      labels.push(
        <text
          key={`detailsChartBottomLabel${i}`}
          x={i}
          y={dimensions.height}
          alignmentBaseline="text-after-edge"
          dominantBaseline="text-after-edge"
          className="detailedChartAxisLabel"
        >
          {index}%
        </text>
      );
      index += 10;
    }
    return labels;
  };

  const gridLines = (): ReactNode => {
    const LEFT = LEFT_LABELS_SIZE;
    const RIGHT = dimensions.width - LABELS_SIZE;
    const TOP = LABELS_SIZE;
    const BOTTOM = dimensions.height - BOTTOM_LABELS_SIZE;
    const NUMBER_OF_GRIDS = 6;
    const UNIT = (BOTTOM - TOP) / (NUMBER_OF_GRIDS - 1);

    const paths: ReactElement[] = [];
    for (let i = TOP; i <= BOTTOM; i += UNIT) {
      paths.push(
        <path
          key={`detailsChartGridLine${i}`}
          d={`M ${LEFT} ${i} L ${RIGHT} ${i}`}
          strokeDasharray="3"
          strokeWidth="1"
          stroke="#C7C7C7"
        />
      );
    }

    return paths;
  };

  const rectangles = () => {
    const rects: ReactElement[] = [];
    const values = props.values;

    const LEFT = LEFT_LABELS_SIZE;
    const RIGHT = dimensions.width - LABELS_SIZE;
    const TOP = LABELS_SIZE;
    const BOTTOM = dimensions.height - BOTTOM_LABELS_SIZE;
    const colorsPerValue = SPEED_CHART_COLORS.length / values.length;

    for (let i = 0; i < values.length; i++) {
      const rectCenterX = proportion(values[i].x, 0, 100, LEFT, RIGHT);
      const height = (values[i].y / 100) * (BOTTOM - TOP);

      const x = rectCenterX;
      rects.push(
        <rect
          key={`detailsChartRect${i}`}
          x={x}
          y={BOTTOM - height}
          width={RECT_WIDTH}
          height={height}
          fill={SPEED_CHART_COLORS[Math.floor(i * colorsPerValue)]}
        />
      );
    }
    return rects;
  };

  return (
    <Fragment>
      <span className="detailedChartLabel">{props.label}</span>
      <div className="detailedSvgLeftLabelContainer">
        <span className="detailedChartLeftLabel">% of People</span>
        <svg id="detailedScoreSVGContainer" ref={svgRef}>
          {leftLabels()}
          {bottomLabels()}
          {gridLines()}
          {rectangles()}
        </svg>
      </div>
      <span className="detailedChartBottomLabel">{props.bottomLabel}</span>
    </Fragment>
  );
};

interface Props {
  values: Point[];
  label: string;
  bottomLabel: string;
}
