/**
 * state
 */
export interface Age {
  range: string;
  value: number;
}

export type Ethnicity =
  | "africa"
  | "asia"
  | "australia"
  | "europe"
  | "northAmerica"
  | "southAmerica";

export type Diversity = {
  [key in Ethnicity]: number;
};

export interface Gender {
  female: number;
  male: number;
  nonbinary: number;

  locked?: boolean;
}

export interface Demographics {
  age: Age[];
  diversity: Diversity;
  gender: Gender;

  locked: {
    age: boolean;
    diversity: boolean;
    gender: boolean;
  };
}

export interface Conversion {
  action: number;
  dashboard: number;
  genetics: number;
  lqi: number;
}

export interface Rank {
  imgUrl: string;
  name: string;
  rank: number;
  value: number;
  category?: RecommendationCategory;
}

export interface DemographicsState {
  demographics: Demographics;
  conversion: Conversion;
  goalsRank: Rank[];
  recommendationsRank: Rank[];
}

/**
 * actions
 */

export const DEMOGRAPHICS_LOADED = "DEMOGRAPHICS_LOADED";
export const CONVERSION_LOADED = "CONVERSION_LOADED";
export const GOAL_RANK_LOADED = "GOAL_RANK_LOADED";
export const RECOMMENDATIONS_RANK_LOADED = "RECOMMENDATIONS_RANK_LOADED";

interface DemographicsLoadedAction {
  type: typeof DEMOGRAPHICS_LOADED;
  demographics: Demographics;
}

interface ConversionLoadedAction {
  type: typeof CONVERSION_LOADED;
  conversion: Conversion;
}

interface GoalRankLoadedAction {
  type: typeof GOAL_RANK_LOADED;
  goalRank: Rank[];
}

interface RecommendationsLoadedAction {
  type: typeof RECOMMENDATIONS_RANK_LOADED;
  recommendationsRank: Rank[];
}

export type DemographicsAction =
  | DemographicsLoadedAction
  | ConversionLoadedAction
  | GoalRankLoadedAction
  | RecommendationsLoadedAction;

export type RecommendationCategory =
  | "supplements"
  | "habits"
  | "services"
  | "reading"
  | "foods"
  | null;
