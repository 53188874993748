import React, { Component, CSSProperties } from "react";
import "./button.css";

export class Button extends Component<Props> {
  static defaultProps = {
    type: "green",
    onClick: () => {},
    class: "",
    onMouseEnter: () => {},
    onMouseExit: () => {}
  };

  render = () => (
    <div
      onClick={this.props.onClick}
      className={`${this.props.class} buttonContainer ${
        this.props.type === "white" ? "greenBorder" : null
      }`}
      style={this.props.style}
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseExit}
    >
      {this.props.iconLeft ? (
        <img
          src={this.props.iconLeft}
          style={{ marginLeft: "0em", marginRight: "5px" }}
          alt=""
        />
      ) : null}
      <p
        style={this.props.textStyle}
        className={`oneLine ${this.props.textClass}`}
      >
        {this.props.text}
      </p>
      {this.props.icon ? <img src={this.props.icon} alt="" /> : null}
    </div>
  );
}

interface Props {
  text: string;
  icon?: any;
  iconLeft?: any;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  type: "white" | "green";
  class: string;

  onClick: () => void;
  onMouseEnter: () => void;
  onMouseExit: () => void;
  textClass?: string;
}
