import { assets } from "../assets/assets";

const ages = () => {
  const result: string[] = [];

  for (let i = 0; i < 30; i++) {
    result.push(i.toString());
  }
  return result;
};

export const labels = {
  obesity: "OBESITY\nDIABETES II",
  stress: "STRESS\nANXIETY\nDEPRESSION",
  cvd: "CARDIOVASCULAR\nDISEASE RISK (CVD)",
  msd: "MUSCULOSKELETAL DISEASE RISK (MSD)",

  obesityAnd: "OBESITY & DIABETES II RISK",
  stressAnd: "STRESS & ANXIETY & DEPRESSION (SAD)",
};

export const arrowChartIndexColors = {
  currentDate: "#7AD077",
  previousDate: "#ADEAA4",
};

export const dropdownData = {
  period: {
    placeholder: "Choose Period",
    options: [
      "February 2019",
      "March 2019",
      "April 2019",
      "May 2019",
      "June 2019",
      "July 2019",
      "August 2019",
    ],
  },

  intention: {
    placeholder: "Choose Intention",
    options: [
      "Increase productivity",
      "Decrease turnout",
      "Maximum ROI",
      "Increase Employees hapiness",
    ],
  },

  location: {
    placeholder: "Choose Location",
    options: ["London", "New York", "Los Angeles", "Miami"],
  },

  gender: {
    placeholder: "Choose Gender",
    options: ["Male", "Female", "Non Binary"],
  },

  ages: {
    placeholder: "Set Age",
    options: ages(),
  },

  comparePeriods: {
    placeholder: "Compare Periods",
    options: [
      "February 2019",
      "March 2019",
      "April 2019",
      "May 2019",
      "June 2019",
      "July 2019",
      "August 2019",
    ],
  },
};

export const chartColors = {
  green: {
    companyColor: "#4FB654",
    ukColor: "#ADEAA4",
    benchmarkColor: "#3E9B4B",
    trailColor: "#F2FDED",
    color: "#4FB654",
  },

  yellow: {
    companyColor: "#FEBE3A",
    ukColor: "#FDDF8E",
    benchmarkColor: "#D99A38",
    trailColor: "#FEECB3",
    color: "#FEBE3A",
  },

  red: {
    companyColor: "#F17036",
    ukColor: "#FABF94",
    benchmarkColor: "#CD512F",
    trailColor: "#FFF6EB",
    color: "#F17036",
  },

  msd: {
    companyColor: "#45525E",
    ukColor: "#AEC2CE",
    benchmarkColor: "#233142",
    trailColor: "#EAF3F6",
    color: "#45525E",
  },
};

export const simpleChartData = {
  energyLack: {
    text: "ENERGY",
    value: 62,
    color: chartColors.green.companyColor,
    trailColor: chartColors.green.trailColor,
  },

  lifeQualityIndex: {
    text: "LIFE QUALITY\nINDEX",
    value: 82,
    color: chartColors.red.companyColor,
    trailColor: chartColors.red.trailColor,
  },

  retentionScore: {
    text: "RETENTION\nSCORE",
    value: 50.8,
    color: chartColors.msd.companyColor,
    trailColor: chartColors.msd.trailColor,
  },

  returnOnInvestment: {
    text: "RETURN ON\nINVESTMENT",
    value: 12,
  },
};

export const indicatorsData = {
  paidWell: {
    image: assets.indicators.paidWell,
    text: "Paid Well",
    value: 76,
  },

  mentored: {
    image: assets.indicators.mentored,
    text: "Mentored",
    value: 81,
  },

  challenged: {
    image: assets.indicators.challenged,
    text: "Challenged",
    value: 23,
  },

  promoted: {
    image: assets.indicators.promoted,
    text: "Promoted",
    value: 81,
  },

  involved: {
    image: assets.indicators.involved,
    text: "Involved",
    value: 24,
  },

  appreciation: {
    image: assets.indicators.appreciation,
    text: "Appreciated",
    value: 66,
  },

  onAMission: {
    image: assets.indicators.onAMission,
    text: "On a Mission",
    value: 85,
  },

  valued: {
    image: assets.indicators.valued,
    text: "Valued",
    value: 61,
  },

  empowered: {
    image: assets.indicators.empowered,
    text: "Empowered",
    value: 42,
  },

  trusted: {
    image: assets.indicators.trusted,
    text: "Trusted",
    value: 85,
  },

  givenFlexibility: {
    image: assets.indicators.givenFlexibility,
    text: "Given Flexibility",
    value: 78,
  },
};

export const conversionScores = {
  unlockedSyd: {
    text: "UNLOCKED\nSYD",
    value: 98,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,
  },

  unlockedLQIndex: {
    text: "UNLOCKED\nLQ INDEX",
    value: 96,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,
  },

  unlockedGenetics: {
    text: "UNLOCKED\nGENETICS",
    value: 87,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,
  },

  unlockedDashboard: {
    text: "UNLOCKED DASHBOARD",
    value: 72,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,
  },
};

export const barchartsData = {
  diet: {
    image: assets.barCharts.diet,
    text: "Diet",
    value: 18,
  },

  cognitiveHealth: {
    image: assets.barCharts.cognitiveHealth,
    text: "Cognitive Health",
    value: 16,
  },

  heartHealth: {
    image: assets.barCharts.heartHealth,
    text: "Heart Health",
    value: 90,
  },

  mindHealth: {
    image: assets.barCharts.mindHealth,
    text: "Mind Health",
    value: 33,
  },

  moodOptimisation: {
    image: assets.barCharts.moodOptimisation,
    text: "Mood Optimisation",
    value: 90,
  },

  vitaminD: {
    supplement: true,
    image: assets.barCharts.vitaminD,
    text: "Vitamin D",
    value: 65,
  },

  bComplex: {
    supplement: true,
    image: assets.barCharts.bComplex,
    text: "B Complex",
    value: 42,
  },

  vitaminC: {
    supplement: true,
    image: assets.barCharts.vitaminC,
    text: "Vitamin C",
    value: 58,
  },

  fishOil: {
    supplement: true,
    image: assets.barCharts.fishOil,
    text: "Fish Oil",
    value: 11,
  },

  antioxidants: {
    supplement: true,
    image: assets.barCharts.antioxidants,
    text: "Antioxidants",
    value: 82,
  },
};

export const genderChartValues = {
  femaleValue: 45,
  maleValue: 55,
  nonBinaryValue: 5,
};

export const triangleChartValues = [33, 45, 22];

export const mapChartValue = 30;

export const fillChartValues = {
  heart: {
    colors: ["#CAF6CB", "#42AB43", "#1E5727"],
    width: 138,
    height: 128,
    values: [40, 45, 15],
    label: labels.cvd,
    icon: "cdv" as FillChartType,
    dashX: 65,
    dashWidth: 142,
    textX: 111.696,
  },

  obesity: {
    colors: ["#F9E9A5", "#EFB22D", "#A56620"],
    width: 138,
    height: 128,
    values: [5, 50, 45],
    label: labels.obesityAnd,
    icon: "obesity" as FillChartType,
    dashX: 33,
    dashWidth: 173.5,
    textX: 141.437477,
  },

  msd: {
    colors: ["#F7D5B2", "#E85929", "#99241A"],
    width: 138,
    height: 128,
    values: [5, 35, 60],
    label: labels.msd,
    icon: "msd" as FillChartType,
    dashX: 71,
    dashWidth: 133.528409,
    textX: 103.437477,
  },

  stressDepression: {
    colors: ["#CAF6CB", "#42AB43", "#1E5727"],
    width: 138,
    height: 128,
    values: [70, 15, 15],
    label: labels.stressAnd,
    icon: "stress" as FillChartType,
    dashX: 65,
    dashWidth: 141.001437,
    textX: 111.331057,
  },
};

export const verticalBarChartsValues = [25, 40, 35];

export const scoresChartData = {
  careerScore: {
    text: "CAREER\nSCORE",
    value: 68,
    color: "#7AD077",
    trailColor: "#DAF9D2",
    hideViewMore: true,

    label: "Good",
  },

  brainPowerScore: {
    text: "BRAIN POWER\nSCORE",
    value: 58,
    color: "#FDD273",
    trailColor: "#FEECB3",
    hideViewMore: true,

    label: "Not bad",
  },

  financialScore: {
    text: "FINANCIAL SCORE",
    value: 38,
    color: "#F49D6C",
    trailColor: "#FDDDBF",
    hideViewMore: true,

    label: "Bad",
  },

  emotionalScore: {
    text: "EMOTIONAL\nSCORE",
    value: 25,
    color: "#F17036",
    trailColor: "#FDDDBF",
    hideViewMore: true,

    label: "Poor",
  },

  environmentScore: {
    text: "ENVIRONMENT\nSCORE",
    value: 20,
    color: "#F17036",
    trailColor: "#FDDDBF",
    hideViewMore: true,

    label: "Poor",
  },

  selfEsteemScore: {
    text: "SELF-AWARENESS\nSCORE",
    value: 47,
    color: "#F49D6C",
    trailColor: "#FDDDBF",
    hideViewMore: true,

    label: "Bad",
  },

  purposeScore: {
    text: "PURPOSE SCORE",
    value: 72,
    color: "#FDD273",
    trailColor: "#FEECB3",
    hideViewMore: true,

    label: "Not bad",
  },

  socialLifeScore: {
    text: "SOCIAL LIFE\nSCORE",
    value: 100,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,

    label: "Perfect",
  },

  burnoutScore: {
    text: "BURNOUT\nSCORE",
    value: 24,
    color: "#FDD273",
    trailColor: "#FEECB3",
    hideViewMore: true,
  },

  satisfiedIamyiam: {
    text: "SATISFIED WITH\nIAMYIAM",
    value: 93,
    color: "#7AD077",
    trailColor: "#DAF9D2",
    hideViewMore: true,

    label: "Bad",
  },

  healthScore: {
    text: "HEALTH\nSCORE",
    value: 12,
    color: "#FDD273",
    trailColor: "#FEECB3",
    hideViewMore: true,

    label: "Perfect",
  },

  conversionOnLaunch: {
    text: "CONVERSION\nON LAUNCH",
    value: 20,
    color: "#F17036",
    trailColor: "#FDDDBF",
    hideViewMore: true,

    label: "Poor",
  },
};

export const lifeQualityIndexChart = {
  lqiScore: 70,
  healthRelatedScore: 52,
  percentage: 36,
};

export class Point {
  x: number = 0;
  y: number = 0;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  string = () => `${this.x} ${this.y}`;
}

export const curvePoints = [
  new Point(10, 60),
  new Point(20, 36),
  new Point(30, 68),
  new Point(48, 70),
  new Point(60, 25),
  new Point(73, 33),
  new Point(86, 52),
];

export const fifthSimpleCircularChartsData = {
  burnoutScore: {
    text: "",
    value: 24,
    color: "#FDD273",
    trailColor: "#FEECB3",
    hideViewMore: true,
  },

  scoreOfEnergyLack: {
    text: "",
    value: 62,
    color: "#F17036",
    trailColor: "#FDDDBF",
    hideViewMore: true,
  },

  stressScore: {
    text: "",
    value: 14,
    color: "#4FB654",
    trailColor: "#DAF9D2",
    hideViewMore: true,
  },
};

export const fillBarChartData = {
  cdv: {
    label: fillChartValues.heart.label,
    colors: fillChartValues.heart.colors,
    values: fillChartValues.heart.values,
    icon: "cdv" as FillChartType,
    verticalBarChartValues: [33, 66, 20],
  },

  obesity: {
    label: fillChartValues.obesity.label,
    colors: fillChartValues.obesity.colors,
    values: fillChartValues.obesity.values,
    icon: "obesity" as FillChartType,
    verticalBarChartValues: [33, 66, 20],
  },

  msd: {
    label: fillChartValues.msd.label,
    colors: fillChartValues.msd.colors,
    values: fillChartValues.msd.values,
    icon: "msd" as FillChartType,
    verticalBarChartValues: [33, 66, 20],
    offset: 5,
  },

  stress: {
    label: fillChartValues.stressDepression.label,
    colors: fillChartValues.stressDepression.colors,
    values: fillChartValues.stressDepression.values,
    icon: "stress" as FillChartType,
    verticalBarChartValues: [33, 66, 20],
    offset: 20,
  },
};

export type FillChartType = "cdv" | "obesity" | "msd" | "stress";
