import React, { Component } from "react";
import "./absenteeismPresenteeism.css";

export class AbsenteeismPresenteeismChart extends Component<Props> {
  static defaultProps: Partial<Props> = {
    absenteeism: 12,
    presenteeism: 20,
  };

  render = () => (
    <div className="absenteeismPresenteeismContainer">
      <span>Extrapolated to 10000 people</span>
      <div
        className="absenteeismPresenteeismSubContainer"
        style={{ marginBottom: "20px" }}
      >
        <p>Absenteeism</p>
        {this.absenteeism()}
      </div>

      <div
        className="absenteeismPresenteeismSubContainer"
        style={{ marginTop: "20px" }}
      >
        <p>Presenteeism</p>
        {this.presenteeism()}
      </div>
    </div>
  );

  absenteeism = () => (
    <svg width="140px" height="134px" viewBox="0 0 140 134" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="absenteeism">
          <path
            d="M140,40.9702 L0,40.9702 L0,17.896 C0,14.8033 2.50706,12.2959 5.60008,12.2959 L134.4,12.2959 C137.493,12.2959 140,14.803 140,17.896 L140,40.9702 Z"
            id="Path"
            fill="#F17036"
            fillRule="nonzero"
          ></path>
          <path
            d="M132.533,133.11 L7.46678,133.11 C3.34295,133.11 0,129.767 0,125.644 L0,40.9697 L140,40.9697 L140,125.644 C140,129.767 136.657,133.11 132.533,133.11 Z"
            id="Path"
            fill="#EAF3F6"
            fillRule="nonzero"
          ></path>
          <text
            id="Days-/-Year"
            fill="#45525E"
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
            fontSize="16"
            fontWeight="normal"
          >
            <tspan x="34.316" y="105">
              Days / Year
            </tspan>
          </text>
          <path
            d="M27.6279,34.246 C32.1362,34.246 35.7909,30.5913 35.7909,26.083 C35.7909,21.5746 32.1362,17.9199 27.6279,17.9199 C23.1196,17.9199 19.4648,21.5746 19.4648,26.083 C19.4648,30.5913 23.1196,34.246 27.6279,34.246 Z"
            id="Path"
            fill="#CD512F"
            fillRule="nonzero"
          ></path>
          <path
            d="M27.6294,28.3472 C24.5367,28.3472 22.0293,25.8401 22.0293,22.7471 L22.0293,6.48973 C22.0293,3.39701 24.5364,0.88965 27.6294,0.88965 C30.7221,0.88965 33.2295,3.39671 33.2295,6.48973 L33.2295,22.7474 C33.2292,25.8401 30.7221,28.3472 27.6294,28.3472 Z"
            id="Path"
            fill="#D5D6DB"
            fillRule="nonzero"
          ></path>
          <path
            d="M112.37,34.246 C116.878,34.246 120.533,30.5913 120.533,26.083 C120.533,21.5746 116.878,17.9199 112.37,17.9199 C107.862,17.9199 104.207,21.5746 104.207,26.083 C104.207,30.5913 107.862,34.246 112.37,34.246 Z"
            id="Path"
            fill="#CD512F"
            fillRule="nonzero"
          ></path>
          <path
            d="M112.37,28.3472 C109.277,28.3472 106.77,25.8401 106.77,22.7471 L106.77,6.48973 C106.77,3.39701 109.277,0.88965 112.37,0.88965 C115.462,0.88965 117.97,3.39671 117.97,6.48973 L117.97,22.7474 C117.97,25.8401 115.462,28.3472 112.37,28.3472 Z"
            id="Path"
            fill="#D5D6DB"
            fillRule="nonzero"
          ></path>
          <text
            id="12"
            fill="#CD512F"
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
            fontSize="28"
            fontWeight="normal"
          >
            <tspan x="70" y="76" fontWeight="bold" textAnchor="middle">
              {this.props.absenteeism}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );

  presenteeism = () => (
    <svg width="140px" height="134px" viewBox="0 0 140 134" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="presenteeism">
          <path
            d="M140,40.9702 L0,40.9702 L0,17.896 C0,14.8033 2.50706,12.2959 5.60008,12.2959 L134.4,12.2959 C137.493,12.2959 140,14.803 140,17.896 L140,40.9702 Z"
            id="Path"
            fill="#FEBE3A"
            fillRule="nonzero"
          ></path>
          <path
            d="M132.533,133.1403 L7.46678,133.1403 C3.34295,133.1403 0,129.7973 0,125.6743 L0,41 L140,41 L140,125.6743 C140,129.7973 136.657,133.1403 132.533,133.1403 Z"
            id="Path"
            fill="#EAF3F6"
            fillRule="nonzero"
          ></path>
          <path
            d="M27.6279,34.246 C32.1362,34.246 35.7909,30.5913 35.7909,26.083 C35.7909,21.5746 32.1362,17.9199 27.6279,17.9199 C23.1196,17.9199 19.4648,21.5746 19.4648,26.083 C19.4648,30.5913 23.1196,34.246 27.6279,34.246 Z"
            id="Path"
            fill="#D99A38"
            fillRule="nonzero"
          ></path>
          <path
            d="M27.6294,28.3462 C24.5367,28.3462 22.0293,25.8391 22.0293,22.7461 L22.0293,6.48875 C22.0293,3.39603 24.5364,0.88867 27.6294,0.88867 C30.7221,0.88867 33.2295,3.39573 33.2295,6.48875 L33.2295,22.7464 C33.2292,25.8391 30.7221,28.3462 27.6294,28.3462 Z"
            id="Path"
            fill="#AEC2CE"
            fillRule="nonzero"
          ></path>
          <path
            d="M112.372,34.246 C116.88,34.246 120.535,30.5913 120.535,26.083 C120.535,21.5746 116.88,17.9199 112.372,17.9199 C107.864,17.9199 104.209,21.5746 104.209,26.083 C104.209,30.5913 107.864,34.246 112.372,34.246 Z"
            id="Path"
            fill="#D99A38"
            fillRule="nonzero"
          ></path>
          <path
            d="M112.37,28.3462 C109.277,28.3462 106.77,25.8391 106.77,22.7461 L106.77,6.48875 C106.77,3.39603 109.277,0.88867 112.37,0.88867 C115.462,0.88867 117.97,3.39573 117.97,6.48875 L117.97,22.7464 C117.97,25.8391 115.462,28.3462 112.37,28.3462 Z"
            id="Path"
            fill="#AEC2CE"
            fillRule="nonzero"
          ></path>
          <text
            id="Days-/-Year"
            fill="#45525E"
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
            fontSize="16"
            fontWeight="normal"
          >
            <tspan x="34.316" y="105">
              Days / Year
            </tspan>
          </text>
          <text
            id="12"
            fill="#FEBE3A"
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
            fontSize="28"
            fontWeight="normal"
          >
            <tspan x="70" y="76" fontWeight="bold" textAnchor="middle">
              {this.props.presenteeism}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

interface Props {
  absenteeism: number;
  presenteeism: number;
}
