import React, { Component, CSSProperties } from "react";
import "./arrowChart.css";
import { arrowChartIndexColors } from "components/data";

export class ArrowChart extends Component<ArrowChartProps> {
  colors = ["#F17036", "#F49D6C", "#FEBE3A", "#ADEAA4", "#7AD077"];

  static defaultProps = {
    showTopLabel: true,
    showBottomLabels: true,
    style: {},
    monthLabelClass: null,
    oldLqiScore: 50,
    previousDateLabel: "March 2019",
    currentDateLabel: "June 2019",
  };

  percentage = () => {
    const value = this.props.lqiScore - this.props.oldLqiScore;
    let sign = "";
    if (value >= 0) {
      sign = "+";
    }

    return `${sign} ${value}`;
  };

  render = () => (
    <div className="arrowChartFullContainer" style={this.props.style}>
      {this.props.showTopLabel ? <p>{this.percentage()}%</p> : null}
      <p className="arrowChartSmallLabel">
        since {this.props.previousDateLabel}
      </p>

      <div className="arrowChartContainer">
        <div className="arrowChartTopContainer">
          <div className="arrowChartTopContainerSquare">
            <div className="arrowChartLeftLabelContainer">
              <span className="arrowChartAxisLabel arrowChartRotate text">
                LQI Score
              </span>
            </div>

            <div className="arrowChartLeftAxis">{this.axisSegments(true)}</div>

            <div className="arrowChartGraphContainer">
              <div className="arrowChartAxisContainer">{this.svg()}</div>
              <div className="arrowChartBottomAxis">
                {this.axisSegments(false)}
              </div>
              <div className="arrowChartBottomLabelContainer">
                <span className="arrowChartAxisLabel text">Time</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {this.props.showBottomLabels ? (
        <div className="arrowChartFooter">
          <span className="arrowChartFooterCircle" />
          <span>Other iamYiam corporate clients right now</span>
        </div>
      ) : null}
    </div>
  );

  axisSegments = (vertical: boolean) => {
    const items = this.colors.reverse();
    return items.map((item, index) => (
      <div
        key={index}
        className="arrowChartAxisSegment"
        style={{
          backgroundColor: item,
          borderBottomLeftRadius: this.borderBottomLeftRadius(index, vertical),
          borderBottomRightRadius: this.borderBottomRightRadius(index),
          borderTopLeftRadius: this.borderTopLeftRadius(index),
          borderTopRightRadius: this.borderTopRightRadius(index, vertical),
        }}
      />
    ));
  };

  OFFSET = 10;

  svg = () => {
    const { x, y } = this.circlePath();
    const newLQIYPosition = 100 - (this.props.lqiScore / 100) * 80 - 10;
    const oldLQIYPosition = 100 - (this.props.oldLqiScore / 100) * 80 - 10;

    return (
      <svg style={{ flexGrow: 1, overflow: "visible" }} viewBox="0 0 100 100">
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="10"
            refY="5"
            markerWidth="5"
            markerHeight="5"
            stroke="#D6E6EE"
            fill="none"
            orient="auto-start-reverse"
          >
                  <path d="M 0 0 L 10 5 L 0 10" />
          </marker>
        </defs>

        <text
          x="5"
          y={newLQIYPosition}
          textAnchor="middle"
          className="arrowChartValueLabel text"
          alignmentBaseline="central"
          dominantBaseline="central"
        >
          {this.props.lqiScore}%
        </text>
        <path
          d={`M 10 ${newLQIYPosition} L ${
            this.props.healthRelatedScore - 5
          } ${newLQIYPosition}`}
          stroke="#D6E6EE"
          markerEnd="url(#arrow)"
          strokeWidth="0.5"
        />

        <text
          x="5"
          y={oldLQIYPosition}
          textAnchor="middle"
          className="arrowChartValueLabel text"
          alignmentBaseline="central"
          dominantBaseline="central"
        >
          {this.props.oldLqiScore}%
        </text>
        <path
          d={`M 10 ${oldLQIYPosition} L ${
            this.props.healthRelatedScore / 2 - 5
          } ${oldLQIYPosition}`}
          stroke="#D6E6EE"
          markerEnd="url(#arrow)"
          strokeWidth="0.5"
        />

        <circle
          cx={this.props.healthRelatedScore}
          cy={newLQIYPosition}
          r="1.5"
          fill={arrowChartIndexColors.currentDate}
          stroke={arrowChartIndexColors.currentDate}
        />
        <text
          x={this.props.healthRelatedScore}
          y={newLQIYPosition - 4}
          textAnchor="middle"
          className={`arrowChartMonthLabel ${this.props.monthLabelClass}`}
        >
          {this.props.currentDateLabel}
        </text>

        <text
          x={this.props.healthRelatedScore / 2}
          y={
            oldLQIYPosition +
            (this.props.lqiScore > this.props.oldLqiScore ? 5 : -5)
          }
          textAnchor="middle"
          alignmentBaseline="central"
          dominantBaseline="central"
          className={`arrowChartMonthLabel ${this.props.monthLabelClass}`}
        >
          {this.props.previousDateLabel}
        </text>

        <path
          d={`M 26 ${oldLQIYPosition} L ${x} ${100 - y}`}
          stroke="#D6E6EE"
          markerEnd="url(#arrow)"
          strokeWidth="0.5"
        />
        <circle cx="26" cy={oldLQIYPosition} r="3.5" fill="white" />
        <circle
          cx="26"
          cy={oldLQIYPosition}
          r="1"
          fill={arrowChartIndexColors.previousDate}
          stroke={arrowChartIndexColors.previousDate}
        />

        <circle cx="20" cy="70" r="1.45" fill="#45525E" />
        <circle cx="34" cy="45" r="1.45" fill="#45525E" />
        <circle cx="63" cy="55" r="1.45" fill="#45525E" />
        <circle cx="70" cy="65" r="1.45" fill="#45525E" />
      </svg>
    );
  };

  circlePath = () => {
    const xA = 26;
    const yA = (this.props.oldLqiScore / 100) * 80 + 10;

    const xB = this.props.healthRelatedScore;
    const yB = (this.props.lqiScore / 100) * 80 + 10;

    const d = Math.sqrt(Math.pow(xB - xA, 2) + Math.pow(yB - yA, 2));
    const k = 5 / d;

    const x = (1 / (1 + k)) * xB + (k / (1 + k)) * xA;
    const y = (1 / (1 + k)) * yB + (k / (1 + k)) * yA;

    return { x, y };
  };

  borderBottomLeftRadius = (index: number, vertical: boolean) => {
    if (!vertical && index === 0) {
      return "8px";
    } else if (vertical && index === this.colors.length - 1) {
      return "8px";
    }
    return "0px";
  };
  borderBottomRightRadius = (index: number) => {
    return index === this.colors.length - 1 ? "8px" : "0px";
  };

  borderTopRightRadius = (index: number, vertical: boolean) => {
    if (vertical && index === 0) {
      return "8px";
    } else if (!vertical && index === this.colors.length - 1) {
      return "8px";
    }
    return "0px";
  };
  borderTopLeftRadius = (index: number) => {
    return index === 0 ? "8px" : "0px";
  };
}

export interface ArrowChartProps {
  lqiScore: number;
  oldLqiScore: number;
  healthRelatedScore: number;

  showTopLabel: boolean;
  showBottomLabels: boolean;
  style: CSSProperties;
  monthLabelClass?: string;

  previousDateLabel: string;
  currentDateLabel: string;
}
