import { Energy } from "lqi/types";

/**
 * state
 */
export interface HealthRisksElement {
  benchmark?: number;
  company: number;
  country: number;
  date: string;

  locked?: boolean;
}

export interface HealthRisksBenchmarks {
  cvd: HealthRisksElement[];
  msd: HealthRisksElement[];
  metabolic: HealthRisksElement[];
  sad: HealthRisksElement[];

  anxiety: HealthRisksElement[];
  depression: HealthRisksElement[];
  stress: HealthRisksElement[];

  obesity: HealthRisksElement[];
  t2d: HealthRisksElement[];
}

export interface OverviewElement {
  date: string;
  value: number;

  locked?: boolean;
}

export interface Overview {
  conversion: OverviewElement[];
  lqi: OverviewElement[];
  retention: OverviewElement[];
  roi: OverviewElement[];
}

export interface RetentionElement {
  date: string;
  value: number;
}

export interface Retention {
  appreciated: RetentionElement[];
  challenged: RetentionElement[];
  empowered: RetentionElement[];
  flexible: RetentionElement[];
  involved: RetentionElement[];
  mentored: RetentionElement[];
  onMission: RetentionElement[];
  paidWell: RetentionElement[];
  promoted: RetentionElement[];
  trusted: RetentionElement[];
  valued: RetentionElement[];
}

export interface HomeState {
  healthRisks: HealthRisksBenchmarks;
  overview: Overview;
  retention: Retention;
  energy: Energy[];
}

/**
 * actions
 */
export const HEALTH_RISKS_LOADED = "HEALTH_RISKS_LOADED";
export const OVERVIEW_LOADED = "OVERVIEW_LOADED";
export const RETENTION_LOADED = "RETENTION_LOADED";
export const ENERGY_LOADED = "ENERGY_LOADED";

interface HealthRisksLoadedAction {
  type: typeof HEALTH_RISKS_LOADED;
  healthRisks: HealthRisksBenchmarks;
}

interface OverviewLoadedAction {
  type: typeof OVERVIEW_LOADED;
  overview: Overview;
}

interface RetentionLoadedAction {
  type: typeof RETENTION_LOADED;
  retention: Retention;
}

interface EnergyLoadedAction {
  type: typeof ENERGY_LOADED;
  energy: Energy[];
}

export type HomeAction =
  | HealthRisksLoadedAction
  | OverviewLoadedAction
  | RetentionLoadedAction
  | EnergyLoadedAction;
