import { Gender, Age, Diversity } from "./types";

export const genderValues = (gender: Gender) => ({
  maleValue: gender.male,
  femaleValue: gender.female,
  nonBinaryValue: gender.nonbinary,
});

export const ageValues = (age: Age[]) => age.map((item) => item.value);

export type Continent = keyof Diversity;
