import React, { Component } from "react";
import { FillChart } from "./FillChart";
import { VerticalBarCharts } from "../vertical_chart/VerticalBarCharts";
import { Button } from "../button/Button";
import "./fillBarChart.css";
import { Locked } from "components/locked/Locked";

export class FillBarChart extends Component<FillBarChartProps> {
  static defaultProps = {
    verticalLabels: ["0%", "33%", "66%", "100%"].reverse(),
    horizontalLabels: ["<30%", "30-60%", ">60%"],
    offset: 0,
    change: -25,
  };

  percentage = () => {
    if (this.props.change <= 0) {
      return `${this.props.change}%`;
    } else {
      return `+${this.props.change}%`;
    }
  };

  percentageClass = () => {
    if (this.props.change >= 0) {
      return "fillBartChartTitlePercentage fillBarChartTitleGreen";
    } else {
      return "fillBartChartTitlePercentage fillBarChartTitleRed";
    }
  };

  growthText = () => {
    return "Since inception: 22/06/2020";
  };

  render = () => (
    <div
      className="chartContainer"
      style={{
        justifyContent: "space-around",
        paddingBottom: "1em",
      }}
    >
      <div className="fillBarChartLeftContainer">
        <p className="fillBarChartTitle">{this.props.label}</p>
        <div className="fillBarChartLeftRow">
          <Button
            text="See Evolution"
            style={{ height: "28px" }}
            type="white"
            onClick={this.props.onViewDetailsClicked}
          />
        </div>
        <span className="fillBarChartDescriptionLabel">
          {/* {this.growthText()} */}
        </span>

        <FillChart
          values={this.props.values}
          colors={this.props.colors}
          label=""
          icon={this.props.icon}
          hideTitle={true}
          center={true}
          leftMarginOffset={this.props.offset}
          large={true}
          style={{ marginBottom: "1em" }}
        />

        {this.props.healthRiskLocked && <Locked />}
      </div>
      <div className="fillBarChartLeftContainer">
        <p className="fillBarChartTitle" style={{ marginLeft: "30px" }}>
          People at risk
        </p>
        <VerticalBarCharts
          values={this.props.verticalBarChartValues}
          horizontalLabels={this.props.horizontalLabels}
          verticalLabels={this.props.verticalLabels}
          colors={this.props.colors}
          large={true}
        />
        {this.props.distributionLocked && <Locked />}
      </div>
    </div>
  );
}

export interface FillBarChartProps {
  colors: string[];
  values: number[];
  label: string;
  verticalLabels: string[];
  horizontalLabels: string[];
  icon: any;
  change: number;

  verticalBarChartValues: number[];
  offset: number;
  onViewDetailsClicked: () => void;

  healthRiskLocked?: boolean;
  distributionLocked?: boolean;
}
