import React, { Component, ChangeEvent } from "react";
import "./textInput.css";

export class TextInput extends Component<TextInputProps, {}> {
  static defaultProps = {
    class: "",
    error: "",
    onTextChanged: () => {},
    password: false,
    text: "",
  };

  render = () => (
    <div className="inputErrorContainer">
      <div className={`inputContainer ${this.props.class}`}>
        {this.props.sourceImage ? (
          <img src={this.props.sourceImage} alt="" />
        ) : null}
        <input
          placeholder={this.props.placeholder}
          onChange={this.onTextChanged}
          type={this.props.password ? "password" : "text"}
          value={this.props.text}
        />
      </div>
      <span className={`inputError ${this.props.error ? "visible" : "hidden"}`}>
        {this.props.error}
      </span>
    </div>
  );

  onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onTextChanged(e.target.value);
  };
}

export interface TextInputProps {
  placeholder: string;
  sourceImage?: any;
  class: string;
  error: string;
  password: boolean;
  text: string;

  onTextChanged: (text: string) => void;
}
