import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "common/state";
import { api } from "common/api";
import { KpiKey } from "components/kpis";
import { Outlook } from "./types";
import lqiConversionUtils from "./common";

export interface Recommendation {
  slug: string;
  title: string;
  excerpt: string;
  content: string;
  image: string;
  modified: string;
  mediaId: number;
}

export type RecommendationKey = string;

export type RecommendationPair = Record<RecommendationKey, Recommendation>;

export type RecommendationsData = RecommendationPair;

type LQIDist = {
  [key in KpiKey]: {
    range: string;
    value: number;
  }[];
};

interface State {
  loading: boolean;
  recommendations: RecommendationsData;
  lqiDist: LQIDist;
}

export type LQIDistPayload = State["lqiDist"]["brainPower"];

const initialState: State = {
  recommendations: {},
  loading: false,

  lqiDist: {
    brainPower: [],
    career: [],
    emotional: [],
    environment: [],
    financial: [],
    health: [],
    purpose: [],
    selfAwareness: [],
    socialLife: [],
  },
};

export const reducer = createSlice({
  name: "recommendations",
  initialState,
  reducers: {
    addRecommendation(
      state,
      action: PayloadAction<{
        slug: string;
        recommendation: Recommendation;
      }>
    ) {
      state.recommendations[action.payload.slug] =
        action.payload.recommendation;
    },
    clearRecommendations(state) {
      state.recommendations = {};
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    lqiDistLoaded(
      state,
      action: PayloadAction<Record<string, LQIDistPayload>>
    ) {
      for (let key of Object.keys(action.payload)) {
        const kpiKey = lqiConversionUtils.lqiIdtoKPIKey(key);
        state.lqiDist[kpiKey] = action.payload[key];
      }
    },
  },
});

export const loadRecommendations =
  (lqiId?: string, healthRisk?: string): AppThunk =>
  (dispatch) => {
    let requests = 0;

    const requestFinished = (
      partialRecommendation: any,
      slug: RecommendationKey,
      image: string
    ) => {
      dispatch(
        reducer.actions.addRecommendation({
          slug,
          recommendation: {
            content: partialRecommendation.content!,
            mediaId: partialRecommendation.mediaId!,
            modified: partialRecommendation.modified!,
            excerpt: partialRecommendation.excerpt!,
            image: image,
            title: partialRecommendation.title!,
            slug: partialRecommendation.slug!,
          },
        })
      );
      requests--;
      if (requests === 0) {
        dispatch(reducer.actions.loading(false));
      }
    };

    dispatch(reducer.actions.loading(true));
    dispatch(reducer.actions.clearRecommendations());

    api.getArticles(lqiId, healthRisk).then(({ articles }) => {
      const slugs = articles.map((item) => item.url);
      for (let slug of slugs) {
        requests++;

        api.getRecommendation(slug).then((partialRecommendation) => {
          api
            .getRecommendationImage(partialRecommendation.mediaId!)
            .then((image) =>
              requestFinished(partialRecommendation, slug, image)
            );
        });
      }
    });
  };

export const loadLqiDist = (): AppThunk => async (dispatch) => {
  const result = await api.getLQIDist();
  dispatch(reducer.actions.lqiDistLoaded(result));
};

export const loadOutlook = (lqiId: string): Promise<Outlook> => {
  return api.call<Outlook>("kpiPerformers", true, { lqiId });
};

export const recommendations = reducer.reducer;
