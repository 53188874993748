import { LQI, Longetivity, Energy } from "./types";
import { Point } from "../components/data";
import { Pair } from "components/detailed_curve_chart/AbsoluteDetaileddCurveChart";
import { KpiKey } from "components/kpis";
import lqiConversionUtils from "lqiDetails/common";

export const getDimensionScore = (lqis: LQI[], kpiKey: KpiKey): number => {
  if (lqis.length === 0) {
    return 0;
  }
  const lqiId = lqiConversionUtils.KPIKeyToLqiId(kpiKey);
  const dimension = lqis[lqis.length - 1].dimensions.find(
    (item) => item.id === lqiId
  );
  return dimension ? dimension.score : 0;
};

export const getDimensionLocked = (
  lqis: LQI[],
  kpiKey: KpiKey
): boolean | undefined => {
  if (lqis.length === 0) {
    return false;
  }
  const lqiId = lqiConversionUtils.KPIKeyToLqiId(kpiKey);
  const dimension = lqis[lqis.length - 1].dimensions.find(
    (item) => item.id === lqiId
  );
  return dimension ? dimension.locked : false;
};

export const getDimensionValuesOverTime = (
  lqis: LQI[],
  kpiKey: KpiKey
): Pair[] => {
  if (lqis.length === 0) {
    return [];
  }
  const lqiId = lqiConversionUtils.KPIKeyToLqiId(kpiKey);

  const result: Pair[] = [];
  for (let lqi of lqis) {
    const dimension = lqi.dimensions.find((item) => item.id === lqiId);
    if (dimension) {
      result.push({ label: lqi.date, value: dimension.score });
    }
  }
  return result;
};

export const getLQIValuesOverTime = (lqis: LQI[]): Pair[] => {
  if (lqis.length === 0) {
    return [];
  }

  const result: Pair[] = [];
  for (let lqi of lqis) {
    result.push({ label: lqi.date, value: lqi.overall_score });
  }

  return result;
};

export const getEnergyValue = (energy: Energy[]) => {
  if (energy.length === 0) {
    return 0;
  }
  return energy[energy.length - 1].kpis[0].score;
};

export const getEnergyLocked = (energy: Energy[]) => {
  if (energy.length === 0) {
    return true;
  }
  return energy[energy.length - 1].kpis[0].locked;
};

export const getEnergyValuesOverTime = (energy: Energy[]): Pair[] => {
  if (energy.length === 0) {
    return [];
  }

  return energy.map((item) => ({
    label: item.date,
    value: item.kpis[0].score,
  }));
};

export const getDateLabels = (lqi: LQI[]): string[] =>
  lqi.length < 2 ? ["", ""] : [lqi[0].date, lqi[lqi.length - 1].date];

export const getValues = (lqi: LQI[]): number[] =>
  lqi.length < 2
    ? [0, 0]
    : [lqi[0].overall_score, lqi[lqi.length - 1].overall_score];

export const bottomLabels = (longetivity: Longetivity[]) =>
  longetivity.map((item) => item.range);

export const topLabels = (longetivity: Longetivity[]) =>
  longetivity.map((item) => `${item}%`);

export const points = (longetivity: Longetivity[]) =>
  longetivity.map((item, index) => {
    const x = index * (80 / (longetivity.length - 1)) + 10;
    return new Point(x, item.value);
  });

export const largeScreen = window.matchMedia("(min-width: 1920px)").matches;
