import React, { useState, useRef, FC, RefObject } from "react";
import { animationStyle, usePosition } from "common/utils";
import { fillChartValues } from "components/data";
import { FillChart } from "components/fill_chart/FillChart";
import { useHistory } from "react-router-dom";

export const CostsChartsModal: FC<Props> = (props) => {
  const [exit, setExit] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const width = props.data.length === 3 ? 800 : 600;
  const position = usePosition(width, props.parentRef, 50);

  return (
    <div
      id="recommendationModalContainer"
      className={exit ? "fadeOutContainer" : ""}
      onClick={() => {
        setExit(true);
        setTimeout(props.onDimissed, 1000);
      }}
      style={{ background: "none" }}
    >
      <div
        id="chartModal"
        className={`${exit ? "slideOutModal" : ""}`}
        style={{
          maxWidth: `${width}px`,
          left: `${position.left}px`,
          top: `${position.top}px`,
          ...animationStyle(exit, modalRef),
        }}
        ref={modalRef}
      >
        {props.data.map((item) => (
          <FillChart
            {...item.type}
            key={item.type.label}
            style={{ margin: "20px" }}
            leftMarginOffset={20}
            values={item.values}
            clickable={true}
            onChartPressed={() => history.push(item.target)}
          />
        ))}
      </div>
    </div>
  );
};

type FillChartType = typeof fillChartValues.heart;

interface Data {
  type: FillChartType;
  values: number[];
  target: string;
}

interface Props {
  onDimissed: () => void;

  data: Data[];
  parentRef: RefObject<HTMLDivElement>;
}
