import { Point } from "./data";

const smoothing = 0.2;

export const line = (pointA: Point, pointB: Point) => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;

  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX)
  };
};

export const controlPoint = (
  current: Point,
  previous: Point,
  next: Point,
  reverse: boolean = false
): Point => {
  const p = previous || current;
  const n = next || current;

  const o = line(p, n);

  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;

  const x = current.x + Math.cos(angle) * length;
  const y = current.y + Math.sin(angle) * length;
  return new Point(x, y);
};

export const bezierCommand = (point: Point, i: number, a: Point[]) => {
  const cps = controlPoint(a[i - 1], a[i - 2], point);

  const cpe = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cps.x},${cps.y} ${cpe.x},${cpe.y} ${point.x},${point.y}`;
};
