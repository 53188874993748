import React, { Component } from "react";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import "../styles.css";
import { FillChart } from "../components/fill_chart/FillChart";
import { fillChartValues } from "../components/data";
import { VerticalBarCharts } from "../components/vertical_chart/VerticalBarCharts";
import { AbsenteeismPresenteeismChart } from "../components/presenteeism/AbsenteeismPresenteeismChart";
import { VerticalSimpleBarChart } from "../components/vertical_chart/verticalSimpleBarChart";
import { HealthRisks, CostCategories, HealthRisksCosts } from "./types";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  loadHealthRisks,
  loadCostCategories,
  loadHealthRisksCosts,
} from "./actions";
import { connect } from "react-redux";
import {
  labels,
  values,
  chartValues,
  getVerticalSimpleChartValues,
  getVerticalSimpleChartLabels,
  chartLocked,
} from "./common";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { GuidedProps } from "common/types";
import { Tooltip } from "components/tooltip/Tooltip";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { content } from "common/content";
import { CostsChartsModal } from "components/feedback_chart/CostsChartsModal";
import { LoaderWrappper } from "common/LoaderWrapper";

class CostsScreen extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    blur: false,
    blurIndex: -1,
    highlightedItems: {},
  };

  state: State = {
    showObesityModal: false,
    showSADModal: false,
    loading: true,
  };

  sadRef = React.createRef<HTMLDivElement>();
  obesityRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.props.loadHealthRisks();
    this.props.loadCostCategories();
    this.props.loadHealthRisksCosts();
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.healthRisks.cvd.length > 0) {
      this.setState({ ...this.state, loading: false });
    }
  }

  blur = () =>
    this.props.blur || this.state.showSADModal || this.state.showObesityModal;

  render() {
    return (
      <LoaderWrappper
        child={this.renderContent()}
        loading={this.state.loading}
      />
    );
  }

  renderContent = () => {
    const style = { margin: "5px" };

    return (
      <div className="container">
        <SideMenu
          subMenuIndex={0}
          blur={this.blur()}
          highlight={this.blur() && this.props.blurIndex >= 0}
          index={2}
          marginTop={9}
        />

        <div className="rightContainer">
          <Header blur={this.blur()} />

          <DropDownSelectors blur={this.blur()} />

          <span className="titleLabel">Your 4 Key Health Risks</span>

          <div
            className={`chartContainer costs ${
              this.props.blur && this.props.blurIndex !== 0 ? "blur" : ""
            } ${this.props.blurIndex === 0 ? "highlight" : ""}`}
            ref={this.props.highlightedItems[0]}
          >
            <FillChart
              {...fillChartValues.stressDepression}
              style={style}
              leftMarginOffset={20}
              values={chartValues(this.props.healthRisks.sad)}
              clickable={true}
              onChartPressed={() =>
                this.setState({ ...this.state, showSADModal: true })
              }
              containerRef={this.sadRef}
              blur={
                this.blur() &&
                !this.state.showSADModal &&
                this.props.blurIndex !== 0
              }
              locked={chartLocked(this.props.healthRisks.sad)}
            />
            <FillChart
              {...fillChartValues.heart}
              style={style}
              values={chartValues(this.props.healthRisks.cvd)}
              clickable={true}
              onChartPressed={() => this.props.history.push("/cvd")}
              blur={this.blur() && this.props.blurIndex !== 0}
              locked={chartLocked(this.props.healthRisks.cvd)}
            />
            <FillChart
              {...fillChartValues.obesity}
              style={style}
              values={chartValues(this.props.healthRisks.metabolic)}
              clickable={true}
              onChartPressed={() =>
                this.setState({ ...this.state, showObesityModal: true })
              }
              containerRef={this.obesityRef}
              blur={
                this.blur() &&
                !this.state.showObesityModal &&
                this.props.blurIndex !== 0
              }
              locked={chartLocked(this.props.healthRisks.metabolic)}
            />
            <FillChart
              {...fillChartValues.msd}
              style={style}
              leftMarginOffset={10}
              values={chartValues(this.props.healthRisks.msd)}
              clickable={true}
              onChartPressed={() => this.props.history.push("/msd")}
              blur={this.blur() && this.props.blurIndex !== 0}
              locked={chartLocked(this.props.healthRisks.msd)}
            />

            <Tooltip description={content[2][0]} />
          </div>

          <div className="rowColumnChartContainer">
            <div
              className={`chartColumnContainer ${
                this.blur() && this.props.blurIndex !== 1 ? "blur" : ""
              }`}
            >
              <span className="titleLabel" style={{ marginLeft: "0em" }}>
                Days Lost due to health risks
              </span>

              <div
                className={`verticalChartContainer ${
                  this.blur() && this.props.blurIndex !== 1 ? "blur" : ""
                } ${this.props.blurIndex === 1 ? "highlight" : ""}`}
                ref={this.props.highlightedItems[1]}
              >
                <AbsenteeismPresenteeismChart
                  absenteeism={this.props.healthRisksCosts.abs.days.total}
                  presenteeism={this.props.healthRisksCosts.prs.days.total}
                />
                <Tooltip description={content[2][1]} />
              </div>
            </div>

            <div
              className={`chartColumnContainer ${
                this.blur() && this.props.blurIndex !== 2 ? "blur" : ""
              }`}
            >
              <span className="titleLabel" style={{ marginLeft: "0em" }}>
                Your predicted yearly costs
              </span>

              <div
                className={`verticalChartContainer ${
                  this.blur() && this.props.blurIndex !== 2 ? "blur" : ""
                } ${this.props.blurIndex === 2 ? "highlight" : ""}`}
                ref={this.props.highlightedItems[2]}
              >
                <VerticalBarCharts
                  values={values(this.props.costCategories)}
                  verticalLabels={labels(this.props.costCategories)}
                  isCostChart={true}
                />

                <Tooltip description={content[2][2]} />
              </div>
            </div>

            <div
              className={`chartColumnContainer ${
                this.blur() && this.props.blurIndex !== 3 ? "blur" : ""
              }`}
            >
              <span className="titleLabel" style={{ marginLeft: "0em" }}>
                Your predicted preventable disease costs
              </span>

              <div
                className={`verticalChartContainer ${
                  this.blur() && this.props.blurIndex !== 3 ? "blur" : ""
                } ${this.props.blurIndex === 3 ? "highlight" : ""}`}
                ref={this.props.highlightedItems[3]}
              >
                <VerticalSimpleBarChart
                  labels={getVerticalSimpleChartLabels(
                    this.props.healthRisksCosts
                  )}
                  values={getVerticalSimpleChartValues(
                    this.props.healthRisksCosts
                  )}
                />

                <Tooltip description={content[2][3]} />
              </div>
            </div>
          </div>

          <GiveFeedback blur={this.blur()} />
        </div>
        {this.state.showObesityModal && this.obesityModal()}
        {this.state.showSADModal && this.sadModal()}
      </div>
    );
  };

  obesityModal = () => {
    return (
      <CostsChartsModal
        onDimissed={() =>
          this.setState({ ...this.state, showObesityModal: false })
        }
        data={[
          {
            type: { ...fillChartValues.obesity, label: "OBESITY RISK" },
            values: chartValues(this.props.healthRisks.obesity),
            target: "/obesity",
          },
          {
            type: {
              ...fillChartValues.obesity,
              label: "TYPE II DIABETES RISK",
            },
            values: chartValues(this.props.healthRisks.t2d),
            target: "/diabetes",
          },
        ]}
        parentRef={this.obesityRef}
      />
    );
  };

  sadModal = () => {
    return (
      <CostsChartsModal
        onDimissed={() => this.setState({ ...this.state, showSADModal: false })}
        data={[
          {
            type: { ...fillChartValues.stressDepression, label: "STRESS" },
            values: chartValues(this.props.healthRisks.stress),
            target: "/stress",
          },
          {
            type: {
              ...fillChartValues.stressDepression,
              label: "ANXIETY",
            },
            values: chartValues(this.props.healthRisks.anxiety),
            target: "/anxiety",
          },
          {
            type: {
              ...fillChartValues.stressDepression,
              label: "DEPRESSION",
            },
            values: chartValues(this.props.healthRisks.depression),
            target: "/depression",
          },
        ]}
        parentRef={this.sadRef}
      />
    );
  };
}

interface Props extends RouteComponentProps<any>, GuidedProps {
  healthRisks: HealthRisks;
  costCategories: CostCategories;
  healthRisksCosts: HealthRisksCosts;

  loadHealthRisks: () => void;
  loadCostCategories: () => void;
  loadHealthRisksCosts: () => void;
}

interface State {
  showObesityModal: boolean;
  showSADModal: boolean;
  loading: boolean;
}

function mapStateToProps(state: RootState) {
  return {
    healthRisks: state.costs.healthRisks,
    costCategories: state.costs.costCategories,
    healthRisksCosts: state.costs.healthRiskCosts,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadHealthRisks: () => dispatch(loadHealthRisks()),
    loadCostCategories: () => dispatch(loadCostCategories()),
    loadHealthRisksCosts: () => dispatch(loadHealthRisksCosts()),
  };
}

export const CostsScreenNoRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(CostsScreen);

export default withRouter(CostsScreenNoRouter);
