import {
  CostCategories,
  HealthRiskElement,
  CostDistributionElement,
  HealthRisksCosts,
} from "./types";
import { RiskChangeElement } from "../risks_changes/types";

export const labels = (costCategories: CostCategories): string[] => {
  const values = [
    costCategories.abs,
    costCategories.medical,
    costCategories.prs,
  ];

  const max = Math.max(...values);
  const labelValues = [0, max / 3, (2 * max) / 3, max];

  return labelValues
    .map((value) => "£" + (value / 1000000).toFixed(1) + "m")
    .reverse();
};

export const values = (costCategories: CostCategories): number[] => {
  const initialValues = [
    costCategories.abs,
    costCategories.prs,
    costCategories.medical,
  ];

  const max = Math.max(...initialValues);
  return initialValues.map((item) => (item / max) * 100);
};

export const chartValues = (elements: HealthRiskElement[]) =>
  elements.length === 0
    ? [0, 0, 0]
    : [elements[0].healthy, elements[0].inRisk, elements[0].inDanger];

export const chartLocked = (elements: HealthRiskElement[]) =>
  elements.length === 0 ? false : elements[0].locked;

export const barChartLabels = (elements: CostDistributionElement[]): string[] =>
  elements.map((item) => item.range);

export const barChartValues = (elements: CostDistributionElement[]): number[] =>
  elements.map((item) => item.value);

export const getChange = (elements: RiskChangeElement[]): number => {
  if (elements.length === 1) {
    return elements[0].healthy;
  } else if (elements.length > 1) {
    return (
      elements[elements.length - 1].healthy -
      elements[elements.length - 2].healthy
    );
  } else {
    return 0;
  }
};

export const getVerticalSimpleChartLabels = (
  costsParams: HealthRisksCosts
): string[] => {
  const { risk_costs: costs } = costsParams;
  const sum = costs.msd + costs.sad + costs.cvd + costs.metabolic;

  return [0, Math.floor(sum / 3), Math.floor((sum * 2) / 3), Math.floor(sum)]
    .map((item) => "£" + (item / 1000000).toFixed(2) + "m")
    .reverse();
};

export const getVerticalSimpleChartValues = (
  costsParam: HealthRisksCosts
): number[] => {
  const { risk_costs: costs } = costsParam;

  const sum = costs.msd + costs.sad + costs.cvd + costs.metabolic;
  if (sum === 0) {
    return [0, 0, 0, 0];
  } else {
    return [
      costs.msd / sum,
      costs.sad / sum,
      costs.cvd / sum,
      costs.metabolic / sum,
    ];
  }
};
