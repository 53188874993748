import React, { Component } from "react";
import { SideMenu } from "../components/SideMenu";
import { Header } from "../components/Header";
import { DropDownSelectors } from "../components/dropdown/DropDownSelectors";
import "../styles.css";
import { CircularSimpleChart } from "../components/circular_chart/CircularSimpleChart";
import { conversionScores, genderChartValues } from "../components/data";
import { GoalChart } from "../components/bar_chart/GoalChart";
import { GenderChart } from "../components/gender_chart/GenderChart";
import { TriangleChart } from "../components/triangle_chart/TriangleChart";
import { MapChart } from "../components/map_chart/MapChart";
import { RootState } from "../common/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import {
  loadDemographics,
  loadConversion,
  loadGoalRank,
  loadRecommendationsRank,
} from "./actions";
import { Demographics, Conversion, Rank } from "./types";
import { genderValues } from "./common";
import { assets } from "../assets/assets";
import { GuidedProps } from "common/types";
import { Tooltip } from "components/tooltip/Tooltip";
import { NPSChart } from "components/feedback_chart/NPSChart";
import { Overview } from "home/types";
import { loadOverview } from "home/actions";
import { loadSatisfaction } from "lqi/actions";
import { Satisfaction } from "lqi/types";
import { Animator } from "components/speed_chart/Animator";
import { GiveFeedback } from "components/feedback_chart/GiveFeedback";
import { content } from "common/content";
import { LoaderWrappper } from "common/LoaderWrapper";

class EngagementScreen extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    blur: false,
    blurIndex: -1,
    highlightedItems: {},
  };

  state: State = {
    showNPSModal: false,
    animationData: Animator.initialize(4),
    loading: true,
  };

  animator = new Animator(
    (data) => this.setState({ ...this.state, animationData: data }),
    requestAnimationFrame.bind(window)
  );

  componentDidMount() {
    this.props.loadDemographics();
    this.props.loadConversion();
    this.props.loadGoalsRank();
    this.props.loadOverview();
    this.props.loadSatisfaction();
  }

  componentDidUpdate() {
    if (
      this.state.loading &&
      this.props.goalsRank.length > 0 &&
      this.props.conversion.action > 0 &&
      this.props.conversion.dashboard > 0
    ) {
      this.setState({ ...this.state, loading: false });
    }
  }

  render() {
    return (
      <LoaderWrappper
        child={this.renderContent()}
        loading={this.state.loading}
      />
    );
  }

  renderContent = () => (
    <div className="container">
      <SideMenu
        index={1}
        subMenuIndex={0}
        blur={this.props.blur}
        highlight={this.props.blur}
      />

      <div className="rightContainer">
        <Header blur={this.props.blur} />

        <DropDownSelectors blur={this.props.blur} />

        <span
          className={`titleLabel ${
            this.props.blur && this.props.blurIndex !== 0 ? "blur" : ""
          }`}
        >
          Your Demographics
        </span>

        <div
          className={`chartContainer engagement first ${
            this.props.blur && this.props.blurIndex !== 0 ? "blur" : ""
          } ${this.props.blurIndex === 0 ? "highlight" : ""}`}
          ref={this.props.highlightedItems[0]}
        >
          <GenderChart
            {...genderChartValues}
            {...genderValues(this.props.demographics.gender)}
            locked={this.props.demographics.locked.gender}
          />
          <TriangleChart
            values={this.props.demographics.age}
            locked={this.props.demographics.locked.age}
          />
          <MapChart
            diversity={this.props.demographics.diversity}
            locked={this.props.demographics.locked.diversity}
          />

          <Tooltip description={content[1][0]} />
        </div>

        <span
          className={`titleLabel ${
            this.props.blur && this.props.blurIndex !== 1 ? "blur" : ""
          }`}
        >
          Engagement Indicators
        </span>

        <div
          className={`chartContainer extraPadding ${
            this.props.blur && this.props.blurIndex !== 1 ? "blur" : ""
          } ${this.props.blurIndex === 1 ? "highlight" : ""}`}
          ref={this.props.highlightedItems[1]}
        >
          <Tooltip description={content[1][1]} />
          <CircularSimpleChart
            {...conversionScores.unlockedSyd}
            value={93}
            text={"ENGAGED\nEMPLOYEES"}
          />
          <CircularSimpleChart
            {...conversionScores.unlockedSyd}
            value={this.props.conversion.action}
          />
          <CircularSimpleChart
            {...conversionScores.unlockedLQIndex}
            value={this.props.conversion.dashboard}
          />
        </div>

        {/* <span
          className={`titleLabel ${
            this.props.blur && this.props.blurIndex !== 2 ? "blur" : ""
          }`}
        >
          Engagement Indicators
        </span>

        <div
          className={`chartContainer verticalPadding extraPadding ${
            this.props.blur && this.props.blurIndex !== 2 ? "blur" : ""
          } ${this.props.blurIndex === 2 ? "highlight" : ""}`}
          ref={this.props.highlightedItems[2]}
        >
          <Tooltip description={content[1][2]} />
          <SpeedChart
            text={"NET\nPROMOTER\nSCORE"}
            showUpdatedAt={false}
            value={62}
            onClick={() => this.setState({ ...this.state, showNPSModal: true })}
            hideWhiteLines={true}
            range={{ start: -100, end: 100 }}
            arcNumbers={this.state.animationData[0]}
            legendClass="engagement"
          />

          <SpeedChart
            {...scoresChartData.satisfiedIamyiam}
            text={"WOULD\nRECOMMEND YOU"}
            value={this.props.satisfaction.withProduct}
            clickable={false}
            arcNumbers={this.state.animationData[1]}
            legendClass="engagement"
          />

          <SpeedChart
            text={"WOULD\nRECOMMEND SYD"}
            value={78}
            clickable={false}
            arcNumbers={this.state.animationData[2]}
            legendClass="engagement"
          />
        </div> */}

        <span
          className={`titleLabel ${
            this.props.blur && this.props.blurIndex !== 3 ? "blur" : ""
          }`}
        >
          Goals most engaged with
        </span>

        <div
          className={`chartContainer verticalPadding ${
            this.props.blur && this.props.blurIndex !== 2 ? "blur" : ""
          } ${this.props.blurIndex === 2 ? "highlight" : ""}`}
          ref={this.props.highlightedItems[2]}
        >
          <Tooltip description={content[1][2]} />

          {this.props.goalsRank.map((_, index) => (
            <GoalChart
              {...this.rankValue(this.props.goalsRank, index)}
              key={`goal${index}`}
            />
          ))}
        </div>
        <GiveFeedback blur={this.props.blur} />
      </div>
      {this.state.showNPSModal && (
        <NPSChart
          onDimissed={() =>
            this.setState({ ...this.state, showNPSModal: false })
          }
        />
      )}
    </div>
  );

  rankValue = (ranks: Rank[], index: number) =>
    ranks.length === 0
      ? { value: 0, text: "", imageUrl: "" }
      : {
          value: ranks[index].value,
          text: ranks[index].name,
          ...this.image(ranks[index]),
        };

  image = (rank: Rank) => {
    if (rank.category) {
      switch (rank.category) {
        case "habits":
        case "services":
          return { image: assets.recommendations.activity };
        case "supplements":
        case "foods":
          return { image: assets.recommendations.eating };
        case "reading":
          return { image: assets.recommendations.reading };
      }
    } else {
      return { imageUrl: rank.imgUrl };
    }
  };
}

interface Props extends GuidedProps {
  demographics: Demographics;
  conversion: Conversion;
  goalsRank: Rank[];
  recommendationsRank: Rank[];
  overview: Overview;
  satisfaction: Satisfaction;

  loadDemographics: () => void;
  loadConversion: () => void;
  loadGoalsRank: () => void;
  loadRecommendationsRank: () => void;
  loadOverview: () => void;
  loadSatisfaction: () => void;
}

interface State {
  showNPSModal: boolean;
  animationData: number[];
  loading: boolean;
}

function mapStateToProps(state: RootState) {
  return {
    demographics: state.demographics.demographics,
    conversion: state.demographics.conversion,
    goalsRank: state.demographics.goalsRank,
    recommendationsRank: state.demographics.recommendationsRank,
    overview: state.healthRisks.overview,
    satisfaction: state.lqi.satisfaction,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    loadDemographics: () => dispatch(loadDemographics()),
    loadConversion: () => dispatch(loadConversion()),
    loadGoalsRank: () => dispatch(loadGoalRank()),
    loadRecommendationsRank: () => dispatch(loadRecommendationsRank()),
    loadOverview: () => dispatch(loadOverview()),
    loadSatisfaction: () => dispatch(loadSatisfaction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementScreen);
