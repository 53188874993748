import axios from "axios";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const alphaClient = axios.create({
  baseURL: "http://ec2-3-10-107-11.eu-west-2.compute.amazonaws.com",
  headers: {
    apiKey: "a57858f1-9824-4a02-a2de-b4c756ae4805",
    timeStamp: new Date().getSeconds().toString(),
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const prodClient = axios.create({
  baseURL: "https://api.dashboard.iamyiam.com",
  headers: {
    apiKey: "a57858f1-9824-4a02-a2de-b4c756ae4805",
    timeStamp: new Date().getSeconds().toString(),
  },
});

export const client = prodClient;
