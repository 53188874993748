import { assets } from "assets/assets";
import { RootState } from "common/state";
import React from "react";
import { useSelector } from "react-redux";
import "./locked.css";

export const Locked = () => {
  const corporateId = useSelector<RootState, string>(
    (state) => state.common.user.corporateId
  );
  if (corporateId === "" || corporateId === "showcase1") {
    return null;
  }

  return (
    <div className="lockedContainer">
      <div className="lockedInnerContainer">
        <img src={assets.locked} alt="" />
        <span>NOT ENOUGH DATA YET</span>
      </div>
    </div>
  );
};
