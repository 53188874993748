import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import "components/arrow_chart/arrowChart.css";
import { Point } from "components/data";

export const EmployeeLongetivityChart: React.FC<Props> = (props: Props) => {
  const svg = useRef<SVGElement | null>();
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });
  const { width, height } = dimensions;
  const markerSize = 8;
  const axisStroke = 2;

  const bottomLabelsSize = 14;
  const rectanglesLabelsSize = 16;
  const axisBottom = height - markerSize - bottomLabelsSize;
  const axisTop = markerSize;
  const axisLeft = bottomLabelsSize + markerSize + 8;
  const axisRight = width - markerSize;
  const rectangleWidth = 60;

  const axisColor = "#e9e9e9";

  const colors = ["#7AD077", "#FEBE3A"];

  useEffect(() => {
    window.addEventListener("resize", measureItems);
    return () => window.removeEventListener("resize", measureItems);
  });

  const measureItems = () => {
    if (svg.current != null) {
      const rect = svg.current.getBoundingClientRect();
      if (
        rect.width !== dimensions.width ||
        rect.height !== dimensions.height
      ) {
        setDimensions({ width: rect.width, height: rect.height });
      }
    }
  };

  const bottomAxis = () => (
    <path
      d={`M ${axisLeft} ${axisBottom} L ${width - markerSize} ${axisBottom}`}
      stroke={axisColor}
      strokeWidth={axisStroke}
      strokeDasharray="1"
    />
  );

  const bottomLabels = () =>
    props.points.map((point, index) => (
      <text
        key={props.bottomLabels[index]}
        x={(point.x / 100) * (axisRight - axisLeft) + axisLeft}
        y={height}
        alignmentBaseline="after-edge"
        dominantBaseline="after-edge"
        className="arrowChartGraphLabel"
        style={{ fontSize: `${bottomLabelsSize}px` }}
        textAnchor="middle"
      >
        {props.bottomLabels[index]}
      </text>
    ));

  const rectangles = () => {
    return props.points.map((point, index) => {
      const x =
        (point.x / 100) * (axisRight - axisLeft) +
        axisLeft -
        rectangleWidth / 2;
      const labelSize = rectanglesLabelsSize;
      const bottom = axisBottom - 10;
      const height = (point.y / 100) * (bottom - axisTop - labelSize);

      return (
        <rect
          key={`${index}longetivity`}
          x={x}
          y={bottom - height}
          height={height}
          width={rectangleWidth}
          fill={point.y <= 50 ? colors[1] : colors[0]}
          rx={5}
          ry={5}
          className="fillChartRect"
        />
      );
    });
  };

  const rectangleLabels = () => {
    return props.points.map((point, index) => {
      const x = (point.x / 100) * (axisRight - axisLeft) + axisLeft;

      const labelSize = 10;
      const bottom = axisBottom - 10;
      const rectangleHeight = (point.y / 100) * (bottom - axisTop - labelSize);

      return (
        <text
          key={index}
          x={x}
          y={bottom - rectangleHeight - 5}
          dominantBaseline="text-after-edge"
          textAnchor="middle"
          className="arrowChartGraphLabel"
          style={{ fontSize: `${rectanglesLabelsSize}px`, fill: "#45545f" }}
        >
          {point.y}%
        </text>
      );
    });
  };

  return (
    <svg
      id="svgContainer"
      ref={(ref) => {
        svg.current = ref;
        measureItems();
      }}
    >
      {bottomAxis()}
      {bottomLabels()}
      {rectangles()}
      {rectangleLabels()}
    </svg>
  );
};

interface Dimensions {
  width: number;
  height: number;
}

interface Props {
  bottomLabels: string[];
  points: Point[];
}
