import { ChangesType } from "../risks_changes/types";
import { GenericAction } from "../common/types";

export interface HealthRiskElement {
  date: string;
  healthy: number;
  inDanger: number;
  inRisk: number;

  locked?: boolean;
}

export interface HealthRisks {
  cvd: HealthRiskElement[];
  msd: HealthRiskElement[];
  metabolic: HealthRiskElement[];
  sad: HealthRiskElement[];

  anxiety: HealthRiskElement[];
  depression: HealthRiskElement[];
  stress: HealthRiskElement[];

  obesity: HealthRiskElement[];
  t2d: HealthRiskElement[];
}

export interface CostCategories {
  abs: number;
  medical: number;
  prs: number;
}

export interface CostDistributionElement {
  range: string;
  value: number;
}

export interface HealthRiskCostElement {
  danger: number;
  risk: number;
  total: number;
}

export interface HealthRisksCosts {
  risk_costs: {
    cvd: number;
    msd: number;
    metabolic: number;
    sad: number;
  };

  abs: {
    days: { total: number };
  };

  prs: {
    days: { total: number };
  };
}

export interface CostsState {
  healthRisks: HealthRisks;
  costCategories: CostCategories;
  costDistribution: {
    [name in
      | "cvd"
      | "obesity"
      | "msd"
      | "stress"
      | "t2d"
      | "anxiety"
      | "depression"]: CostDistributionElement[];
  };
  costDistributionLocked: {
    [name in
      | "cvd"
      | "obesity"
      | "msd"
      | "stress"
      | "t2d"
      | "anxiety"
      | "depression"]: boolean;
  };
  healthRiskCosts: HealthRisksCosts;
}

export type CostDistributionType = ChangesType;

/**
 * actions
 */

export const HEALTH_RISKS_LOADED = "HEALTH_RISKS_LOADED";
export const COST_CATEGORIES_LOADED = "COST_CATEGORIES_LOADED";
export const COST_DISTRIBUTION_LOADED = "COST_DISTRIBUTION_LOADED";
export const HEALTH_COST_RISKS_LOADED = "HEALTH_COST_RISKS_LOADED";

interface HealthRisksLoadedAction {
  type: typeof HEALTH_RISKS_LOADED;
  healthRisks: HealthRisks;
}

interface CostCategoriesLoadedAction {
  type: typeof COST_CATEGORIES_LOADED;
  costCategories: CostCategories;
}

interface CostDistribtionLoadedAction {
  type: typeof COST_DISTRIBUTION_LOADED;
  distribution: CostDistributionElement[];
  distributionType: CostDistributionType;
  locked: boolean;
}

export type CostsAction =
  | HealthRisksLoadedAction
  | CostCategoriesLoadedAction
  | CostDistribtionLoadedAction
  | GenericAction<typeof HEALTH_COST_RISKS_LOADED, HealthRisksCosts>;
