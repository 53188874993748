import { useState, useEffect, RefObject } from "react";
import { Dimensions } from "./types";

export const useMeasure = (ref: RefObject<SVGElement>) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const measure = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setDimensions({ width: rect.width, height: rect.height });
      }
    };
    window.addEventListener("resize", measure);
    setTimeout(measure, 0);

    return () => window.removeEventListener("resize", measure);
  }, [ref]);

  return dimensions;
};
