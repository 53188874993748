import React, {
  Ref,
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { SpeedChart, SpeedChartProps } from "components/speed_chart/SpeedChart";
import { scoresChartData, simpleChartData } from "../components/data";
import {
  getDimensionScore,
  getDimensionValuesOverTime,
  getEnergyValue,
  getLQIValuesOverTime,
} from "./common";
import { useSelector } from "react-redux";
import { RootState } from "common/state";
import { Energy, LQI, Satisfaction } from "./types";
import { Tooltip } from "components/tooltip/Tooltip";
import { KpiKey } from "components/kpis";
import { Animator } from "components/speed_chart/Animator";
import { Overview } from "home/types";
import { content } from "common/content";
import { capitalize } from "common/utils";

export const LQICharts: React.FC<ComponentProps> = (
  componentProps: ComponentProps
) => {
  const props = useSelector<RootState, ReduxProps>((state) => ({
    lqi: state.lqi.lqi,
    satisfaction: state.lqi.satisfaction,
    overview: state.healthRisks.overview,
    energy: state.healthRisks.energy,
  }));

  const dynamicValue = useCallback(
    (value: number) => (props.lqi.length > 0 ? value : 0),
    [props.lqi]
  );

  const key = (index: number) => `LQIChart_${index}`;

  const [animationData, setAnimationData] = useState<number[]>(
    Animator.initialize(12)
  );
  const animator = useRef(
    new Animator(setAnimationData, requestAnimationFrame)
  );

  const readyToAnimate = useMemo(
    () =>
      props.lqi.length > 0 &&
      props.satisfaction.withProduct > 0 &&
      props.satisfaction.withResults > 0 &&
      props.overview.lqi.length > 0 &&
      props.energy.length > 0,
    [
      props.lqi,
      props.satisfaction.withProduct,
      props.satisfaction.withResults,
      props.overview.lqi,
      props.energy,
    ]
  );

  useEffect(() => {
    animator.current.setValues([
      getDimensionScore(props.lqi, "health"),
      getDimensionScore(props.lqi, "career"),
      getDimensionScore(props.lqi, "brainPower"),
      getDimensionScore(props.lqi, "financial"),
      getDimensionScore(props.lqi, "emotional"),
      getDimensionScore(props.lqi, "environment"),
      getDimensionScore(props.lqi, "selfAwareness"),
      getDimensionScore(props.lqi, "purpose"),
      getDimensionScore(props.lqi, "socialLife"),
      props.overview.lqi.length > 0 ? props.overview.lqi[0].value : 0,
      props.overview.lqi.length > 0 ? getEnergyValue(props.energy) : 0,
      scoresChartData.burnoutScore.value,
    ]);

    if (readyToAnimate) {
      animator.current.beginAnimation();
    }
  }, [
    props.lqi,
    props.satisfaction.withResults,
    props.overview,
    props.overview.lqi,
    readyToAnimate,
    props.energy,
  ]);

  const bottomRow = () => (
    <Fragment>
      <SpeedChart
        text={simpleChartData.lifeQualityIndex.text}
        value={props.overview.lqi.length > 0 ? props.overview.lqi[0].value : 0}
        valuesOverTime={getLQIValuesOverTime(props.lqi)}
        clickable={false}
        arcNumbers={animationData[9]}
        smallLegend={false}
        locked={
          props.overview.lqi.length > 0 ? props.overview.lqi[0].locked : false
        }
        {...style()}
      />
      <SpeedChart
        text={simpleChartData.energyLack.text}
        value={props.overview.lqi.length > 0 ? getEnergyValue(props.energy) : 0}
        showUpdatedAt={false}
        clickable={false}
        arcNumbers={animationData[10]}
        smallLegend={false}
        {...style()}
      />
      <SpeedChart
        smallLegend
        {...scoresChartData.burnoutScore}
        key={key(0)}
        showUpdatedAt={false}
        value={dynamicValue(scoresChartData.burnoutScore.value)}
        clickable={false}
        arcNumbers={animationData[11]}
        {...style()}
        locked={true}
      />
    </Fragment>
  );

  const onChartClicked = (kpi: KpiKey) => (label: string, value: number) =>
    componentProps.onSelectedItem(
      capitalize(label.replace("\n", " ")),
      value,
      kpi
    );

  const style = (): Partial<SpeedChartProps> => ({
    style: {
      flexBasis: "33%",
      width: "0px",
    },
    svgStyle: {
      justifyContent: "center",
    },
    smallLegend: false,
  });

  return (
    <Fragment>
      <div
        className={`chartContainer lqi verticalPadding ${
          componentProps.blur && componentProps.highlightIndex !== 0
            ? "blur"
            : ""
        } ${componentProps.highlightIndex === 0 ? "highlight" : ""}`}
        ref={componentProps.childRefs[0]}
      >
        <SpeedChart
          key={key(4)}
          text={"HEALTH\nSCORE"}
          value={getDimensionScore(props.lqi, "health")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "health")}
          onClick={onChartClicked("health")}
          arcNumbers={animationData[0]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.careerScore}
          key={key(5)}
          value={getDimensionScore(props.lqi, "career")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "career")}
          onClick={onChartClicked("career")}
          arcNumbers={animationData[1]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.brainPowerScore}
          key={key(6)}
          value={getDimensionScore(props.lqi, "brainPower")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "brainPower")}
          onClick={onChartClicked("brainPower")}
          arcNumbers={animationData[2]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.financialScore}
          key={key(7)}
          value={getDimensionScore(props.lqi, "financial")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "financial")}
          onClick={onChartClicked("financial")}
          arcNumbers={animationData[3]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.emotionalScore}
          key={key(8)}
          value={getDimensionScore(props.lqi, "emotional")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "emotional")}
          onClick={onChartClicked("emotional")}
          arcNumbers={animationData[4]}
          {...style()}
        />

        <SpeedChart
          {...scoresChartData.environmentScore}
          key={key(9)}
          value={getDimensionScore(props.lqi, "environment")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "environment")}
          onClick={onChartClicked("environment")}
          arcNumbers={animationData[5]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.selfEsteemScore}
          key={key(10)}
          value={getDimensionScore(props.lqi, "selfAwareness")}
          valuesOverTime={getDimensionValuesOverTime(
            props.lqi,
            "selfAwareness"
          )}
          onClick={onChartClicked("selfAwareness")}
          arcNumbers={animationData[6]}
          {...style()}
        />
        <SpeedChart
          {...scoresChartData.purposeScore}
          key={key(11)}
          value={getDimensionScore(props.lqi, "purpose")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "purpose")}
          onClick={onChartClicked("purpose")}
          arcNumbers={animationData[7]}
          {...style()}
        />

        <SpeedChart
          {...scoresChartData.socialLifeScore}
          key={key(12)}
          value={getDimensionScore(props.lqi, "socialLife")}
          valuesOverTime={getDimensionValuesOverTime(props.lqi, "socialLife")}
          onClick={onChartClicked("socialLife")}
          arcNumbers={animationData[8]}
          {...style()}
        />

        <Tooltip description={content[3][0]} />
      </div>

      <span className="titleLabel">Workplace Indicators</span>

      <div
        className={`chartContainer lqi verticalPadding bottomRow ${
          componentProps.blur && componentProps.highlightIndex !== 1
            ? "blur"
            : ""
        } ${componentProps.highlightIndex === 1 ? "highlight" : ""}`}
        ref={componentProps.childRefs[1]}
      >
        {bottomRow()}
      </div>
    </Fragment>
  );
};

interface ReduxProps {
  lqi: LQI[];
  satisfaction: Satisfaction;
  overview: Overview;
  energy: Energy[];
}

interface ComponentProps {
  childRefs: Ref<HTMLDivElement>[];
  blur: boolean;
  highlightIndex: number;

  onSelectedItem: (label: string, score: number, kpi: KpiKey) => void;
}
